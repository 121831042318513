import axios from 'axios';


// if (process.env.ENVI == 'dev'){
//   URL = 'http://localhost:3500'
// }
// if (process.env.ENVI == 'prod'){
//   URL = 'http://dhanutek-hrms.westus2.cloudapp.azure.com/api'
// }

const APIClient = axios.create({
  baseURL: 'http://dhanutek-hrms.westus2.cloudapp.azure.com/api',
  //baseURL: 'http://localhost:3500',
  // timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default APIClient;