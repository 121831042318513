// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link.active{
    width: 100% !important;
}

.nav-pills {
    .nav-link.active,
    .show>.nav-link{
        background-color: var(--primary-color);
    }
    .nav-link{
        color: var(--text-color);
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/bootstrap/kbase.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI;;QAEI,sCAAsC;IAC1C;IACA;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".nav-link.active{\n    width: 100% !important;\n}\n\n.nav-pills {\n    .nav-link.active,\n    .show>.nav-link{\n        background-color: var(--primary-color);\n    }\n    .nav-link{\n        color: var(--text-color);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
