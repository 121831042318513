// import React, { useState } from "react";
// import axios from "axios";
// import SSNInput from "./SSNInput"; // Import the custom SSNInput component
// import { Dropdown } from "react-bootstrap";
// import APIClient from "./APIClient";

// // Country and extension data
// const countryList = [
//     { name: "United States", code: "USA", extension: "+1" },
//     { name: "Canada", code: "Canada", extension: "+1" },
//     { name: "United Kingdom", code: "UK", extension: "+44" },
//     { name: "Australia", code: "Australia", extension: "+61" },
//     { name: "India", code: "India", extension: "+91" },
//     { name: "Germany", code: "Germany", extension: "+49" },
//     { name: "France", code: "France", extension: "+33" },
//     { name: "China", code: "China", extension: "+86" },
//     { name: "Japan", code: "Japan", extension: "+81" },
//     { name: "Brazil", code: "Brazil", extension: "+55" },
//     { name: "Mexico", code: "Mexico", extension: "+52" },
//     { name: "South Africa", code: "South Africa", extension: "+27" },
//     { name: "Russia", code: "Russia", extension: "+7" },
//     { name: "Italy", code: "Italy", extension: "+39" },
//     { name: "South Korea", code: "South Korea", extension: "+82" },
//     { name: "Spain", code: "Spain", extension: "+34" },
//     { name: "Sweden", code: "Sweden", extension: "+46" },
//     { name: "Netherlands", code: "Netherlands", extension: "+31" },
// ];

// function EmployeeRegistrationForm() {
//     const [formData, setFormData] = useState({
//         firstName: "",
//         lastName: "",
//         dateOfBirth: "",
//         ssn: "",
//         confirmSsn: "",
//         email: "",
//         confirmEmail:"",
//         address: "",
//         city: "", 
//         state: "", 
//         zip: "", 
//         country: "",
//         phoneNumber: "",
//         extension: "+1", 
//         citizenshipStatus:""
//     });

//     const [errors, setErrors] = useState({});

//     const validateField = (name, value) => {
//         let error = "";
//         switch (name) {
//             case "firstName":
//                 if (!value) error = "Please enter your first name.";
//                 else if (!/^[a-zA-Z ]{2,50}$/.test(value)) {
//                     error = "First name must be 2-50 characters and can include spaces.";
//                 }
//                 break;
//             case "lastName":
//                 if (!value) error = "Please enter your last name.";
//                 else if (!/^[a-zA-Z]{2,50}$/.test(value)) {
//                     error = "Last name must be 2-50 characters and cannot include spaces.";
//                 }
//                 break;
//             case "dateOfBirth":
//                 if (!value) {
//                     error = "Please enter your date of birth.";
//                 } else {
//                     const today = new Date();
//                     const dob = new Date(value);
//                     const age = today.getFullYear() - dob.getFullYear();
//                     if (age < 16 || (age === 16 && today < new Date(dob.setFullYear(dob.getFullYear() + 16)))) {
//                         error = "User must be above 16 years old.";
//                     }
//                 }
//                 break;
//             case "ssn":
//             case "confirmSsn":
//                 if (!value) error = "Please enter your SSN.";
//                 else if (!/^\d{9}$/.test(value)) {
//                     error = "SSN must be a 9-digit number.";
//                 }
//                 break;
//             case "email":
//             case "confirmEmail":
//                 if (!value) error = "Please enter your email.";
//                 else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
//                     error = "Please enter a valid email address.";
//                 }
//                 break;
//             case "address":
//                 if (!value) error = "Please enter your address.";
//                 else if (value.length < 5) error = "Address must be at least 5 characters long.";
//                 break;
//             case "city":
//             case "state":
//                 if (!value) error = `Please enter your ${name}.`;
//                 else if (!/^[a-zA-Z ]{2,50}$/.test(value)) {
//                     error = `${name.charAt(0).toUpperCase() + name.slice(1)} must be 2-50 characters long.`;
//                 }
//                 break;
//             case "zip":
//                 if (!value) error = "Please enter your ZIP code.";
//                 else if (!/^\d{5}(-\d{4})?$/.test(value)) {
//                     error = "ZIP code must be in 5-digit or ZIP+4 format.";
//                 }
//                 break;
//             case "phoneNumber":
//                 if (!value) error = "Please enter your phone number.";
//                 else if (!/^\d{10}$/.test(value)) {
//                     error = "Phone number must be a 10-digit number.";
//                 }
//                 break;
//             case "citizenshipStatus":
//                 if (!value) error = "Please select your citizenship status.";
//                 break;
//             default:
//                 break;
//         }
//         return error;
//     };

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
    
//         // Update form data
//         setFormData({ ...formData, [name]: value });
    
//         // Validate field dynamically
//         const error = validateField(name, value);
//         setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
//     };
    

//     const handleCountryChange = (e) => {
//         const selectedCountry = e.target.value;
//         const selectedExtension = countryList.find((country) => country.code === selectedCountry)?.extension || "+1";
//         setFormData({ ...formData, country: selectedCountry, extension: selectedExtension });
//     };

//     const handleExtensionChange = (e) => {
//         setFormData({ ...formData, extension: e.target.value });
//     };

//     const handleSSNChange = (value) => {
//         setFormData({ ...formData, ssn: value });
//         const error = validateField("ssn", value);
//         setErrors({ ...errors, ssn: error });
//     };

//     const handleConfirmSSNChange = (value) => {
//         setFormData({ ...formData, confirmSsn: value });
//         const error = validateField("confirmSsn", value);
//         setErrors({ ...errors, confirmSsn: error });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault(); // Prevent page reload
    
//         console.log("Form Data Before Submission: ", formData); // Log form data
    
//         const formErrors = {};
//         Object.keys(formData).forEach((key) => {
//             const error = validateField(key, formData[key]);
//             if (error) formErrors[key] = error;
//         });
    
//         if (formData.ssn !== formData.confirmSsn) {
//             formErrors.confirmSsn = "SSN fields must match";
//         }
//         if (formData.email !== formData.confirmEmail) {
//             formErrors.confirmEmail = "Email fields must match";
//         }
        
    
//         setErrors(formErrors);
    
//         if (Object.keys(formErrors).length === 0) {
//             try {
//                 const dataToSend = {
//                     firstName: formData.firstName,
//                     lastName: formData.lastName,
//                     dob: formData.dateOfBirth,
//                     ssn: formData.ssn,
//                     email: formData.email,
//                     address: formData.address,
//                     city: formData.city,
//                     state: formData.state,
//                     zip: formData.zip,
//                     country: formData.country,
//                     phone: formData.phoneNumber,
//                     extension: formData.extension,
//                     citizenshipStatus:formData.citizenshipStatus,
//                 };
    
//                 console.log("Sending Data: ", dataToSend); // Log data sent to backend
    
//                 // Send the request using axios
//                 const response = await APIClient.post(
//                     "/employee/register", 
//                     dataToSend
//                 );
    
//                 console.log("API Response: ", response.data); // Log API response
    
//                 if (response.data.success) {
//                     alert("Employee registered successfully!"); // Success alert
//                 } else {
//                     alert(`Error: ${response.data.message}`); // Backend error message
//                 }
//             } catch (error) {
//                 console.error("Error during registration: ", error); // Log detailed error
    
//                 // Check if the error response contains a message
//                 if (error.response && error.response.data && error.response.data.message) {
//                     alert(`Error: ${error.response.data.message}`); // Display backend error message
//                 } else {
//                     alert("An unexpected error occurred. Please try again."); // Generic error
//                 }
//             }
//         }
//     };
//     const handleSelect = (displayValue, eventKey) => {
//         setFormData((prevData) => ({
//           ...prevData,
//           citizenshipStatus: displayValue, // Set display label as selected option
//         }));
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           citizenshipStatus: null, // Clear error on selection
//         }));
//       };
    
    
    

//     return (
//         <div className="card mb-3">
//             <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
//                 <h6 className="mb-0 fw-bold">Employee Registration Form</h6>
//             </div>
//             <div className="card-body">
//                 <form onSubmit={handleSubmit}>
//                     <div className="row g-3 align-items-center">
//                         <div className="col-md-6">
//                             <label htmlFor="firstName" className="form-label">First Name</label>
//                             <input type="text" className="form-control" id="firstName" name="firstName" value={formData.firstName} onChange={handleInputChange}  />
//                             {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
//                         </div>
                       
//                         <div className="col-md-6">
//                             <label htmlFor="lastName" className="form-label">Last Name</label>
//                             <input type="text" className="form-control" id="lastName" name="lastName" value={formData.lastName} onChange={handleInputChange}  />
//                             {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
//                         </div>
//                         <div className="col-md-6">
//                             <label className="form-label">Date Of Birth</label>
//                             <input type="date" className="form-control" id="dateOfBirth" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleInputChange}  />
//                             {errors.dateOfBirth && <div className="text-danger">{errors.dateOfBirth}</div>}
//                         </div>
                        
//                         <div className="col-md-6">
//                             <label className="form-label">Phone Number</label>
//                             <div className="input-group">
//                                 <span className="input-group-text">{formData.extension}</span>
//                                 <input type="text" className="form-control" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange}  />
//                             </div>
//                             {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
//                         </div>

//                         <div className="col-md-6">
//                             <SSNInput label="SSN" value={formData.ssn} onChange={handleSSNChange} error={errors.ssn} />
//                         </div>
//                         <div className="col-md-6">
//   <SSNInput
//     label="Confirm SSN"
//     value={formData.confirmSsn}
//     onChange={handleConfirmSSNChange}
//     error={errors.confirmSsn}
//   />
// </div>


//                         <div className="col-md-6">
//                             <label className="form-label">Email</label>
//                             <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleInputChange}  />
//                             {errors.email && <div className="text-danger">{errors.email}</div>}
//                         </div>

//                         <div className="col-md-6">
//                             <label className="form-label">Confirm Email</label>
//                             <input type="email" className="form-control" id="confirmEmail" name="confirmEmail" value={formData.confirmEmail} onChange={handleInputChange} onPaste={(e) => e.preventDefault()} />
//                             {errors.confirmEmail && <div className="text-danger">{errors.confirmEmail}</div>}
//                         </div>

//                         <div className="col-md-6">
//                             <label className="form-label">Address</label>
//                             <input type="text" className="form-control" id="address" name="address" value={formData.address} onChange={handleInputChange}  />
//                             {errors.address && <div className="text-danger">{errors.address}</div>}
//                         </div>

//                         <div className="col-md-6">
//                             <label className="form-label">City</label>
//                             <input type="text" className="form-control" id="city" name="city" value={formData.city} onChange={handleInputChange} />
//                             {errors.city && <div className="text-danger">{errors.city}</div>}
//                         </div>

//                         <div className="col-md-6">
//                             <label className="form-label">State</label>
//                             <input type="text" className="form-control" id="state" name="state" value={formData.state} onChange={handleInputChange}  />
//                             {errors.state && <div className="text-danger">{errors.state}</div>}
//                         </div>

//                         <div className="col-md-6">
//                             <label className="form-label">ZIP</label>
//                             <input type="text" className="form-control" id="zip" name="zip" value={formData.zip} onChange={handleInputChange}  />
//                             {errors.zip && <div className="text-danger">{errors.zip}</div>}
//                         </div>

//                         <div className="col-md-6">
//                             <label className="form-label">Country</label>
//                             <select className="form-select" id="country" name="country" value={formData.country} onChange={handleCountryChange} >
//                                 <option value="">Select Country</option>
//                                 {countryList.map((country) => (
//                                     <option key={country.code} value={country.code}>
//                                         {country.name}
//                                     </option>
//                                 ))}
//                             </select>
//                         </div>
//                         <div className="col-md-6">
//               <label htmlFor="citizenshipStatus" className="form-label">
//                 Citizenship Status
//               </label>
              
//               <Dropdown style={{ width: '100%' }}>
//           <Dropdown.Toggle
//             variant=""
//             id="citizenshipStatus"
//             className="form-control custom-dropdown-toggle"
//           >
//             {formData.citizenshipStatus || 'Select an Option'} {/* Display selected option or placeholder */}
//           </Dropdown.Toggle>

//           <Dropdown.Menu style={{ width: '100%' }}>
//             <Dropdown.Item onClick={() => handleSelect("H1-B Visa", "h1")}>
//             H1-B Visa
//             </Dropdown.Item>
//             <Dropdown.Item onClick={() => handleSelect("J-1 Visa", "j1")}>
//             J-1 Visa
//             </Dropdown.Item>
//             <Dropdown.Item onClick={() => handleSelect("F1 Visa with OPT", "f1Opt")}>
//             F1 Visa with OPT
//             </Dropdown.Item>
//             <Dropdown.Item onClick={() => handleSelect("Permanent Residency(Green Card)", "prgc")}>
//             Permanent Residency(Green Card)
//             </Dropdown.Item>
//             <Dropdown.Item onClick={() => handleSelect("Permanent Residency(Citizen)", "prc")}>
//             Permanent Residency(Citizen)
//             </Dropdown.Item>
//             <Dropdown.Item onClick={() => handleSelect("L-1 Visa", "l1visa")}>
//             L-1 Visa
//             </Dropdown.Item>
//             <Dropdown.Item onClick={() => handleSelect("B-1 Visa", "b1visa")}>
//             B-1 Visa
//             </Dropdown.Item>
//             <Dropdown.Item onClick={() => handleSelect("O-1 Visa", "o1visa")}>
//             O-1 Visa
//             </Dropdown.Item>
//           </Dropdown.Menu>
//         </Dropdown>
              
//             </div>
//              </div>

//                     <button type="submit" className="btn btn-primary">Submit</button>
//                 </form>
//             </div>
//         </div>
//     );
// }

// export default EmployeeRegistrationForm;


import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import SSNInput from "./SSNInput"; // Import the custom SSNInput component
import { Dropdown } from "react-bootstrap";
import APIClient from "./APIClient";


// Country and extension data
const countryList = [
    { name: "United States", code: "USA", extension: "+1" },
    { name: "Canada", code: "Canada", extension: "+1" },
    { name: "United Kingdom", code: "UK", extension: "+44" },
    { name: "Australia", code: "Australia", extension: "+61" },
    { name: "India", code: "India", extension: "+91" },
    { name: "Germany", code: "Germany", extension: "+49" },
    { name: "France", code: "France", extension: "+33" },
    { name: "China", code: "China", extension: "+86" },
    { name: "Japan", code: "Japan", extension: "+81" },
    { name: "Brazil", code: "Brazil", extension: "+55" },
    { name: "Mexico", code: "Mexico", extension: "+52" },
    { name: "South Africa", code: "South Africa", extension: "+27" },
    { name: "Russia", code: "Russia", extension: "+7" },
    { name: "Italy", code: "Italy", extension: "+39" },
    { name: "South Korea", code: "South Korea", extension: "+82" },
    { name: "Spain", code: "Spain", extension: "+34" },
    { name: "Sweden", code: "Sweden", extension: "+46" },
    { name: "Netherlands", code: "Netherlands", extension: "+31" },
];
const usStates = [
    { name: "Alabama", code: "AL" },
    { name: "Alaska", code: "AK" },
    { name: "Arizona", code: "AZ" },
    { name: "Arkansas", code: "AR" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Connecticut", code: "CT" },
    { name: "Delaware", code: "DE" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Hawaii", code: "HI" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Iowa", code: "IA" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Maine", code: "ME" },
    { name: "Maryland", code: "MD" },
    { name: "Massachusetts", code: "MA" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Mississippi", code: "MS" },
    { name: "Missouri", code: "MO" },
    { name: "Montana", code: "MT" },
    { name: "Nebraska", code: "NE" },
    { name: "Nevada", code: "NV" },
    { name: "New Hampshire", code: "NH" },
    { name: "New Jersey", code: "NJ" },
    { name: "New Mexico", code: "NM" },
    { name: "New York", code: "NY" },
    { name: "North Carolina", code: "NC" },
    { name: "North Dakota", code: "ND" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Pennsylvania", code: "PA" },
    { name: "Rhode Island", code: "RI" },
    { name: "South Carolina", code: "SC" },
    { name: "South Dakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "Utah", code: "UT" },
    { name: "Vermont", code: "VT" },
    { name: "Virginia", code: "VA" },
    { name: "Washington", code: "WA" },
    { name: "West Virginia", code: "WV" },
    { name: "Wisconsin", code: "WI" },
    { name: "Wyoming", code: "WY" },
];


function EmployeeRegistrationForm() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        ssn: "",
        confirmSsn: "",
        email: "",
        confirmEmail:"",
        address: "",
        city: "", 
        state: "", 
        zip: "", 
        country: "",
        phoneNumber: "",
        extension: "+1", 
        citizenshipStatus:""
    });

    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const validateField = (name, value) => {
        let error = "";
        switch (name) {
            case "firstName":
                if (!value) error = "Please enter your first name.";
                else if (!/^[a-zA-Z ]{2,50}$/.test(value)) {
                    error = "First name must be 2-50 characters and can include spaces.";
                }
                break;
            case "lastName":
                if (!value) error = "Please enter your last name.";
                else if (!/^[a-zA-Z]{2,50}$/.test(value)) {
                    error = "Last name must be 2-50 characters and cannot include spaces.";
                }
                break;
            case "dateOfBirth":
                if (!value) {
                    error = "Please enter your date of birth.";
                } else {
                    const today = new Date();
                    const dob = new Date(value);
                    const age = today.getFullYear() - dob.getFullYear();
                    if (age < 16 || (age === 16 && today < new Date(dob.setFullYear(dob.getFullYear() + 16)))) {
                        error = "User must be above 16 years old.";
                    }
                }
                break;
            case "ssn":
            case "confirmSsn":
                if (!value) error = "Please enter your SSN.";
                else if (!/^\d{9}$/.test(value)) {
                    error = "SSN must be a 9-digit number.";
                }
                break;
            case "email":
            case "confirmEmail":
                if (!value) error = "Please enter your email.";
                else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    error = "Please enter a valid email address.";
                }
                break;
            case "address":
                if (!value) error = "Please enter your address.";
                else if (value.length < 5) error = "Address must be at least 5 characters long.";
                break;
            case "city":
            case "state":
                if (!value) error = `Please enter your ${name}.`;
                else if (!/^[a-zA-Z ]{2,50}$/.test(value)) {
                    error = `${name.charAt(0).toUpperCase() + name.slice(1)} must be 2-50 characters long.`;
                }
                break;
            case "zip":
                if (!value) error = "Please enter your ZIP code.";
                else if (!/^\d{5}(-\d{4})?$/.test(value)) {
                    error = "ZIP code must be in 5-digit or ZIP+4 format.";
                }
                break;
            case "phoneNumber":
                if (!value) error = "Please enter your phone number.";
                else if (!/^\d{10}$/.test(value)) {
                    error = "Phone number must be a 10-digit number.";
                }
                break;
            case "citizenshipStatus":
                if (!value) error = "Please select your citizenship status.";
                break;
            default:
                break;
        }
        return error;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // Update form data
        setFormData({ ...formData, [name]: value });
    
        // Validate field dynamically
        const error = validateField(name, value);
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    };
    

    const handleCountryChange = (e) => {
        const selectedCountry = e.target.value;
        const selectedExtension = countryList.find((country) => country.code === selectedCountry)?.extension || "+1";
        setFormData({ ...formData, country: selectedCountry, extension: selectedExtension });
    };

    const handleExtensionChange = (e) => {
        setFormData({ ...formData, extension: e.target.value });
    };

    const handleSSNChange = (value) => {
        setFormData({ ...formData, ssn: value });
        const error = validateField("ssn", value);
        setErrors({ ...errors, ssn: error });
    };

    const handleConfirmSSNChange = (value) => {
        setFormData({ ...formData, confirmSsn: value });
        const error = validateField("confirmSsn", value);
        setErrors({ ...errors, confirmSsn: error });
    };
    
    

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload
    
        console.log("Form Data Before Submission: ", formData); // Log form data
    
        const formErrors = {};
        Object.keys(formData).forEach((key) => {
            const error = validateField(key, formData[key]);
            if (error) formErrors[key] = error;
        });
    
        if (formData.ssn !== formData.confirmSsn) {
            formErrors.confirmSsn = "SSN fields must match";
        }
        if (formData.email !== formData.confirmEmail) {
            formErrors.confirmEmail = "Email fields must match";
        }
        
    
        setErrors(formErrors);
    
        if (Object.keys(formErrors).length === 0) {
            try {
                const dataToSend = {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    dob: formData.dateOfBirth,
                    ssn: formData.ssn,
                    email: formData.email,
                    address: formData.address,
                    city: formData.city,
                    state: formData.state,
                    zip: formData.zip,
                    country: formData.country,
                    phone: formData.phoneNumber,
                    extension: formData.extension,
                    citizenshipStatus:formData.citizenshipStatus,
                };
    
                console.log("Sending Data: ", dataToSend); // Log data sent to backend
    
                // Send the request using axios
                const response = await APIClient.post(
                    "/employee/register", 
                    dataToSend
                );
    
                console.log("API Response: ", response.data); // Log API response
    
                if (response.data.success) {
                    const employeeTempId = response.data.data; // Use data directly as employee_temp_id
                    alert("Employee registered successfully!");
                    navigate(`/app/create-login/${employeeTempId}`); // Navigate with the employee_temp_id
                } else {
                    alert(`Error: ${response.data.message}`); // Backend error message
                }
            } catch (error) {
                console.error("Error during registration: ", error); // Log detailed error
    
                // Check if the error response contains a message
                if (error.response && error.response.data && error.response.data.message) {
                    alert(`Error: ${error.response.data.message}`); // Display backend error message
                } else {
                    alert("An unexpected error occurred. Please try again."); // Generic error
                }
            }
        }
    };
    const handleSelect = (displayValue, eventKey) => {
        setFormData((prevData) => ({
          ...prevData,
          citizenshipStatus: displayValue, // Set display label as selected option
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          citizenshipStatus: null, // Clear error on selection
        }));
      };
    
    
    

    return (
        <div className="card mb-3">
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold">Employee Registration Form</h6>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row g-3 align-items-center">
                        <div className="col-md-6">
                            <label htmlFor="firstName" className="form-label">First Name</label>
                            <input type="text" className="form-control" id="firstName" name="firstName" value={formData.firstName} onChange={handleInputChange}  />
                            {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
                        </div>
                       
                        <div className="col-md-6">
                            <label htmlFor="lastName" className="form-label">Last Name</label>
                            <input type="text" className="form-control" id="lastName" name="lastName" value={formData.lastName} onChange={handleInputChange}  />
                            {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Date Of Birth</label>
                            <input type="date" className="form-control" id="dateOfBirth" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleInputChange}  />
                            {errors.dateOfBirth && <div className="text-danger">{errors.dateOfBirth}</div>}
                        </div>
                        
                        <div className="col-md-6">
                            <label className="form-label">Phone Number</label>
                            <div className="input-group" style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ flexShrink: 0 }}>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.extension}
                                    style={{
                                    width: "60px", // Adjust width for extension box
                                    textAlign: "center", // Center-align the text
                                    }}
                                    readOnly
                                />
                                </div>
                                <input
                                type="text"
                                className="form-control"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                                style={{
                                    flexGrow: 1, // Ensures phone number box takes up the remaining space
                                }}
                                />
                            </div>
                            {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
                            </div>


                        <div className="col-md-6">
                            <SSNInput label="SSN" value={formData.ssn} onChange={handleSSNChange} error={errors.ssn} />
                        </div>
                        <div className="col-md-6">
                        
                            <SSNInput
                                label="Confirm SSN"
                                value={formData.confirmSsn}
                                onChange={handleConfirmSSNChange}
                                error={errors.confirmSsn}
                                
                            />
                            </div>



                        <div className="col-md-6">
                            <label className="form-label">Email</label>
                            <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleInputChange}  />
                            {errors.email && <div className="text-danger">{errors.email}</div>}
                        </div>

                        <div className="col-md-6">
                            <label className="form-label">Confirm Email</label>
                            <input type="email" className="form-control" id="confirmEmail" name="confirmEmail" value={formData.confirmEmail} onChange={handleInputChange} onPaste={(e) => e.preventDefault()} />
                            {errors.confirmEmail && <div className="text-danger">{errors.confirmEmail}</div>}
                        </div>

                        <div className="col-md-6">
                            <label className="form-label">Address</label>
                            <input type="text" className="form-control" id="address" name="address" value={formData.address} onChange={handleInputChange}  />
                            {errors.address && <div className="text-danger">{errors.address}</div>}
                        </div>

                        <div className="col-md-6">
                            <label className="form-label">City</label>
                            <input type="text" className="form-control" id="city" name="city" value={formData.city} onChange={handleInputChange} />
                            {errors.city && <div className="text-danger">{errors.city}</div>}
                        </div>

                         <div className="col-md-6">
                            <label className="form-label">State</label>
                            <input type="text" className="form-control" id="state" name="state" value={formData.state} onChange={handleInputChange}  />
                            {errors.state && <div className="text-danger">{errors.state}</div>}
                        </div> 
                       



                        <div className="col-md-6">
                            <label className="form-label">ZIP</label>
                            <input type="text" className="form-control" id="zip" name="zip" value={formData.zip} onChange={handleInputChange}  />
                            {errors.zip && <div className="text-danger">{errors.zip}</div>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="country" className="form-label">
                                Country
                            </label>
                            <Dropdown style={{ width: "100%" }}>
                            <Dropdown.Toggle
                            variant=""
                            id="country"
                            className="form-control custom-dropdown-toggle"
                            >
                            {formData.country
                                ? countryList.find((country) => country.code === formData.country)?.name
                                : "Select a Country"}{" "}
                            {/* Display selected country name or placeholder */}
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                            style={{
                                maxHeight: "200px", // Limit the height of the dropdown menu
                                overflowY: "auto",  // Enable vertical scrolling
                                width: "100%",      // Ensure it matches the width of the dropdown toggle
                            }}
                            >
                            {countryList.map((country) => (
                                <Dropdown.Item
                                key={country.code}
                                onClick={() =>
                                    setFormData((prevData) => ({
                                    ...prevData,
                                    country: country.code,
                                    extension: country.extension, // Update extension when country changes
                                    }))
                                }
                                >
                                {country.name}
                                </Dropdown.Item>
                            ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        </div>


                        <div className="col-md-6">
              <label htmlFor="citizenshipStatus" className="form-label">
                Immigration Status
              </label>
              
              <Dropdown style={{ width: '100%' }}>
          <Dropdown.Toggle
            variant=""
            id="citizenshipStatus"
            className="form-control custom-dropdown-toggle"
          >
            {formData.citizenshipStatus || 'Select an Option'} {/* Display selected option or placeholder */}
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ width: '100%' }}>
            <Dropdown.Item onClick={() => handleSelect("H1-B Visa", "h1")}>
            H1-B Visa
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSelect("J-1 Visa", "j1")}>
            J-1 Visa
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSelect("F1 Visa with OPT", "f1Opt")}>
            F1 Visa with OPT
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSelect("Permanent Residency(Green Card)", "prgc")}>
            Permanent Residency(Green Card)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSelect("Permanent Residency(Citizen)", "prc")}>
            Permanent Residency(Citizen)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSelect("L-1 Visa", "l1visa")}>
            L-1 Visa
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSelect("B-1 Visa", "b1visa")}>
            B-1 Visa
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSelect("O-1 Visa", "o1visa")}>
            O-1 Visa
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
              
            </div>
             </div>

                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>
        </div>
    );
}

export default EmployeeRegistrationForm;

// STATE Dropdown
// <div className="col-md-6">
//                         <label htmlFor="state" className="form-label">
//                             State
//                         </label>
//                         <Dropdown style={{ width: "100%" }}>
//                             <Dropdown.Toggle
//                             variant=""
//                             id="state"
//                             className="form-control custom-dropdown-toggle"
//                             >
//                             {formData.state
//                                 ? usStates.find((state) => state.code === formData.state)?.name
//                                 : "Select a State"}{" "}
//                             {/* Display selected state name or placeholder */}
//                             </Dropdown.Toggle>

//                             <Dropdown.Menu
//                             style={{
//                                 maxHeight: "200px", // Limit the height of the dropdown menu
//                                 overflowY: "auto",  // Enable vertical scrolling
//                                 width: "100%",      // Ensure it matches the width of the dropdown toggle
//                             }}
//                             >
//                             {usStates.map((state) => (
//                                 <Dropdown.Item
//                                 key={state.code}
//                                 onClick={() =>
//                                     setFormData((prevData) => ({
//                                     ...prevData,
//                                     state: state.code, // Update state code in formData
//                                     }))
//                                 }
//                                 >
//                                 {state.name}
//                                 </Dropdown.Item>
//                             ))}
//                             </Dropdown.Menu>
//                         </Dropdown>
// </div>
