import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import menu4 from "../Data/menu4.json"; // Import the new menu4 data

function EmptySidebar(props) {
    const [isSidebarMini, setIsSidebarMini] = useState(false);
    const [menuData, setMenuData] = useState([...menu4?.menu4]); // Set initial state to menu4
    const [darkLightMode, setDarkLightMode] = useState("light");


    
    useEffect(() => {
        window.document.children[0].setAttribute("data-theme", "light");
    }, []);

    function onChangeDarkMode() {
        if (window.document.children[0].getAttribute("data-theme") === "light") {
            window.document.children[0].setAttribute("data-theme", "dark");
            setDarkLightMode("dark");
        } else {
            window.document.children[0].setAttribute("data-theme", "light");
            setDarkLightMode("light");
        }
    }

    const { activekey } = props;

    return (
        <div id="emptySidebar" className={`empty-sidebar px-4 py-4 py-md-5 me-0 ${isSidebarMini ? "sidebar-mini" : ""}`}>
            <div className="d-flex flex-column h-100">
                <a href="hr-dashboard" className="mb-0 brand-icon">
                    <span className="logo-icon">
                        <svg width="35" height="35" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"></path>
                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                        </svg>
                    </span>
                    <span className="logo-text">My-Task</span>
                </a>
                <ul className="menu-list flex-grow-1 mt-3">
                    {
                        menuData.map((d, i) => {
                            return (
                                <li key={"menu-item-" + i} className="collapsed">
                                    <Link to={`${process.env.PUBLIC_URL}` + "/" + d.routerLink[0]} className={`m-link ${(d.routerLink[0] === activekey) ? "active" : ""}`}>
                                        <i className={d.iconClass}></i>
                                        <span>{d.name}</span>
                                    </Link>
                                </li>
                            );
                        })
                    }
                </ul>
                <ul className="list-unstyled mb-0">
                    <li className="d-flex align-items-center justify-content-center">
                        <div className="form-check form-switch theme-switch">
                            <input className="form-check-input" type="checkbox" checked={darkLightMode === "dark"} id="theme-switch" onChange={onChangeDarkMode} />
                            <label className="form-check-label" htmlFor="theme-switch">Enable Dark Mode!</label>
                        </div>
                    </li>
                </ul>
                <button type="button" className="btn btn-link sidebar-mini-btn text-light" onClick={() => { setIsSidebarMini(!isSidebarMini); }}>
                    <span className="ms-2"><i className="icofont-bubble-right"></i></span>
                </button>
            </div>
        </div>
    );
}

export default EmptySidebar;
