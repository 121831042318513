import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import './Other.css';
import '../../components/Pages/IconFontsCard'
import APIClient from "./APIClient";

const CreateLogin = () => {
    const { hash } = useParams(); // Capture employee_temp_id from URL
    const navigate = useNavigate();
    const recaptchaRef = useRef();

    const [formData, setFormData] = useState({
        userId: "", // User ID (email) should be entered manually by the user
        password: "",
        confirmPassword: "",
        otp: ["", "", "", "", "", ""],
        recaptchaToken: "",
        securityQuestions: [{ question: "", answer: "" }, { question: "", answer: "" }, { question: "", answer: "" }],
    });

    const [initialQuestions, setInitialQuestions] = useState([]);
    const [errors, setErrors] = useState({});
    const [otpValid, setOtpValid] = useState(false);
    const [maxQuestions, setMaxQuestions] = useState(8); 

    // References for OTP input fields
    const otpRefs = useRef([...Array(6)].map(() => React.createRef()));

    // Fetch security questions and check OTP validity when the component loads
    useEffect(() => {
        if (hash) {
            checkOtpValidity();
        }
        fetchSecurityQuestions();
    }, [hash]);

    // Fetch security questions
    const fetchSecurityQuestions = async () => {
        try {
            const response = await APIClient.get("/employee/security/questions");
            if (response.data.success) {
                setInitialQuestions(response.data.data);
                setMaxQuestions(response.data.data.length); // Update maxQuestions based on the response
            } else {
                console.error("Failed to fetch security questions");
            }
        } catch (error) {
            console.error("Error fetching security questions:", error);
        }
    };

    // Check OTP validity using employee_temp_id from the URL
    const checkOtpValidity = async () => {
        try {
            const response = await APIClient.get("/employee/create/login/expiry/check", {
                params: { employee_temp_id: hash }
            });

            if (!response.data.success) {
                alert(response.data.message || "Invalid or expired OTP");
                setOtpValid(false);
                navigate("/error-page");
            } else {
                setOtpValid(true);
            }
        } catch (error) {
            setOtpValid(false);
            alert("Error checking OTP validity. Please try again.");
        }
    };

    // Handle changes in the input fields
    const handleInputChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
    };

    // Handle OTP input changes
    const handleOtpChange = (index, value) => {
        if (!/^\d*$/.test(value)) return;

        const newOtp = [...formData.otp];
        newOtp[index] = value;
        setFormData({ ...formData, otp: newOtp });

        if (value && index < otpRefs.current.length - 1) {
            otpRefs.current[index + 1].current.focus();
        }
    };

    // Handle security question change
    const handleSecurityQuestionChange = (index, field, value) => {
        const newQuestions = [...formData.securityQuestions];
        newQuestions[index][field] = value;
        setFormData({ ...formData, securityQuestions: newQuestions });
    };

    // Handle OTP resend
    const handleResendOTP = async () => {
        try {
            const response = await APIClient.get(`/employee/login/otp/resend`, {
                params: { email: formData.userId }
            });

            if (response.data.success) {
                alert("OTP has been resent successfully.");
            } else {
                alert(`Error: ${response.data.message}`);
            }
        } catch (error) {
            alert("Error resending OTP. Please try again.");
        }
    };

    // Handle ReCAPTCHA change
    const onRecaptchaChange = (token) => {
        setFormData({ ...formData, recaptchaToken: token });
    };

    // Add a new security question
    const handleAddQuestion = () => {
        if (formData.securityQuestions.length < maxQuestions) {
            setFormData({
                ...formData,
                securityQuestions: [...formData.securityQuestions, { question: "", answer: "" }]
            });
        }
    };

    // Handle removal of a specific security question
    const handleRemoveSingleQuestion = (index) => {
        if (formData.securityQuestions.length > 3) {
            const updatedQuestions = formData.securityQuestions.filter((_, i) => i !== index);
            setFormData({ ...formData, securityQuestions: updatedQuestions });
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!otpValid) {
            alert("OTP is invalid or expired. Please request a new OTP.");
            return;
        }

        const createLoginData = {
            otp: formData.otp.join(""),
            email: formData.userId,
            password: formData.password,
            employee_temp_id: hash, // Include employee_temp_id (hash) for submission
            questions: formData.securityQuestions.map((q) => ({
                ques_id: initialQuestions.find(question => question.question === q.question)?.ques_id,
                answer: q.answer
            }))
        };

        try {
            const response = await APIClient.post("/employee/login/create", createLoginData);

            if (response.data.success) {
                alert("Login successfully created!");
                navigate("/app/login"); 
            } else {
                alert(`Error: ${response.data.message}`);
            }
        } catch (error) {
            alert("Error creating login. Please try again.");
        }
    };

    return (
        <div className="card mb-3">
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold">Create Login</h6>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row g-3 align-items-center">
                        {/* OTP fields */}
                        <div className="col-md-6">
                            <label className="form-label">Enter OTP</label>
                            <div className="d-flex">
                                {formData.otp.map((digit, index) => (
                                    <input
                                        key={index}
                                        ref={otpRefs.current[index]}
                                        type="text"
                                        maxLength="1"
                                        className="form-control me-2 otp-input"
                                        value={digit}
                                        onChange={(e) => handleOtpChange(index, e.target.value)}
                                        onFocus={(e) => e.target.select()}
                                        required
                                    />
                                ))}
                            </div>
                            {!otpValid && <div className="text-danger">OTP is invalid or expired</div>}
                        </div>

                        <div className="col-md-6">
                            <button type="button" className="btn btn-secondary mt-4" onClick={handleResendOTP}>
                                Resend OTP
                            </button>
                        </div>

                        <div className="col-md-7">
                            <label htmlFor="userId" className="form-label">User ID (Email)</label>
                            <input type="email" className="form-control" id="userId" value={formData.userId} onChange={(e) => handleInputChange("userId", e.target.value)} required />
                        </div>

                        {/* Password fields */}
                        <div className="col-md-6">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input type="password" className="form-control" id="password" value={formData.password} onChange={(e) => handleInputChange("password", e.target.value)} required />
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                            <input type="password" className="form-control" id="confirmPassword" value={formData.confirmPassword} onChange={(e) => handleInputChange("confirmPassword", e.target.value)} required />
                        </div>

                        <div className="alert alert-secondary">
                                    <p>
                                        Please answer at least three security questions. 
                                    </p>
                                </div>

                        {/* Security questions */}
                        {formData.securityQuestions.map((question, index) => (
                        <React.Fragment key={index}>
                            <div className="col-md-6">
                                <label htmlFor={`securityQuestion${index}`} className="form-label">Security Question {index + 1}</label>
                                <select
                                    id={`securityQuestion${index}`}
                                    className="form-select"
                                    value={question.question}
                                    onChange={(e) => handleSecurityQuestionChange(index, "question", e.target.value)}
                                    required
                                >
                                    <option value="">Select a question</option>
                                    {initialQuestions.map((q, idx) => (
                                        <option key={idx} value={q.question}>{q.question}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-md-6 d-flex align-items-center">
                                <input
                                    type="text"
                                    className="form-control"
                                    id={`securityAnswer${index}`}
                                    value={question.answer}
                                    onChange={(e) => handleSecurityQuestionChange(index, "answer", e.target.value)}
                                    required
                                />
                                {formData.securityQuestions.length > 3 && (
                                    <div className="icon-holder ms-2" onClick={() => handleRemoveSingleQuestion(index)} style={{ cursor: "pointer" }}>
                                        <div className="icon">
                                            <i className="icofont-trash"></i>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    ))}
    
                        {/* Add question button */}
                        {formData.securityQuestions.length < maxQuestions && (
                            <div className="col-md-12 text-left">
                                <button type="button" className="btn btn-primary" onClick={handleAddQuestion}>
                                    Add Security Question
                                </button>
                            </div>
                        )}

                        {/* ReCAPTCHA */}
                        <div className="col-md-12">
                            <ReCAPTCHA ref={recaptchaRef} sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" onChange={onRecaptchaChange} />
                        </div>

                        {/* Submit button */}
                        <div className="col-md-12 text-left">
                            <button type="submit" className="btn btn-primary">Create Login</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateLogin;
