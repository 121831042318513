import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";

function OnboardingW4() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    ssn: '', // SSN is fetched but will be readOnly
    address: '',
    city: '',
    state: '',
    zip: '',
    selectOption: '',
    signature: '',
  });
   // Declare useState

  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const jwt_token = Cookies.get("jwt_token");
      const session_id = Cookies.get("session_id");

      if (jwt_token) {
        try {
          const response = await axios.get(`http://localhost:3500/employee/w4-details/${session_id}`, {
            headers: {
              Authorization: `Bearer ${jwt_token}`,
            },
            withCredentials: true, // Include cookies
          });

          const data = response.data.data;
          setFormData((prev) => ({
            ...prev,
            firstName: data.firstName,
            lastName: data.lastName,
            ssn: data.ssn, // SSN will be displayed but uneditable
            address: data.address,
            selectOption: data.w4TaxStatus,
          }));
        } catch (error) {
          console.error("Error during onboarding:", error);

          if (error.response && error.response.status === 403) {
            alert("Your session has expired. Please log in again.");
          } else {
            alert("An error occurred during onboarding. Please login again.");
          }
        }
      }
    };

    fetchData();
  }, []);

  const formatSSN = (value) => {
    // Remove any non-numeric characters except for the dash
    const cleanedValue = value.replace(/\D/g, '');

    // Format the cleaned value to NNN-NN-NNNN
    const formattedSSN = cleanedValue
      .slice(0, 9) // Ensure max length is 9 digits
      .replace(/(\d{3})(\d{2})(\d{0,4})/, '$1-$2-$3');

    return formattedSSN;
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the field is 'ssn' and apply formatting
    if (name === "ssn") {
      const formattedSSN = formatSSN(value);
      setFormData({
        ...formData,
        [name]: formattedSSN,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setErrors({
      ...errors,
      [name]: '',
    });
  };
    
  const validate = () => {
    let formErrors = {};
    if (!formData.firstName) formErrors.firstName = "First name is required";
    if (!formData.lastName) formErrors.lastName = "Last name is required";
    if (!formData.ssn) {
      formErrors.ssn = "SSN is required";
    } else if (!/^\d{3}-\d{2}-\d{4}$/.test(formData.ssn)) {
      formErrors.ssn = "SSN should be in the format NNN-NN-NNNN";
    }
    if (!formData.address) formErrors.address = "Address is required";
    if (!formData.dropdownOption)
      formErrors.dropdownOption = "Please select an option";
    if (!formData.signature) formErrors.signature = "Signature is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  
  const handleSave = () => {
    if (validate()) {
      console.log("form saved:", formData);
      alert("form saved successfully.");
    }else {
        console.log("Validation errors found: ", errors);
        alert("Please correct the errors before saving.");
    }
  };
  const handleSubmit = async () => {
    if (validate()) {
      try {
        const payload = {
          session_id: Cookies.get("session_id"),
          firstname: formData.firstName,
          lastname: formData.lastName,
          address: formData.address,
          filing_status: formData.selectOption,
          signature: formData.signature,
        };

        const jwt_token = Cookies.get("jwt_token");
        const response = await axios.post("http://0.0.0.0:3500/employee/w4/onboard", payload, {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        });
        console.log("Form submitted successfully:", response.data);
        alert("Form submitted successfully");
      } catch (error) {
        console.error("Error submitting W-4 form:", error);
        alert("Error submitting W-4 form. Please login again.");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="card mb-3">
        <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
          <h3 className="mb-0 fw-bold">Form W-4</h3>
        </div>
        <div className="card-body">
          <div className="alert alert-info">
            <p>
              I am aware that federal law provides for imprisonment and/or fines
              for false statements or use of false documents in connection with
              the completion of this form.
            </p>
            <p>
              I attest, under penalty of perjury, that I am (check one of the
              following boxes):
            </p>
          </div>
          {/* <div className="row g-3 align-items-center"> */}
            <div className="col-md-12">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              {errors.firstName && (
                <span style={{ color: "red" }}>{errors.firstName}</span>
              )}
            </div>
            <div className="col-md-12">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="form-control"
                value={formData.lastName}
                onChange={handleInputChange}
              />
              {errors.lastName && (
                <span style={{ color: "red" }}>{errors.lastName}</span>
              )}
            </div>
            <div className="col-md-12">
              <label htmlFor="ssn" className="form-label">
                SSN
              </label>
              <input
                type="text"
                name="ssn"
                id="ssn"
                className="form-control"
                value={formData.ssn}
                onChange={handleInputChange}
              />
              {errors.ssn && (
                <span style={{ color: "red" }}>{errors.ssn}</span>
              )}
            </div>
            <div className="col-md-12">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <input
                type="text"
                name="address"
                id="address"
                className="form-control"
                value={formData.address}
                onChange={handleInputChange}
              />
              {errors.address && (
                <span style={{ color: "red" }}>{errors.address}</span>
              )}
            </div>
            <div className="col-md-12">
              <label htmlFor="dropdownOption" className="form-label">
                Select an Option
              </label>
              <select
                name="dropdownOption"
                className="form-control"
                id="dropdownOption"
                value={formData.dropdownOption}
                onChange={handleInputChange}
              >
                <option value="">Select an option</option>
                <option value="single">
                  Single or Married filing separately
                </option>
                <option value="jointly">
                  Married filing jointly or Qualifying surviving spouse
                </option>
                <option value="head">Head of household</option>
              </select>
              {errors.dropdownOption && (
                <span style={{ color: "red" }}>{errors.dropdownOption}</span>
              )}
            </div>

            <div className="col-md-12">
              <label htmlFor="signature" className="form-label">
                Signature
              </label>
              <input
                type="text"
                name="signature"
                id="signature"
                className="form-control"
                value={formData.signature}
                onChange={handleInputChange}
              />
              {errors.signature && (
                <span style={{ color: "red" }}>{errors.signature}</span>
              )}
            </div>
          </div>
        {/* </div> */}
      </div>
      <div>
        <button type="button" className="btn btn-secondary mt-4" onClick={handleSave}>
          Save
        </button>
        <button type="submit" className="btn btn-success mt-4">Submit</button>
      </div>
    </form>
  );
}

export default OnboardingW4;
