import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../screens/AuthContext"; // Import AuthContext
import menuData from "../Data/menuData.json";
import Cookies from "js-cookie";
import APIClient from "../../screens/OtherPages/APIClient";

function Sidebar(props) {
    const [isSidebarMini, setIsSidebarMini] = useState(false);
    const { authState, logout, updateAuthState } = useAuth();
    const [firstName, setFirstName] = useState(authState.firstName || "User");
    const { isLoggedIn } = authState; // Read login status from authState
    const [darkLightMode, setDarkLightMode] = useState("light");
    // const [updateRtl, setUpdateRtl] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFirstName = async () => {
            const jwt_token = Cookies.get("jwt_token");
            if (isLoggedIn && jwt_token) {
                try {
                    const response = await APIClient.get(`/employee/welcome`, {
                        headers: {
                            Authorization: `Bearer ${jwt_token}`,
                        },
                        withCredentials: true,
                    });

                    if (response.data.success) {
                        const fetchedFirstName = response.data.data.firstName;
                        setFirstName(fetchedFirstName);
                        updateAuthState({ firstName: fetchedFirstName }); // Update AuthContext
                    }
                } catch (error) {
                    console.error("Error fetching first name:", error);
                }
            }
        };

        fetchFirstName();
    }, [isLoggedIn]); // Fetch first name only when login status changes

    // Handle Logout
    const handleLogout = () => {
        Cookies.remove("jwt_token");
        logout(); // Update global auth state
        setFirstName("User"); // Reset first name
        navigate("/app/login"); // Redirect to login page
    };
    function onChangeDarkMode() {
        if (window.document.children[0].getAttribute("data-theme") === "light") {
            window.document.children[0].setAttribute("data-theme", "dark");
            setDarkLightMode("dark");
        } else {
            window.document.children[0].setAttribute("data-theme", "light");
            setDarkLightMode("light");
        }
    }

    const { activekey } = props;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    // Navigate to the selected dropdown page
    const handleDropdownSelect = (path) => {
        navigate(path);
        setIsDropdownOpen(false); // Close dropdown after selecting
    };
    // Handle Logo Click
    const handleLogoClick = async () => {
        const jwt_token = Cookies.get("jwt_token");
        if (jwt_token) {
            try {
                const response = await APIClient.get(`/employee/welcome`, {
                    headers: {
                        Authorization: `Bearer ${jwt_token}`,
                    },
                    withCredentials: true,
                });

                if (response.data.success) {
                    navigate("/app/dashboard"); // Redirect to dashboard
                } else {
                    handleLogout(); // Redirect to login if auth fails
                }
            } catch (error) {
                console.error("Error verifying authentication:", error);
                handleLogout(); // Redirect to login on error
            }
        } else {
            handleLogout(); // Redirect to login if no token
        }
    };

    const activeMenu = isLoggedIn ? menuData.menuAfterLogin : menuData.menuBeforeLogin;

    return (
        <div id="mainSideMenu" className={`sidebar px-4 py-4 py-md-5 me-0 ${isSidebarMini ? "sidebar-mini" : ""}`}>
            <div className="d-flex flex-column h-100">
                {/* Logo with Title */}
                <a onClick={handleLogoClick} className="mb-0 brand-icon d-flex align-items-center" style={{ cursor: "pointer" }}>
                    <i className="material-icons logo-icon">dashboard</i>
                    <span className="logo-text ms-2">My-HRMS</span>
                </a>

                {/* Welcome Message */}
                {isLoggedIn && (
                    <div className="welcome-message mt-3">
                        <h4 className="text-white">Welcome, {firstName}!</h4>
                    </div>
                )}

                {/* Menu List */}
                <ul className="menu-list flex-grow-1 mt-3">
                    {activeMenu.map((menuItem, index) => (
                        <li key={index} className="collapsed">
                            <Link to={`${process.env.PUBLIC_URL}/${menuItem.routerLink[0]}`} className="m-link d-flex align-items-center">
                                <i className="material-icons">{menuItem.iconClass}</i>
                                <span className="ms-2">{menuItem.name}</span>
                            </Link>
                        </li>
                    ))}
                </ul>

                {/* Logout Button */}
                {isLoggedIn && (
                    <button
                        className="btn btn-danger mt-3 px-4 py-2"
                        onClick={handleLogout}
                        style={{ backgroundColor: "var(--secondary-color)", color: "white" }}
                    >
                        Logout
                    </button>
                )}

<ul className="list-unstyled mb-0 mt-4">
                    <li className="d-flex align-items-center justify-content-center">
                        <div className="form-check form-switch theme-switch">
                            <input className="form-check-input" type="checkbox" checked={darkLightMode === "dark"} id="theme-switch" onChange={onChangeDarkMode} />
                            <label className="form-check-label" htmlFor="theme-switch">Enable Dark Mode!</label>
                        </div>
                    </li>
                </ul>
                <button type="button" className="btn btn-link sidebar-mini-btn text-light" onClick={() => { setIsSidebarMini(!isSidebarMini); }}>
                    <span className="ms-2"><i className="icofont-bubble-right"></i></span>
                </button>
            </div>
        </div>
    );
}

export default Sidebar;



// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { useAuth } from "../../screens/AuthContext"; // Import AuthContext
// import menuData from "../Data/menuData.json";
// import Cookies from "js-cookie";
// import axios from "axios";

// function Sidebar(props) {
//     const [isSidebarMini, setIsSidebarMini] = useState(false);
//     const { authState, logout, updateAuthState } = useAuth();
//     const [firstName, setFirstName] = useState(authState.firstName || "User");
//     const { isLoggedIn } = authState; // Read login status from authState
//     const [darkLightMode, setDarkLightMode] = useState("light");
//     const [updateRtl, setUpdateRtl] = useState(false);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const fetchFirstName = async () => {
//             const jwt_token = Cookies.get("jwt_token");
//             if (isLoggedIn && jwt_token) {
//                 try {
//                     const response = await axios.get("http://0.0.0.0:3500/employee/welcome", {
//                         headers: {
//                             Authorization: `Bearer ${jwt_token}`,
//                         },
//                         withCredentials: true,
//                     });

//                     if (response.data.success) {
//                         const fetchedFirstName = response.data.data.firstName;
//                         setFirstName(fetchedFirstName);
//                         updateAuthState({ firstName: fetchedFirstName }); // Update AuthContext
//                     }
//                 } catch (error) {
//                     console.error("Error fetching first name:", error);
//                 }
//             }
//         };

//         fetchFirstName();
//     }, [isLoggedIn]); // Fetch first name only when login status changes

//     // Handle Logout
//     const handleLogout = () => {
//         Cookies.remove("jwt_token");
//         logout(); // Update global auth state
//         setFirstName("User"); // Reset first name
//         navigate("/app/login"); // Redirect to login page
//     };
//     function onChangeDarkMode() {
//         if (window.document.children[0].getAttribute("data-theme") === "light") {
//             window.document.children[0].setAttribute("data-theme", "dark");
//             setDarkLightMode("dark");
//         } else {
//             window.document.children[0].setAttribute("data-theme", "light");
//             setDarkLightMode("light");
//         }
//     }

//     function onChangeRTLMode() {
//         if (document.body.classList.contains("rtl_mode")) {
//             document.body.classList.remove("rtl_mode");
//         } else {
//             document.body.classList.add("rtl_mode");
//         }
//         setUpdateRtl(!updateRtl);
//     }

//     const { activekey } = props;
//     const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//     const toggleDropdown = () => {
//         setIsDropdownOpen(!isDropdownOpen);
//     };

//     // Navigate to the selected dropdown page
//     const handleDropdownSelect = (path) => {
//         navigate(path);
//         setIsDropdownOpen(false); // Close dropdown after selecting
//     };
//     // Handle Logo Click
//     const handleLogoClick = async () => {
//         const jwt_token = Cookies.get("jwt_token");
//         if (jwt_token) {
//             try {
//                 const response = await axios.get("http://0.0.0.0:3500/employee/welcome", {
//                     headers: {
//                         Authorization: `Bearer ${jwt_token}`,
//                     },
//                     withCredentials: true,
//                 });

//                 if (response.data.success) {
//                     navigate("/app/dashboard"); // Redirect to dashboard
//                 } else {
//                     handleLogout(); // Redirect to login if auth fails
//                 }
//             } catch (error) {
//                 console.error("Error verifying authentication:", error);
//                 handleLogout(); // Redirect to login on error
//             }
//         } else {
//             handleLogout(); // Redirect to login if no token
//         }
//     };

//     const activeMenu = isLoggedIn ? menuData.menuAfterLogin : menuData.menuBeforeLogin;

//     return (
//         <div id="mainSideMenu" className={`sidebar px-4 py-4 py-md-5 me-0 ${isSidebarMini ? "sidebar-mini" : ""}`}>
//             <div className="d-flex flex-column h-100">
//                 {/* Logo with Title */}
//                 <a onClick={handleLogoClick} className="mb-0 brand-icon d-flex align-items-center" style={{ cursor: "pointer" }}>
//                     <i className="material-icons logo-icon">dashboard</i>
//                     <span className="logo-text ms-2">My-HRMS</span>
//                 </a>

//                 {/* Welcome Message */}
//                 {isLoggedIn && (
//                     <div className="welcome-message mt-3">
//                         <h4 className="text-white">Welcome, {firstName}!</h4>
//                     </div>
//                 )}

//                 {/* Menu List */}
//                 <ul className="menu-list flex-grow-1 mt-3">
//                     {activeMenu.map((menuItem, index) => (
//                         <li key={index} className="collapsed">
//                             <Link to={`${process.env.PUBLIC_URL}/${menuItem.routerLink[0]}`} className="m-link d-flex align-items-center">
//                                 <i className="material-icons">{menuItem.iconClass}</i>
//                                 <span className="ms-2">{menuItem.name}</span>
//                             </Link>
//                         </li>
//                     ))}
//                 </ul>

//                 {/* Logout Button */}
//                 {isLoggedIn && (
//                     <button
//                         className="btn btn-danger mt-3 px-4 py-2"
//                         onClick={handleLogout}
//                         style={{ backgroundColor: "var(--secondary-color)", color: "white" }}
//                     >
//                         Logout
//                     </button>
//                 )}

// <ul className="list-unstyled mb-0 mt-4">
//                     <li className="d-flex align-items-center justify-content-center">
//                         <div className="form-check form-switch theme-switch">
//                             <input className="form-check-input" type="checkbox" checked={darkLightMode === "dark"} id="theme-switch" onChange={onChangeDarkMode} />
//                             <label className="form-check-label" htmlFor="theme-switch">Enable Dark Mode!</label>
//                         </div>
//                     </li>
//                     <li className="d-flex align-items-center justify-content-center">
//                         <div className="form-check form-switch theme-rtl">
//                             <input className="form-check-input" type="checkbox" checked={document.body.classList.contains("rtl_mode")} id="theme-rtl" onChange={onChangeRTLMode} />
//                             <label className="form-check-label" htmlFor="theme-rtl">Enable RTL Mode!</label>
//                         </div>
//                     </li>
//                 </ul>
//                 <button type="button" className="btn btn-link sidebar-mini-btn text-light" onClick={() => { setIsSidebarMini(!isSidebarMini); }}>
//                     <span className="ms-2"><i className="icofont-bubble-right"></i></span>
//                 </button>
//             </div>
//         </div>
//     );
// }

// export default Sidebar;