import React, { useState } from 'react';
import './UploadComponent.css'; 

const UploadComponent = ({ isOpen, onClose, fields }) => {
    const [formData, setFormData] = useState({}); 
    const [selectedFile, setSelectedFile] = useState(null); 
    const [error, setError] = useState(''); 

    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB

    const handleChange = (event) => {
        const { name, value, type, files } = event.target;
        if (type === 'file') {
            const file = files[0];
            if (file && file.size > MAX_FILE_SIZE) {
                setError(`File size should not exceed ${MAX_FILE_SIZE / (1024 * 1024)} MB`);
                setSelectedFile(null); 
            } else {
                setError(''); 
                setSelectedFile(file); 
            }
        } else if (type === 'date') {

            const formattedDate = formatDate(value); // Convert date to MM-DD-YYYY

            setFormData((prevData) => ({ ...prevData, [name]: formattedDate }));
        } else {
            setFormData((prevData) => ({ ...prevData, [name]: value }));
        }
    };
    const formatDate = (value) => {

        const date = new Date(value);

        const day = String(date.getDate()).padStart(2, '0');

        const month = String(date.getMonth() + 1).padStart(2, '0');

        const year = date.getFullYear();

        return `${month}-${day}-${year}`;

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (error) {
            alert(error); 
            return;
        }
        // Handle document upload logic here
        const uploadData = { ...formData, file: selectedFile }; 
        console.log("Document Uploaded:", uploadData);
        onClose(); 
    };


    return (
        isOpen && (
            <div className="upload-popup">
                <div className="upload-popup-content">
                    <span className="close" onClick={onClose}>&times;</span>
                    <h2>Upload Document</h2>
                    {error && <div className="error-message">{error}</div>} 
                    <form onSubmit={handleSubmit}>
                        {fields.map((field, index) => (
                            <div key={index} className="mb-3">
                                <label>{field.label}</label>
                                {field.type === 'text' && (
                                    <input
                                        type="text"
                                        className="form-control"
                                        name={field.name}
                                        value={formData[field.name] || ''}
                                        onChange={handleChange}
                                        required={field.required}
                                    />
                                )}
                                {field.type === 'textarea' && (
                                    <textarea
                                        className="form-control"
                                        name={field.name}
                                        value={formData[field.name] || ''}
                                        onChange={handleChange}
                                    ></textarea>
                                )}
                                {field.type === 'select' && (
                                    <select
                                        className="form-select"
                                        name={field.name}
                                        onChange={handleChange}
                                        required={field.required}
                                    >
                                        <option value="">Select {field.label}</option>
                                        {field.options.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                )}
                                {field.type === 'file' && (
                                    <input
                                        type="file"
                                        className="form-control"
                                        name={field.name}
                                        onChange={handleChange}
                                        required={field.required}
                                    />
                                )}
                                {field.type === 'date' && (
                                        <input
                                        type="date"
                                        className="form-control"
                                        name={field.name}
                                        onChange={handleChange}
                                        required={field.required}
                                    />
                                    )}
                            </div>
                        ))}
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
        )
    );
};

export default UploadComponent;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './UploadComponent.css'; 
// import Cookies from "js-cookie";


// const UploadComponent = ({ isOpen, onClose }) => {
//     const [formData, setFormData] = useState({}); 
//     const [selectedFile, setSelectedFile] = useState(null); 
//     const [error, setError] = useState(''); 
//     const [documentTypes, setDocumentTypes] = useState([]); // State to hold document types

//     const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB

//     useEffect(() => {
//         // Fetch document types from the backend
//         const fetchDocumentTypes = async () => {
//             try {
//                 const response = await axios.get('http://0.0.0.0:3500/employee/document-types');
//                 if (response.data.success) {
//                     setDocumentTypes(response.data.data);
//                 } else {
//                     console.error('Error fetching document types:', response.data.message);
//                 }
//             } catch (error) {
//                 console.error('Error fetching document types:', error);
//             }
//         };

//         if (isOpen) {
//             fetchDocumentTypes(); // Fetch document types only when the popup is open
//         }
//     }, [isOpen]);

//     const handleChange = (event) => {
//         const { name, value, type, files } = event.target;
//         if (type === 'file') {
//             const file = files[0];
//             if (file && file.size > MAX_FILE_SIZE) {
//                 setError(`File size should not exceed ${MAX_FILE_SIZE / (1024 * 1024)} MB`);
//                 setSelectedFile(null); 
//             } else {
//                 setError(''); 
//                 setSelectedFile(file); 
//             }
//         } else {
//             setFormData((prevData) => ({ ...prevData, [name]: value }));
//         }
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         if (error) {
//             alert(error); 
//             return;
//         }
    
//         if (!selectedFile) {
//             alert("Please upload a file");
//             return;
//         }
//         const session_id = Cookies.get("session_id"); // Retrieve token from cookies

//         // Create a FormData object to handle file and other data together
//         const formDataToUpload = new FormData();
//         formDataToUpload.append('session_id', Cookies.get("session_id")); 
//         formDataToUpload.append('doc_name', formData.doc_name);
//         formDataToUpload.append('doc_type', formData.doc_type);
//         formDataToUpload.append('comments', formData.comments);
//         formDataToUpload.append('file', selectedFile);
//         // const payload = {
//         //     session_id: Cookies.get("session_id"),
//         //   };
    
//         try {
//             const jwt_token = Cookies.get("jwt_token"); // Retrieve token from cookies

//             const response = await axios.post('http://0.0.0.0:3500/employee/document/upload', formDataToUpload, {
//                 headers: {
//                     Authorization: `Bearer ${jwt_token}`,
//                 },
//                 withCredentials: true,
//             });
    
//             if (response.data.success) {
//                 console.log("Document uploaded successfully:", response.data);
//                 alert("Document uploaded successfully");
//             } else {
//                 console.error("Error uploading document:", response.data.message);
//                 alert("Error uploading document: " + response.data.message);
//             }
//         } catch (error) {
//             console.error("Error uploading document:", error);
//             alert("Error uploading document. Please try again.");
//         }
    
//         onClose(); // Close the popup after successful submission
//     };
    

//     return (
//         isOpen && (
//             <div className="upload-popup">
//                 <div className="upload-popup-content">
//                     <span className="close" onClick={onClose}>&times;</span>
//                     <h2>Upload Document</h2>
//                     {error && <div className="error-message">{error}</div>} 
//                     <form onSubmit={handleSubmit}>
//                         <div className="mb-3">
//                             <label>Document Name</label>
//                             <input
//                                 type="text"
//                                 className="form-control"
//                                 name="doc_name"
//                                 value={formData.doc_name || ''}
//                                 onChange={handleChange}
//                                 required
//                             />
//                         </div>
//                         <div className="mb-3">
//                             <label>Document Type</label>
//                             <select
//                                 className="form-select"
//                                 name="doc_type"
//                                 value={formData.doc_type || ''}
//                                 onChange={handleChange}
//                                 required
//                             >
//                                 <option value="">Select Document Type</option>
//                                 {documentTypes.map((type) => (
//                                     <option key={type.forms_master_id} value={type.form_type}>
//                                         {type.form_type}
//                                     </option>
//                                 ))}
//                             </select>
//                         </div>
//                         <div className="mb-3">
//                             <label>Comments</label>
//                             <textarea
//                                 className="form-control"
//                                 name="comments"
//                                 value={formData.comments || ''}
//                                 onChange={handleChange}
//                             ></textarea>
//                         </div>
//                         <div className="mb-3">
//                             <label>Upload File</label>
//                             <input
//                                 type="file"
//                                 className="form-control"
//                                 name="file"
//                                 onChange={handleChange}
//                                 required
//                             />
//                         </div>
//                         <button type="submit" className="btn btn-primary">Submit</button>
//                     </form>
//                 </div>
//             </div>
//         )
//     );
// };

// export default UploadComponent;