import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "./../../../src/assets/scss/main.scss"
import APIClient from "./APIClient";



const OnboardingI9 = () => {
    const [activeSection, setActiveSection] = useState('section1');
    const [listADocument, setListADocument] = useState("");
    const [listBDocument, setListBDocument] = useState(""); // Selected document from List B
    const [listCDocument, setListCDocument] = useState(""); // Selected document from List C
    const [listBFile, setListBFile] = useState(null); // Track List B file upload
  const [listCFile, setListCFile] = useState(null); // Track List C file upload
  const [listAFile, setListAFile] = useState(null);
  const [listAFile1, setListAFile1] = useState(null);
  const [listAFile2, setListAFile2] = useState(null);
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        ssn: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
        citizenshipStatus: "",
        signature:""
        
    });
    

    
    
    // Fetch employee details and update formData based on cookies
    useEffect(() => {
        const fetchData = async () => {
            // const session_id = Cookies.get("session_id");
            const jwt_token = Cookies.get("jwt_token");
            console.log('Cookies (inside useEffect): ', document.cookie); // Check cookies
            
            if (jwt_token) {
                try {
                    const response = await APIClient.get(`/employee/i9/details`, {
                        // withCredentials: true, // Ensures cookies like JWT and session_id are sent with the request
                        headers: {
                            Authorization: `Bearer ${jwt_token}`, // Include JWT in Authorization header
                        },
                    });
                    const data = response.data.data;
          
          // Ensure SSN is a string and then format it
          const formattedSSN = formatSSN(data.ssn.toString());
                    // Update formData based on the response
                    setFormData({
                        firstName: response.data.data.first_name || "",
                        lastName: response.data.data.last_name || "",
                        ssn: formattedSSN || "",
                        address: response.data.data.address || "",
                        city: response.data.data.city || "",
                        state: response.data.data.state || "",
                        zipcode: response.data.data.zip || "",
                        citizenshipStatus: response.data.data.citizenship_status || "",
                        expirationDate: response.data.data.non_citizen_expiry || "",
                        arn: response.data.data.arn || "",
                        i94Number: response.data.data.i94an || "",
                        passportNumber: response.data.data.fpn || "",
                        countryOfIssuance: response.data.data.country_of_issuance || ""
                    });

                } catch (error) {
                    alert("Error fetching employee details:", error);
                }
            } else {
                alert("Missing employee ID or JWT token.");
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures it runs only once after the component mounts

    const handleSubmit = async (e) => {
      
        e.preventDefault();
        const jwt_token = Cookies.get("jwt_token");
        
        
            
        // Map frontend form data to backend field names, excluding SSN
        const payload = {
            // session_id: Cookies.get("session_id"), // Get employee_id from cookies
            first_name: formData.firstName,          // Change to first_name
            last_name: formData.lastName,            // Change to last_name
            address: formData.address,
            city: formData.city,
            state: formData.state,
            zip: formData.zipcode,                  // Change to zip
            citizenship_status: formData.citizenshipStatus, // Change to citizenship_status
            expiration_date: formData.expirationDate,
            arn: formData.alienNumber,
            i94an: formData.i94Number,
            fpn: formData.passportNumber,
            country_of_issuance: formData.countryOfIssuance // Change to match backend
            // Excluding ssn from payload as it's uneditable and already exists in the system
        };
        if (!validateSection2()) {
          
          alert("Please correct the errors before saving.");
        }
    
        try {
            const response = await APIClient.post("/employee/onboard", payload, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${jwt_token}`
                }
            });
            alert("Onboarding successful:", response.data);
        } catch (error) {
            alert("Error during onboarding:", error);
        }
        
    };

    
      

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "expirationDate") {
          const formattedDate = formatExpirationDate(value); // Format the date
          setFormData({ ...formData, [name]: formattedDate });
        }else if (name === "ssn") {
          const formattedSSN = formatSSN(value);
          setFormData({
            ...formData,
            [name]: formattedSSN,
          });
        } else if (name === "alienNumber"|| name==="passportNumber"|| name==="i94Number") {
          const alphanumericValue = value.toUpperCase().replace(/[^A-Z0-9]/g, "");
          setFormData((prevState) => ({
              ...prevState,
              [name]: alphanumericValue,
          }));
      } 
        else {
          setFormData({ ...formData, [name]: value });
        }
        setErrors({ ...errors, [name]: "" });
      };
      const formatExpirationDate = (value) => {
        // Remove all non-numeric characters except dash
        const cleanedValue = value.replace(/[^\d]/g, "");
    
        // Format value to MM-DD-YYYY
        const parts = [];
        if (cleanedValue.length > 0) parts.push(cleanedValue.slice(0, 2));
        if (cleanedValue.length > 2) parts.push(cleanedValue.slice(2, 4));
        if (cleanedValue.length > 4) parts.push(cleanedValue.slice(4, 8));
    
        return parts.join("-");
      };

      const validateField = (name, value) => {
        let error = "";
        if (!name) {
            return "Field name is missing.";
          }
        if (!value) {
            // Ensure value is defined before applying string methods
            error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required.`;
          }
          if (name === "citizenshipStatus" && !value) {
            error = "Please select a citizenship status.";
          }
          
          
        
      if (name === "citizenshipStatus" && !value) {
        error = "Please select a citizenship status.";
      }
      if (name === "passportNumber" && !/^[A-Za-z0-9]+$/.test(value)) {
        error = "Passport number should be alphanumeric.";
      }
      
        return error;
      };
      const validateSection1 = () => {
        const fieldsToValidate = [
          "firstName",
          "lastName",
          "ssn",
          "address",
          "city",
          "state",
          "zipcode",
          "citizenshipStatus",
          
          "signature"
          
        ];
        if (formData.citizenshipStatus == "nonCitizen") {
            fieldsToValidate.push("documentType", "expirationDate", );
        }
        let newErrors = {};
    
        fieldsToValidate.forEach((field) => {
          const error = validateField(field, formData[field]);
          if (error) {
            newErrors[field] = error;
          }
        });
        if(formData.citizenshipStatus== "resident"){
          if(!formData.alienNumber){
          newErrors.alienNumber="Enter Alien Registration Number"
          }
        }
        if (formData.citizenshipStatus == "nonCitizen"){
        if (formData.documentType === "alienNumber" && !formData.alienNumber) {
            newErrors.alienNumber = "Alien Registration Number is required for this document type.";
          }
        
          else if (formData.documentType === "i94Number" && !formData.i94Number) {
            newErrors.i94Number = "Form I-94 Admission Number is required for this document type.";
          }
        
          if (!formData.documentType) {
            newErrors.documentType = "Please select a document type";
          }
        
          if (formData.documentType === "passportNumber") {
            if (!formData.passportNumber) {
              newErrors.passportNumber = "Foreign Passport Number is required for this document type.";
            }
            if (!formData.countryOfIssuance) {
              newErrors.countryOfIssuance = "Country of issuance is required if you provide a Foreign Passport Number.";
            }
    
        }
    }
    
        setErrors(newErrors);
    
        return Object.keys(newErrors).length === 0;
      };
      const formatSSN = (value) => {
        // Remove any non-numeric characters except for the dash
        const cleanedValue = value.replace(/\D/g, '');
    
        // Format the cleaned value to NNN-NN-NNNN
        const formattedSSN = cleanedValue
          .slice(0, 9) // Ensure max length is 9 digits
          .replace(/(\d{3})(\d{2})(\d{0,4})/, '$1-$2-$3');
    
        return formattedSSN;
      };
      const validateSection2 = () => {
        let newErrors = {};
        if(!formData.formsAvailable){
          newErrors.formsAvailable = "Please select a option.";
        }
        if (formData.formsAvailable === "listA") {
          if (!listADocument) {
            newErrors.listADocument = "Please upload a document for List A.";
          }
          if (!listAFile) {
            newErrors.listAFile = "Please upload a document for List A.";
          }
        }
    
        if (formData.formsAvailable === "listBC") {
          if (!listBDocument) {
            newErrors.listBDocument = "Please select a document from List B.";
          }
    
          if (!listCDocument) {
            newErrors.listCDocument = "Please select a document from List C.";
          }
    
          if (!listBFile) {
            newErrors.listBFile = "Please upload a document for List B.";
          }
    
          if (!listCFile) {
            newErrors.listCFile = "Please upload a document for List C.";
          }
        }
    
        setErrors(newErrors);
    
        // Return true if there are no errors
        return Object.keys(newErrors).length === 0;
      };

    // Handle dropdown changes for List B and List C
    // const handleSaveSection1 = () => {
    //     if (validateSection1()) {
    //       console.log("Section 1 saved:", formData);
    //       alert("Section 1 saved successfully.");
    //     }else {
    //         console.log("Validation errors found: ", errors);
    //         alert("Please correct the errors before saving.");
    //     }
    //   };
    
      const handleNext = () => {
        if (validateSection1()) {
          setActiveSection("section2");
        } else {
          alert("Please fill in all fields before proceeding to the next section.");
        }
      };
      const handleSaveSection1 = () => {
        console.log("Section 1 saved:", formData);
        alert("Section 1 saved successfully.");
    };

    const handleSaveSection2 = () => {
        console.log("Section 2 saved:", { listAFile, listBDocument, listCDocument, listBFile, listCFile });
        alert("Section 2 saved successfully.");
    };
    
      // const handleSaveSection2 = () => {
      //   if (validateSection2()) {
      //       console.log("Section 2 saved:", { listAFile, listBDocument, listCDocument, listBFile, listCFile });
      //       alert("Section 2 saved successfully.");
      //     } else {
      //       alert("Please correct the errors before saving.");
      //     }
      // };
      const handleListAChange = (e) => {
        setListADocument(e.target.value);
        setErrors({ ...errors, listADocument: "" });
      };
    
      const handleListBChange = (e) => {
        setListBDocument(e.target.value);
        setErrors({ ...errors, listBDocument: "" });
      };
    
      const handleListCChange = (e) => {
        setListCDocument(e.target.value);
        setErrors({ ...errors, listCDocument: "" });
      };
      const handleListAFileUpload = (e) => {
        setListAFile(e.target.files[0]);
        setErrors({ ...errors, listAFile: "" });
      };
      const handleListAFile1Upload = (e) => {
        setListAFile1(e.target.files[0]);
        setErrors({ ...errors, listAFile1: "" });
      };
      const handleListAFile2Upload = (e) => {
        setListAFile2(e.target.files[0]);
        setErrors({ ...errors, listAFile2: "" });
      };
    
      const handleListBFileUpload = (e) => {
        setListBFile(e.target.files[0]);
        setErrors({ ...errors, listBFile: "" });
      };
    
      const handleListCFileUpload = (e) => {
        setListCFile(e.target.files[0]);
        setErrors({ ...errors, listCFile: "" });
      };
      const handleClear = () => {
        setFormData({
          ...formData,
          citizenshipStatus: "",
          signature:"", 
          // formsAvailable:""// Explicitly set to empty to reset dropdown
      });
        setErrors({});
        setListADocument("");
        setListBDocument("");
        setListCDocument("");
        setListAFile(null);
        setListBFile(null);
        setListCFile(null);
        
        alert("Form cleared.");
    };

      return (
       // <div style={{ overflowY: 'scroll', maxHeight: '100vh', paddingRight: '25px',scrollbarWidth: 'thin' }}>
        <div className="onboarding-i9-container">
          <h2>I-9 Form Employment Eligibility Verification</h2>
          <div>
            <h4>
              All Employees can choose which acceptable documentation to present for
              form I-9. Employers cannot ask employees for documentation to verify
              information in section 1, or specify which acceptable documentation
              employees must present in Section 2 or Supplement B, Reverification
              and Rehire.
            </h4>
          </div>
          <div className="alert alert-info">
            <p>
              I am aware that federal law provides for imprisonment and/or fines for
              false statements or use of false documents in connection with the
              completion of this form.
            </p>
          </div>
          <div className="section">
            <button
              className={activeSection === "section1" ? "active" : ""}
              onClick={() => setActiveSection("section1")}
            >
              Section 1
            </button>
            <button
              className={activeSection === "section2" ? "active" : ""}
              onClick={handleNext}
            >
              Section 2
            </button>
          </div>
          
    
          {/* Section 1 */}
          {activeSection === "section1" && (
            <form>
              <h4>
                Employee Information and Attestation: Employees must complete and
                sign section 1 of Form I-9 no later than first day of employment,
                but not before accepting a job offer.
              </h4>
              <div className="col-md-12">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  placeholder="First Name"
                  onChange={handleInputChange}
                />
                {errors.firstName && (
                  <span className="text-danger">{errors.firstName}</span>
                )}
              </div>
              <div className="col-md-12">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  placeholder="Last Name"
                  onChange={handleInputChange}
                />
                {errors.lastName && (
                  <span className="text-danger">{errors.lastName}</span>
                )}
              </div>
              <div className="col-md-12">
                <label htmlFor="ssn" className="form-label">
                  SSN
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ssn"
                  name="ssn"
                  value={formData.ssn}
                  placeholder="SSN"
                  onChange={handleInputChange}
                  readOnly
                />
                {errors.ssn && <span className="text-danger">{errors.ssn}</span>}
              </div>
              <div className="col-md-12">
                <label htmlFor="address" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                  value={formData.address}
                  placeholder="Address"
                  onChange={handleInputChange}
                />
                {errors.address && (
                  <span className="text-danger">{errors.address}</span>
                )}
              </div>
              <div className="col-md-12">
                <label htmlFor="city" className="form-label">
                  City or Town
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  name="city"
                  value={formData.city}
                  placeholder="City"
                  onChange={handleInputChange}
                />
                {errors.city && <span className="text-danger">{errors.city}</span>}
              </div>
              <div className="col-md-12">
                <label htmlFor="state" className="form-label">
                  State
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="state"
                  name="state"
                  value={formData.state}
                  placeholder="State"
                  onChange={handleInputChange}
                />
                {errors.state && (
                  <span className="text-danger">{errors.state}</span>
                )}
              </div>
              <div className="col-md-12">
                <label htmlFor="zipcode" className="form-label">
                  Zip Code
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="zipcode"
                  name="zipcode"
                  value={formData.zipcode}
                  placeholder="Zip Code"
                  onChange={handleInputChange}
                />
                {errors.zipcode && (
                  <span className="text-danger">{errors.zipcode}</span>
                )}
              </div>
              <div className="col-md-12">
                <label htmlFor="citizenshipStatus" className="form-label">
                  Citizenship Status
                </label>
                <select
                  className="form-select"
                  id="citizenshipStatus"
                  name="citizenshipStatus"
                  value={formData.citizenshipStatus}
                  onChange={handleInputChange}
                >
                  <option value="">Select an option</option>
                  <option value="citizen">A Citizen of US</option>
                  <option value="nonCitizenNational">
                    A non-citizen national of US
                  </option>
                  <option value="resident">
                    A Lawful Permanent Resident of US
                  </option>
                  <option value="nonCitizen">A Non-Citizen</option>
                </select>
                {errors.citizenshipStatus && (
                  <span className="text-danger">{errors.citizenshipStatus}</span>
                )}
              </div>
              {formData.citizenshipStatus === "resident" && (
                                <div className="col-md-12">
                                <label htmlFor="alienNumber" className="form-label">Alien Registration Number/USCIS Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="alienNumber"
                                    name="alienNumber"
                                    value={formData.alienNumber}
                                    onChange={handleInputChange}
                                    required
                                />
                                {errors.alienNumber && <div className="text-danger">{errors.alienNumber}</div>}
                            </div>
                            )}
                            
                            {formData.citizenshipStatus === "nonCitizen" && (
                                <>
    
                                    <div className="col-md-12">
                                        <label htmlFor="expirationDate" className="form-label">Expiration Date (mm-dd-yyyy)</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="expirationDate"
                                            name="expirationDate"
                                            value={formData.expirationDate}
                                            placeholder="mm-dd-yyyy"
                                            onChange={handleInputChange}
                                            required
                                        
                                        />
                                        {errors.expirationDate && <div className="text-danger">{errors.expirationDate}</div>}
                                    </div>
                                    
    
                                    
                                    {/* Document Type Dropdown */}
                                    <div className="col-md-12">
                                        <label htmlFor="documentType" className="form-label">Select Document Type</label>
                                        <select
                                            className="form-select"
                                            id="documentType"
                                            name="documentType"
                                            value={formData.documentType}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="">Select Document Type</option>
                                            <option value="alienNumber">4.1 Alien Registration Number/USCIS Number</option>
                                            <option value="i94Number">4.2 Form I-94 Admission Number</option>
                                            <option value="passportNumber">4.3 Foreign Passport Number</option>
                                        </select>
                                        {errors.documentType && <div className="text-danger">{errors.documentType}</div>}
                                    </div>
                                    <div className=" alert-secondary">
    
                                        <p>
                                            Aliens authorized to work must provide only one of the following document numbers to fill form I-9:
                                        </p>
                                    
                                    </div>
    
                                    {/* Display the relevant input field based on selected document type */}
                                    {formData.documentType === "alienNumber" && (
                                        <div className="col-md-12">
                                            <label htmlFor="alienNumber" className="form-label">Alien Registration Number/USCIS Number</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="alienNumber"
                                                name="alienNumber"
                                                value={formData.alienNumber}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            {errors.alienNumber && <div className="text-danger">{errors.alienNumber}</div>}
                                        </div>
                                    )}
    
                                    {formData.documentType === "i94Number" && (
                                        <div className="col-md-12">
                                            <label htmlFor="i94Number" className="form-label">4.2 Form I-94 Admission Number</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="i94Number"
                                                name="i94Number"
                                                value={formData.i94Number}
                                                onChange={handleInputChange}
                                                
                                            />
                                            {errors.i94Number && <div className="text-danger">{errors.i94Number}</div>}
                                        </div>
                                    )}
    
                                    {formData.documentType === "passportNumber" && (
                                        <>
                                            <div className="col-md-12">
                                                <label htmlFor="passportNumber" className="form-label">4.3 Foreign Passport Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="passportNumber"
                                                    name="passportNumber"
                                                    value={formData.passportNumber}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                {errors.passportNumber && <div className="text-danger">{errors.passportNumber}</div>}
                                            </div>
    
                                            {formData.passportNumber && (
                                                <div className="col-md-12">
                                                    <label htmlFor="countryOfIssuance" className="form-label">Country of Issuance</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="countryOfIssuance"
                                                        name="countryOfIssuance"
                                                        value={formData.countryOfIssuance}
                                                        onChange={handleInputChange}
                                                        required
                                                        
                                                    />
                                                    {errors.countryOfIssuance && <div className="text-danger">{errors.countryOfIssuance}</div>}
                                                </div>
                                            )}
                      
                                </>
                            )}
                                      </>
                                    )}
              <div className="col-md-12">
                <label htmlFor="signature" className="form-label">
                  Write your full name to Sign
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="signature"
                  name="signature"
                  value={formData.signature}
                  placeholder="Signature"
                  onChange={handleInputChange}
                />
                {errors.signature && (
                  <span className="text-danger">{errors.signature}</span>
                )}
              </div>
              <button type="button" className="btn btn-secondary mt-4" onClick={handleSaveSection1}>
                Save
              </button>
              <button type="button" className="btn btn-secondary mt-4" onClick={handleClear}>
                        Clear
                    </button>
              <button type="button" className="btn btn-primary mt-4" onClick={handleNext}>
                Next
              </button>
            </form>
          )}
    
          {/* Section 2 */}
          {activeSection === "section2" && (
            <form onSubmit={handleSubmit}>
                <div>
                {/* <SingleButtonDropDown /> */}
              <h2>Section 2: Verification of Employment Authorization</h2>
              </div>
              <div className="col-md-12">
                <label htmlFor="formsAvailable" className="form-label">
                  Forms Available
                </label>
                <select 
                  className="form-select"
                  id="formsAvailable"
                  name="formsAvailable"
                  value={formData.formsAvailable}
                  onChange={handleInputChange}
                >
                  <option value="">Select a List</option>
                  <option value="listA">List A</option>
                  <option value="listBC">Combination of List B and List C</option>
                </select>
                {errors.formsAvailable && <div className="text-danger">{errors.formsAvailable}</div>}
              </div>
    
              {/* List A Document Options */}
              {formData.formsAvailable === "listA" && (
                <div className="documents-container">
                <h3>
                    The following documents can be used to verify both employment authorization and identity. 
                </h3>
                <p>
                    Please ensure all documents are unexpired.
                </p>
                
              <p>Please provide one document from List A</p>
    
              <div className="document-list">
                <h3>List A: Documents that Establish Identity</h3>
                <label htmlFor="list-a-dropdown">Select List A Document:</label>
                <select
                className="form-select"
                  id="list-a-dropdown"
                  value={listADocument}
                  onChange={handleListAChange}
                  
                >
                  <option value="" disabled>Select List A Document</option>
                  <option value="us-passport">US Passport or US Passport Card</option>
                  <option value="permanent-resident-card">Permanent resident card or Alien Registration Receipt Card</option>
                  <option value="foreign-passport">Foreign Passport that contains temporary I-551 stamp</option>
                  <option value="employement-authorization-document">Employement Authorization Document</option>
                  <option value="passport-fsm">Passport from federated states micronecia or the Republic of the Marshall Islands with Form I-94 or Form I-94A</option>
                 <option value="temporarily-authorized">Foreign Passport and Form I-94/Form I-94A</option> 
                </select>
                {errors.listADocument && <div className="text-danger">{errors.listADocument}</div>}
    
                {listADocument === 'temporarily-authorized' ? (
    
    <div className="upload-container">
      <div className="upload-section">
    <label htmlFor="listAFile1" className="btn btn-primary upload-label">
      Upload Document 1
    </label>
    <input
      type="file"
      id="listAFile1"
      accept=".pdf,.jpg,.jpeg,.png"
      onChange={handleListAFile1Upload}
      style={{ display: 'none' }}
    />
    {listAFile1 && (
      <span className="file-name">{listAFile1.name}</span>
    )}
    </div>
    {errors.listAFile1 && (
      <div className="text-danger">{errors.listAFile1}</div>
    )}
    

<div className="upload-section">
 <label htmlFor="listAFile2" className="btn btn-primary upload-label">
     Upload Document 2
   </label>
   <input
     type="file"
     id="listAFile2"
     accept=".pdf,.jpg,.jpeg,.png"
     onChange={handleListAFile2Upload}
     style={{ display: 'none' }}
   />
   {listAFile2 && (
     <span className="file-name">{listAFile2.name}</span>
   )}
   </div>
   {errors.listAFile2 && (
     <div className="text-danger">{errors.listAFile2}</div>
   )}
   </div>
 
                ) : (
    
    <div className="upload-container">
      <div className="upload-section">
      <label htmlFor="listAFile" className="btn btn-primary upload-label">
        Upload List A Document
      </label>
      <input
        type="file"
        id="listAFile"
        accept=".pdf,.jpg,.jpeg,.png"
        onChange={handleListAFileUpload}
        style={{ display: 'none' }}
      />
      {listAFile && (
        <span className="file-name">{listAFile.name}</span>
      )}
      </div>
      {errors.listAFile && (
        <div className="text-danger">{errors.listAFile}</div>
      )}
    </div>
    
                )}
                
    
                </div>
                </div>
                
            
              )}
    
              {/* List B and List C Document Options */}
              {formData.formsAvailable === "listBC" && (
            <div className="documents-container">
              <p>Please provide one document from List B and one from List C:</p>
    
              <div className="document-list">
                <h3>List B: Documents that Establish Identity</h3>
                <label htmlFor="list-b-dropdown">Select List B Document:</label>
                <select
                className="form-select"
                  id="list-b-dropdown"
                  value={listBDocument}
                  onChange={handleListBChange}
                  
                >
                  <option value="" disabled>Select List B Document</option>
                  <option value="driver-license">Driver’s license</option>
                  <option value="id-card">ID card issued by U.S. federal/state agencies with a photograph</option>
                  <option value="school-id">School ID with a photograph</option>
                  <option value="voters-registration-card">Voter's registration card</option>
                  <option value="military-id-card">U.S. Military card or draft record</option>
                  <option value="military-dependant-id-card">Military dependent's ID card</option>
                  <option value="coast-guard-id-card">U.S. Coast Guard Merchant Mariner Card</option>
                  <option value="native-american-tribal-document">Native American Tribal Document</option>
                  <option value="drivers-license-canada">Drivers License Issued by Canadian Government</option>
                </select>
                {errors.listBDocument && <div className="text-danger">{errors.listBDocument}</div>}
    
                
                <div className="upload-container">
                <div className="upload-section">
      <label htmlFor="listBFile" className="btn btn-primary upload-label">
        Upload List B Document
      </label>
      <input
        type="file"
        id="listBFile"
        accept=".pdf,.jpg,.jpeg,.png"
        onChange={handleListBFileUpload}
        style={{ display: 'none' }}
      />
      {listBFile && (
        <span className="file-name">{listBFile.name}</span>
      )}
      </div>
      {errors.listCFile && (
        <div className="text-danger">{errors.listBFile}</div>
      )}
    </div>
    
                
    </div>
    
                <h3>List C: Documents that Establish Employment Authorization</h3>
                <label htmlFor="list-c-dropdown">Select List C Document:</label>
                <select
                className="form-select"
                  id="list-c-dropdown"
                  value={listCDocument}
                  onChange={handleListCChange}
                  
                >
                  <option value="" disabled>Select List C Document</option>
                  <option value="social-security-card">U.S. Social Security card</option>
                  <option value="birth-certification">Certification of Birth Abroad (Form FS-545)</option>
                  <option value="birth-certificate">Original or certified birth certificate with an official seal</option>
                  <option value="us-citizen-id-card">U.S. Citizen ID Card (Form I-197)</option>
                  <option value="native-american-tribal-document">Native American Tribal Document</option>
                  <option value="resident-citizen-id-card">Identification Card for Use of Resident Citizen in the United States (Form I-179)</option>
                  <option value="ead">Employment authorization document issued by the Department of Homeland Security</option>
                </select>
                {errors.listCDocument && <div className="text-danger">{errors.listCDocument}</div>}
                <div>
                <div className="upload-container">
                <div className="upload-section">
      <label htmlFor="listCFile" className="btn btn-primary upload-label">
        Upload List C Document
      </label>
      <input
        type="file"
        id="listCFile"
        accept=".pdf,.jpg,.jpeg,.png"
        onChange={handleListCFileUpload}
        style={{ display: 'none' }}
      />
      {listCFile && (
        <span className="file-name">{listCFile.name}</span>
      )}
      </div>
      {errors.listCFile && (
        <div className="text-danger">{errors.listCFile}</div>
      )}
    </div>
</div>

              </div>
           
          )}
    
              <button
                type="button"
                className="btn btn-secondary mt-4"
                onClick={handleSaveSection2}
              >
                Save
              </button>
              <button type="button" className="btn btn-secondary mt-4" onClick={handleClear}>
                        Clear
                    </button>
              <button type="submit" className="btn btn-success mt-4">
                Submit
              </button>
            </form>
          )}
        </div>
        
      );
  
}


export default OnboardingI9;




