import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import './DocumentsPage.css';
import SearchBar from './SearchBar';
import ResultNavigation from './ResultNavigation';
import UploadComponent from './UploadComponent';

function DocumentsPage() {
    const [isPreFilled, setIsPreFilled] = useState(true);
    const [searchQuery, setSearchQuery] = useState(''); 
    const [documents, setDocuments] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1); 
    const documentsPerPage = 3; 
    const [isUploadOpen, setIsUploadOpen] = useState(false); 
    const [documentTypes, setDocumentTypes] = useState([]); 
    const [filteredDocuments, setFilteredDocuments] = useState([]); 

    const handleFormTypeChange = (event) => {
        setIsPreFilled(event.target.value === 'pre-filled');
    };

    const handleUploadClick = () => {
        setIsUploadOpen(true);
    };

    const closeUploadPopup = () => {
        setIsUploadOpen(false);
    };

    const uploadFields = [
        { name: 'name', label: 'Document Name', type: 'text', required: true },
        {
            name: 'type',
            label: 'Document Type',
            type: 'select',
            options: documentTypes.map(type => ({ value: type.id, label: type.name })),
            required: true 
        },
        { name: 'comments', label: 'Comments', type: 'textarea', required: false },
        { name: 'file', label: 'Upload File', type: 'file', required: true },
    ];

    const indexOfLastDocument = currentPage * documentsPerPage;
    const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
    const currentDocuments = filteredDocuments.slice(indexOfFirstDocument, indexOfLastDocument);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredDocuments.length / documentsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleSearchQueryChange = (query) => {
        setSearchQuery(query);
        setCurrentPage(1); 
    };

    const clearSearch = () => {
        setSearchQuery('');
        setCurrentPage(1);
    };

    useEffect(() => {
        const fetchDocuments = async () => {
            const session_id = Cookies.get("session_id");
            const jwt_token = Cookies.get("jwt_token");
        
            if (session_id && jwt_token) {
                try {
                    const response = await axios.get(`http://0.0.0.0:3500/employee/documents/${session_id}`, {
                        headers: {
                            Authorization: `Bearer ${jwt_token}`,
                        },
                        withCredentials: true,
                    });
                
                    if (response.status === 200) {
                        // Ensure `uploaded_at` is parsed as a Date object
                        const documents = response.data.documents.map(doc => ({
                            ...doc,
                            uploaded_at: new Date(doc.uploaded_at) // Convert to JavaScript Date object
                        }));
                        console.log("Documents fetched:", documents);
                        setDocuments(documents);
                        setFilteredDocuments(documents);
                    } else {
                        console.error("Unexpected response:", response);
                    }
                } catch (error) {
                    console.error("Error fetching documents:", error);
                    if (error.response && error.response.status === 404) {
                        alert("Documents not found.");
                    } else if (error.response && error.response.status === 401) {
                        alert("Session expired. Please log in again.");
                        window.location.href = "/login";
                    } else {
                        alert("An error occurred. Please try again.");
                    }
                }
            }
        };
        fetchDocuments();
    }, []);

    return (
        <div className={`document-status-container ${isUploadOpen ? 'blur' : ''}`}>
            <div className="top-bar d-flex justify-content-between align-items-center mb-4">
                <div className="left-options d-flex align-items-center">
                    <select 
                        className="form-select me-3" 
                        onChange={handleFormTypeChange} 
                        value={isPreFilled ? 'pre-filled' : 'unfilled'}
                    >
                        <option value="unfilled">Unfilled Form</option>
                        <option value="pre-filled">Pre-filled Form</option>
                    </select>

                    <select className="form-select me-3">
                        <option value="">
                            {isPreFilled ? "Select Pre-filled Form" : "Select Unfilled Form"}
                        </option>
                        {isPreFilled ? (
                            <>
                                <option value="pre1">Pre-filled Form 1</option>
                                <option value="pre2">Pre-filled Form 2</option>
                            </>
                        ) : (
                            <>
                                <option value="form1">Unfilled Form 1</option>
                                <option value="form2">Unfilled Form 2</option>
                            </>
                        )}
                    </select>

                    <button className="btn btn-primary me-2">Download</button>
                </div>

                <div className="right-options">
                    <button className="btn btn-success" onClick={handleUploadClick}>Upload Document</button>
                </div>
            </div>

            <UploadComponent 
                isOpen={isUploadOpen} 
                onClose={closeUploadPopup} 
                fields={uploadFields} 
            />

            <SearchBar documents={documents} setFilteredDocuments={setFilteredDocuments} />

            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Document Name</th>
                        <th scope="col">Type</th>
                        <th scope="col">Uploaded On</th>
                        <th scope="col">Current Status</th>
                        <th scope="col">Comments</th>
                    </tr>
                </thead>
                <tbody>
                    {currentDocuments.length > 0 ? (
                        currentDocuments.map((doc, index) => (
                            <tr key={index}>
                                <td>{doc.name}</td>
                                <td>{doc.type}</td>
                                <td>
                                    {doc.uploaded_at instanceof Date && !isNaN(doc.uploaded_at)
                                        ? doc.uploaded_at.toLocaleDateString()
                                        : 'N/A'}
                                </td>
                                <td>
                                    <span className={`badge ${doc.status === 'Approved' ? 'bg-success' : 'bg-warning'}`}>
                                        {doc.status}
                                    </span>
                                </td>
                                <td>{doc.comments}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" className="text-center">No documents found</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <ResultNavigation
                currentPage={currentPage}
                totalItems={filteredDocuments.length}
                itemsPerPage={documentsPerPage}
                onNextPage={handleNextPage}
                onPreviousPage={handlePreviousPage}
                isNextDisabled={currentPage >= Math.ceil(filteredDocuments.length / documentsPerPage)}
                isPreviousDisabled={currentPage <= 1}
            />
        </div>
    );
}

export default DocumentsPage;