// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timesheets-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.header-bar {
    display: flex;
    justify-content: space-between;
}

.download-section {
    padding: 30px;
    display: flex;
    align-items: center;
}

.table th, .table td {
    padding: 50px;
    text-align: center;
    vertical-align: middle;
}

.badge.bg-success {
    background-color: #28a745;
    color: white;
}

.total-hours {
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/screens/OtherPages/TimesheetsPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".timesheets-container {\n    padding: 20px;\n    font-family: Arial, sans-serif;\n}\n\n.header-bar {\n    display: flex;\n    justify-content: space-between;\n}\n\n.download-section {\n    padding: 30px;\n    display: flex;\n    align-items: center;\n}\n\n.table th, .table td {\n    padding: 50px;\n    text-align: center;\n    vertical-align: middle;\n}\n\n.badge.bg-success {\n    background-color: #28a745;\n    color: white;\n}\n\n.total-hours {\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
