// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-page {
    display: flex;
    /* height: 100vh; Full viewport height */
    overflow: hidden !important; /* Prevent the entire page from scrolling */

    .form-container {
        flex: 1 1; /* Take remaining space */
        overflow-y: auto; /* Only the form content is scrollable */
        padding: 20px;
        /* background-color: white; */

        .login-form-wrapper {
            max-width: auto; /* Center and constrain the form width */
            margin: 0 auto;
           
        }

        h2 {
            text-align: center;
            margin-bottom: 20px;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/screens/OtherPages/LoginPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,wCAAwC;IACxC,2BAA2B,EAAE,2CAA2C;;IAExE;QACI,SAAO,EAAE,yBAAyB;QAClC,gBAAgB,EAAE,wCAAwC;QAC1D,aAAa;QACb,6BAA6B;;QAE7B;YACI,eAAe,EAAE,wCAAwC;YACzD,cAAc;;QAElB;;QAEA;YACI,kBAAkB;YAClB,mBAAmB;QACvB;IACJ;AACJ","sourcesContent":[".login-page {\n    display: flex;\n    /* height: 100vh; Full viewport height */\n    overflow: hidden !important; /* Prevent the entire page from scrolling */\n\n    .form-container {\n        flex: 1; /* Take remaining space */\n        overflow-y: auto; /* Only the form content is scrollable */\n        padding: 20px;\n        /* background-color: white; */\n\n        .login-form-wrapper {\n            max-width: auto; /* Center and constrain the form width */\n            margin: 0 auto;\n           \n        }\n\n        h2 {\n            text-align: center;\n            margin-bottom: 20px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
