// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1; 
}
.custom-file-label {
    display: block; /* Display as a block to take up full width */
    padding: 8px 12px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    color: #555;
    margin-top: 8px; /* Add space above for better alignment */
    text-align: center;
}


.custom-file-label:hover {
    background-color: #e0e0e0;
}



.upload-popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 400px;
}

.close {
    cursor: pointer;
    float: right;
    font-size: 24px;
}

/* .blur {
    filter: blur(5px); 
} */
`, "",{"version":3,"sources":["webpack://./src/screens/OtherPages/UploadComponent.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;AACd;AACA;IACI,cAAc,EAAE,6CAA6C;IAC7D,iBAAiB;IACjB,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,eAAe,EAAE,yCAAyC;IAC1D,kBAAkB;AACtB;;;AAGA;IACI,yBAAyB;AAC7B;;;;AAIA;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,eAAe;AACnB;;AAEA;;GAEG","sourcesContent":[".upload-popup {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.7);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1; \n}\n.custom-file-label {\n    display: block; /* Display as a block to take up full width */\n    padding: 8px 12px;\n    cursor: pointer;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    background-color: #f8f8f8;\n    color: #555;\n    margin-top: 8px; /* Add space above for better alignment */\n    text-align: center;\n}\n\n\n.custom-file-label:hover {\n    background-color: #e0e0e0;\n}\n\n\n\n.upload-popup-content {\n    background: white;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    width: 400px;\n}\n\n.close {\n    cursor: pointer;\n    float: right;\n    font-size: 24px;\n}\n\n/* .blur {\n    filter: blur(5px); \n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
