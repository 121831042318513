import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DocumentsPage.css';
import Cookies from "js-cookie";
// import SessionTimer from '../SessionTimer';
import APIClient from './APIClient';
import KnowledgeBaseSidebar from "../../components/common/KnowledgeBaseSidebar";

function DashboardPage() {
    const [visaType, setVisaType] = useState('');
    const [onboardingStatus, setOnboardingStatus] = useState('');
    const [timesheetsStatus, setTimesheetsStatus] = useState([]);
    const [documentsStatus, setDocumentsStatus] = useState([]);
    const [leavesStatus, setLeavesStatus] = useState([]);

    useEffect(() => {
        const fetchDashboardDetails = async () => {
            const jwt_token = Cookies.get("jwt_token");
            // const session_id = Cookies.get("session_id");

            try {
                // Fetch onboarding details
                const onboardingResponse = await APIClient.get(`/employee/onboarding/status`, {
                    headers: {
                        Authorization: `Bearer ${jwt_token}`,
                    },
                    withCredentials: true,
                });

                if (onboardingResponse.data.success) {
                    const statusValue = onboardingResponse.data.data.status;
                    const statusLabel = statusValue === 1 ? 'Active' : 'Inactive';
                    setOnboardingStatus(statusLabel);
                } else {
                    setOnboardingStatus('No Onboarding Status Found');
                }

                // Fetch statuses for documents, timesheets, and leaves
                const [documentsResponse, timesheetsResponse, leavesResponse] = await Promise.all([
                    APIClient.get(`/employee/documents/status`, {
                        headers: { Authorization: `Bearer ${jwt_token}` },
                        withCredentials: true,
                    }),
                    APIClient.get(`/employee/timesheets/status`, {
                        headers: { Authorization: `Bearer ${jwt_token}` },
                        withCredentials: true,
                    }),
                    APIClient.get(`/employee/leaves/status`, {
                        headers: { Authorization: `Bearer ${jwt_token}` },
                        withCredentials: true,
                    }),
                ]);

                setDocumentsStatus(documentsResponse.data.documents || []);
                setTimesheetsStatus(timesheetsResponse.data.timesheets || []);
                setLeavesStatus(leavesResponse.data.leaves || []);
            } catch (error) {
                console.error('Error fetching dashboard details:', error);
            }
        };

        fetchDashboardDetails();
    }, []);

    const getStatusBadge = (status) => {
        switch (status) {
            case 'Approved':
                return <span className="badge bg-success">Approved</span>;
            case 'Pending':
                return <span className="badge bg-warning">Pending</span>;
            case 'Declined':
                return <span className="badge bg-danger">Declined</span>;
            case 'Active':
                return <span className="badge bg-success">Active</span>;
            case 'Inactive':
                return <span className="badge bg-danger">Inactive</span>;
            default:
                return null;
        }
    };

    return (
        <div className="dashboard-container d-flex">
            <div className="main-content flex-grow-1 me-4">
            <div className="top-bar d-flex justify-content-between align-items-center mb-4">
                <h3>Dashboard Overview</h3>
                <button className="btn btn-primary">New Application</button>
            </div>
            
            {/* <SessionTimer /> */}
        
            <div className="onboarding-details mb-4">
                <h4>Onboarding Application Details</h4>
                <p>Visa Type: <strong>{visaType}</strong></p>
                <p>Onboarding Status: {getStatusBadge(onboardingStatus)}</p>
            </div>

            <div className="status-sections">
                {/* Documents Status */}
                <div className="status-box mb-4">
                    <h5>Documents Status</h5>
                    <ul className="list-group">
                        {documentsStatus.length > 0 ? (
                            documentsStatus.map((document, index) => (
                                <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                    <span>{document.doc_name}</span>
                                    {getStatusBadge(document.status)}
                                </li>
                            ))
                        ) : (
                            <li className="list-group-item text-center">No Documents found</li>
                        )}
                    </ul>
                </div>

                {/* Timesheets Status */}
                <div className="status-box mb-4">
                    <h5>Timesheets Status</h5>
                    <ul className="list-group">
                        {timesheetsStatus.length > 0 ? (
                            timesheetsStatus.map((timesheet, index) => (
                                <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                    <span>{timesheet.client}</span>
                                    {getStatusBadge(timesheet.status)}
                                </li>
                            ))
                        ) : (
                            <li className="list-group-item text-center">No Timesheets found</li>
                        )}
                    </ul>
                </div>

                {/* Leaves Status */}
                <div className="status-box mb-4">
                    <h5>Leaves Status</h5>
                    <ul className="list-group">
                        {leavesStatus.length > 0 ? (
                            leavesStatus.map((leave, index) => (
                                <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                    <span>{leave.leave_type}</span>
                                    {getStatusBadge(leave.status)}
                                </li>
                            ))
                        ) : (
                            <li className="list-group-item text-center">No Leaves found</li>
                        )}
                    </ul>
                </div>
            </div>
            </div>
            <div className="kbase-container">
                <KnowledgeBaseSidebar />
            </div>
        </div>
    );
}

export default DashboardPage;