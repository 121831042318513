import React from "react";
import { Route, Routes as ReactRoutes } from "react-router-dom";
import Sidebar from "../components/common/Sidebar";
import Header from "../components/common/Header";
import HrDashboard from "./Dashboard/HrDashboard";
import CreateLogin from "./OtherPages/CreateLogin";
import EmployeeRegisterationForm from "./OtherPages/EmployeeRegistrationForm";
import LoginPage from "./OtherPages/LoginPage";
import ForgotUserID from "./OtherPages/ForgotUserID";
import ForgotPassword from "./OtherPages/ForgotPassword";
import ResetPassword from "./OtherPages/ResetPassword";
import OnboardingI9 from "./OtherPages/OnboardingI9";
import OnboardingW4 from "./OtherPages/OnboardingW4";
import DocumentsPage from "./OtherPages/DocumentsPage";
import Dashboard from "./OtherPages/Dashboard";
import TimeSheetsPage from "./OtherPages/TimeSheetsPage";
import Leaves from "./OtherPages/Leaves";
import Messages from "./OtherPages/Messages";

function EmptyPageIndex(props) {
    const { activekey } = props;
    
    return (
        <div className="main px-lg-4 px-md-4">
            <div className="body d-flex py-lg-3 py-md-2">
                <ReactRoutes>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} element={<HrDashboard />} />
                    <Route exact path={`${process.env.PUBLIC_URL}/create-login/:hash`} element={<CreateLogin />} />
                    <Route exact path={`${process.env.PUBLIC_URL}/employee-registration`} element={<EmployeeRegisterationForm />} />
                    <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<LoginPage />} />
                     <Route exact path={`${process.env.PUBLIC_URL}/forgot-user-id`} element={<ForgotUserID/>} /> 
                     <Route exact path={`${process.env.PUBLIC_URL}/forgot-password`} element={<ForgotPassword/>} /> 
                     <Route exact path={`${process.env.PUBLIC_URL}/reset-password`} element={<ResetPassword/>} /> 
                     <Route exact path={`${process.env.PUBLIC_URL}/onboarding-i9`} element={<OnboardingI9/>} /> 
                     <Route exact path={`${process.env.PUBLIC_URL}/onboarding-w4`} element={<OnboardingW4/>} />
                     <Route exact path={`${process.env.PUBLIC_URL}/my-dashboard`} element={<Dashboard/>} /> 
                     <Route exact path={`${process.env.PUBLIC_URL}/documents`} element={<DocumentsPage/>} /> 
                     <Route exact path={`${process.env.PUBLIC_URL}/timesheets`} element={<TimeSheetsPage/>} /> 
                     <Route exact path={`${process.env.PUBLIC_URL}/leaves`} element={<Leaves/>} />
                     <Route exact path={`${process.env.PUBLIC_URL}/messages`} element={<Messages/>} />
                     {/* <Route exact path={${process.env.PUBLIC_URL}/personal-details} element={<PersonalDetails/>} /> */}

            
                </ReactRoutes>
            </div>
        </div>
    );
}

export default EmptyPageIndex;