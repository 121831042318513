import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './TimesheetsPage.css';
import SearchBar from './SearchBar';
import ResultNavigation from './ResultNavigation';
import UploadComponent from './UploadComponent';

function TimesheetsPage() {
    const [searchQuery, setSearchQuery] = useState('');
    const [timesheets, setTimesheets] = useState([]); 
    const [filteredTimesheets, setFilteredTimesheets] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [isUploadOpen, setIsUploadOpen] = useState(false); 
    const [formData, setFormData] = useState({ date: '', client: '', hours: '', document: '' });
    const [error, setError] = useState('');

    const timesheetsPerPage = 3;

    // Fetch the latest timesheets for the authenticated user
    useEffect(() => {
        const fetchTimesheets = async () => {
            const session_id = Cookies.get("session_id");
            const jwt_token = Cookies.get("jwt_token");

            if (session_id && jwt_token) {
                try {
                    const response = await axios.get(`http://0.0.0.0:3500/employee/timesheets/${session_id}`, {
                        headers: {
                            Authorization: `Bearer ${jwt_token}`,
                        },
                        withCredentials: true,
                    });

                    if (response.data.success) {
                        setTimesheets(response.data.timesheets || []); 
                        setFilteredTimesheets(response.data.timesheets || []); 
                    } else {
                        console.error("Unexpected response:", response);
                    }
                } catch (error) {
                    console.error("Error fetching timesheets:", error);
                    if (error.response && error.response.status === 404) {
                        alert("Timesheets not found.");
                    } else if (error.response && error.response.status === 401) {
                        alert("Session expired. Please log in again.");
                        window.location.href = "/login";
                    } else {
                        alert("An error occurred. Please try again.");
                    }
                }
            }
        };

        fetchTimesheets(); 
    }, []);

    const indexOfLastTimesheet = currentPage * timesheetsPerPage;
    const indexOfFirstTimesheet = indexOfLastTimesheet - timesheetsPerPage;
    const currentTimesheets = filteredTimesheets.slice(indexOfFirstTimesheet, indexOfLastTimesheet);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredTimesheets.length / timesheetsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const openUploadPopup = () => {
        setIsUploadOpen(true);
    };

    const closeUploadPopup = () => {
        setIsUploadOpen(false);
    };

    // Calculate total hours from filtered timesheets
    const totalHours = filteredTimesheets.reduce((total, sheet) => total + (parseFloat(sheet.hours) || 0), 0);

    return (
        <div className="timesheets-container">
            {/* Header section */}
            <div className="header-bar d-flex justify-content-between align-items-center mb-4">
                <div className="right-options d-flex align-items-center">
                    <div className="download-section me-3">
                        <label> Approved Timesheets</label>
                        <select className="form-select me-2">
                            <option value="">Select Pay Period</option>
                            {/* Populate options dynamically */}
                        </select>
                        <button className="btn btn-primary">Download</button>
                    </div>
                </div>
                <div className="left-options d-flex align-items-center">
                    <button className="btn btn-primary" onClick={openUploadPopup}>+ Timesheet</button>
                </div>
            </div>

            {/* Search and Filters */}
            <div className="search-and-filters d-flex align-items-center mb-7">
                <SearchBar 
                    documents={timesheets} 
                    setFilteredDocuments={setFilteredTimesheets} 
                />
                <button className="btn btn-primary ms-2">Download</button>
                <span className="total-hours ms-3">Total Hours: {totalHours}</span>
            </div>

            {/* Timesheets Table */}
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Pay Period</th>
                        <th scope="col">Hours</th>
                        <th scope="col">Uploaded At</th>
                        <th scope="col">Current Status</th>
                        <th scope="col">Client</th>
                        <th scope="col">Comments</th>
                    </tr>
                </thead>
                <tbody>
                    {currentTimesheets.length > 0 ? (
                        currentTimesheets.map((sheet, index) => (
                            <tr key={index}>
                                <td><a href="#">{sheet.pay_period}</a></td>
                                <td>{sheet.hours}</td>
                                <td>{new Date(sheet.uploadedAt).toLocaleDateString()}</td>
                                <td><span className={`badge ${sheet.status === 'Approved' ? 'bg-success' : 'bg-warning'}`}>{sheet.status}</span></td>
                                <td>{sheet.client}</td>
                                <td>{sheet.comments}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className="text-center">No timesheets found</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Pagination */}
            <ResultNavigation
                currentPage={currentPage}
                totalItems={filteredTimesheets.length}
                itemsPerPage={timesheetsPerPage}
                onNextPage={handleNextPage}
                onPreviousPage={handlePreviousPage}
                isNextDisabled={currentPage >= Math.ceil(filteredTimesheets.length / timesheetsPerPage)}
                isPreviousDisabled={currentPage <= 1}
            />

            {/* UploadComponent for +Timesheet */}
            <UploadComponent 
                isOpen={isUploadOpen} 
                onClose={closeUploadPopup}
                fields={[
                    {
                        label: 'Client',
                        name: 'client',
                        type: 'select',
                        options: [
                            { value: 'Client 1', label: 'Client 1' },
                            { value: 'Client 2', label: 'Client 2' }
                        ],
                        required: true,
                        onChange: handleChange
                    },
                    {
                        label: 'Date (mm-dd-yyyy)',
                        name: 'date',
                        type: 'text',
                        value: formData.date,
                        placeholder: 'mm-dd-yyyy',
                        onChange: handleChange,
                        required: true
                    },
                    {
                        label: 'Hours Worked',
                        name: 'hours',
                        type: 'text',
                        required: true,
                        onChange: handleChange
                    },
                    {
                        label: 'Upload Document',
                        name: 'document',
                        type: 'file',
                        onChange: handleChange
                    }
                ]}
            />

            {error && <div className="alert alert-danger mt-3">{error}</div>}
        </div>
    );
}

export default TimesheetsPage;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Cookies from 'js-cookie';
// import './TimesheetsPage.css';
// import SearchBar from './SearchBar';
// import ResultNavigation from './ResultNavigation';
// import UploadComponent from './UploadComponent';

// function TimesheetsPage() {
//     const [searchQuery, setSearchQuery] = useState('');
//     const [timesheets, setTimesheets] = useState([]); 
//     const [filteredTimesheets, setFilteredTimesheets] = useState([]); 
//     const [currentPage, setCurrentPage] = useState(1);
//     const [isUploadOpen, setIsUploadOpen] = useState(false); 
//     const [formData, setFormData] = useState({ date: '', client: '', hours: '', document: '' });
//     const [error, setError] = useState('');

//     const timesheetsPerPage = 3;

//     // Fetch the latest timesheets for the authenticated user
//     useEffect(() => {
//         const fetchTimesheets = async () => {
//             const session_id = Cookies.get("session_id");
//             const jwt_token = Cookies.get("jwt_token");

//             if (session_id && jwt_token) {
//                 try {
//                     const response = await axios.get(`http://0.0.0.0:3500/employee/timesheets/${session_id}`, {
//                         headers: {
//                             Authorization: `Bearer ${jwt_token}`,
//                         },
//                         withCredentials: true,
//                     });

//                     if (response.data.success) {
//                         setTimesheets(response.data.timesheets || []); 
//                         setFilteredTimesheets(response.data.timesheets || []); 
//                     } else {
//                         console.error("Unexpected response:", response);
//                     }
//                 } catch (error) {
//                     console.error("Error fetching timesheets:", error);
//                     if (error.response && error.response.status === 404) {
//                         alert("Timesheets not found.");
//                     } else if (error.response && error.response.status === 401) {
//                         alert("Session expired. Please log in again.");
//                         window.location.href = "/login";
//                     } else {
//                         alert("An error occurred. Please try again.");
//                     }
//                 }
//             }
//         };

//         fetchTimesheets(); 
//     }, []);

//     const indexOfLastTimesheet = currentPage * timesheetsPerPage;
//     const indexOfFirstTimesheet = indexOfLastTimesheet - timesheetsPerPage;
//     const currentTimesheets = filteredTimesheets.slice(indexOfFirstTimesheet, indexOfLastTimesheet);

//     const handleNextPage = () => {
//         if (currentPage < Math.ceil(filteredTimesheets.length / timesheetsPerPage)) {
//             setCurrentPage(currentPage + 1);
//         }
//     };

//     const handlePreviousPage = () => {
//         if (currentPage > 1) {
//             setCurrentPage(currentPage - 1);
//         }
//     };

//     const handleChange = (event) => {
//         const { name, value } = event.target;
//         setFormData((prevData) => ({ ...prevData, [name]: value }));
//     };

//     const openUploadPopup = () => {
//         setIsUploadOpen(true);
//     };

//     const closeUploadPopup = () => {
//         setIsUploadOpen(false);
//     };

//     return (
//         <div className="timesheets-container">
//             {/* Header section */}
//             <div className="header-bar d-flex justify-content-between align-items-center mb-4">
//                 <div className="right-options d-flex align-items-center">
//                     <div className="download-section me-3">
//                         <label> Approved Timesheets</label>
//                         <select className="form-select me-2">
//                             <option value="">Select Pay Period</option>
//                             {/* Populate options dynamically */}
//                         </select>
//                         <button className="btn btn-primary">Download</button>
//                     </div>
//                 </div>
//                 <div className="left-options d-flex align-items-center">
//                     <button className="btn btn-primary" onClick={openUploadPopup}>+ Timesheet</button>
//                 </div>
//             </div>

//             {/* Search and Filters */}
//             <div className="search-and-filters d-flex align-items-center mb-7">
//                 <SearchBar 
//                     documents={timesheets} 
//                     setFilteredDocuments={setFilteredTimesheets} 
//                 />
//                 <button className="btn btn-primary ms-2">Download</button>
//                 <span className="total-hours ms-3">Total Hours: {filteredTimesheets.reduce((total, sheet) => total + sheet.hours, 0)}</span>
//             </div>

//             {/* Timesheets Table */}
//             <table className="table table-striped">
//                 <thead>
//                     <tr>
//                         <th scope="col">Pay Period</th>
//                         <th scope="col">Hours</th>
//                         <th scope="col">Uploaded At</th>
//                         <th scope="col">Current Status</th>
//                         <th scope="col">Client</th>
//                         <th scope="col">Comments</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {currentTimesheets.length > 0 ? (
//                         currentTimesheets.map((sheet, index) => (
//                             <tr key={index}>
//                                 <td><a href="#">{sheet.pay_period}</a></td>
//                                 <td>{sheet.hours}</td>
//                                 <td>{new Date(sheet.uploadedAt).toLocaleDateString()}</td>
//                                 <td><span className={`badge ${sheet.status === 'Approved' ? 'bg-success' : 'bg-warning'}`}>{sheet.status}</span></td>
//                                 <td>{sheet.client}</td>
//                                 <td>{sheet.comments}</td>
//                             </tr>
//                         ))
//                     ) : (
//                         <tr>
//                             <td colSpan="6" className="text-center">No timesheets found</td>
//                         </tr>
//                     )}
//                 </tbody>
//             </table>

//             {/* Pagination */}
//             <ResultNavigation
//                 currentPage={currentPage}
//                 totalItems={filteredTimesheets.length}
//                 itemsPerPage={timesheetsPerPage}
//                 onNextPage={handleNextPage}
//                 onPreviousPage={handlePreviousPage}
//                 isNextDisabled={currentPage >= Math.ceil(filteredTimesheets.length / timesheetsPerPage)}
//                 isPreviousDisabled={currentPage <= 1}
//             />

//             {/* UploadComponent for +Timesheet */}
//             <UploadComponent 
//                 isOpen={isUploadOpen} 
//                 onClose={closeUploadPopup}
//                 fields={[
//                     {
//                         label: 'Client',
//                         name: 'client',
//                         type: 'select',
//                         options: [
//                             { value: 'Client 1', label: 'Client 1' },
//                             { value: 'Client 2', label: 'Client 2' }
//                         ],
//                         required: true,
//                         onChange: handleChange
//                     },
//                     {
//                         label: 'Date (mm-dd-yyyy)',
//                         name: 'date',
//                         type: 'text',
//                         value: formData.date,
//                         placeholder: 'mm-dd-yyyy',
//                         onChange: handleChange,
//                         required: true
//                     },
//                     {
//                         label: 'Hours Worked',
//                         name: 'hours',
//                         type: 'text',
//                         required: true,
//                         onChange: handleChange
//                     },
//                     {
//                         label: 'Upload Document',
//                         name: 'document',
//                         type: 'file',
//                         onChange: handleChange
//                     }
//                 ]}
//             />

//             {error && <div className="alert alert-danger mt-3">{error}</div>}
//         </div>
//     );
// }

// export default TimesheetsPage;
