import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import menu4 from "../Data/menu4.json"; // Import the Knowledge Base menu data

function KnowledgeBaseSidebar(props) {
    const [isSidebarMini, setIsSidebarMini] = useState(false);
    const [menuData] = useState([...menu4?.menu4]); // Use the Knowledge Base menu
    const [darkLightMode, setDarkLightMode] = useState("light");
    const [updateRtl, setUpdateRtl] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        window.document.children[0].setAttribute("data-theme", "light");
    }, []);

    function onChangeDarkMode() {
        const currentTheme = window.document.children[0].getAttribute("data-theme");
        if (currentTheme === "light") {
            window.document.children[0].setAttribute("data-theme", "dark");
            setDarkLightMode("dark");
        } else {
            window.document.children[0].setAttribute("data-theme", "light");
            setDarkLightMode("light");
        }
    }

    function onChangeRTLMode() {
        if (document.body.classList.contains("rtl_mode")) {
            document.body.classList.remove("rtl_mode");
        } else {
            document.body.classList.add("rtl_mode");
        }
        setUpdateRtl(!updateRtl);
    }

    const { activekey } = props;

    return (
        <div
            id="knowledgeBaseSideMenu"
            className={`kbase px-4 py-4 py-md-5 me-0 ${
                isSidebarMini ? "sidebar-mini" : ""
            }`}
        >
            <div className="d-flex flex-column h-100">
                {/* Title */}
                <div className="kbase-title">Knowledge Base</div>

                {/* Menu List */}
                <ul className="kbase-menu flex-grow-1 mt-3">
                    {menuData.map((item, index) => (
                        <li
                            key={`menu-item-${index}`}
                            className={`kbase-item ${item.routerLink[0] === activekey ? "active" : ""}`}
                        >
                            <Link
                                to={`${process.env.PUBLIC_URL}/${item.routerLink[0]}`}
                                className="kbase-link"
                                title={item.breadcrumbMessage} // Add breadcrumb as title
                            >
                                <i className={`icon ${item.iconClass}`}></i>
                                <span>{item.name}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default KnowledgeBaseSidebar;

// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import menu4 from "../Data/menu4.json"; // Import the Knowledge Base menu data

// function KnowledgeBaseSidebar(props) {
//     const [isSidebarMini, setIsSidebarMini] = useState(false);
//     const [menuData, setMenuData] = useState([...menu4?.menu4]); // Use the Knowledge Base menu
//     const [darkLightMode, setDarkLightMode] = useState("light");
//     const [updateRtl, setUpdateRtl] = useState(false);

//     const navigate = useNavigate();

//     useEffect(() => {
//         window.document.children[0].setAttribute("data-theme", "light");
//     }, []);

//     function onChangeDarkMode() {
//         if (window.document.children[0].getAttribute("data-theme") === "light") {
//             window.document.children[0].setAttribute("data-theme", "dark");
//             setDarkLightMode("dark");
//         } else {
//             window.document.children[0].setAttribute("data-theme", "light");
//             setDarkLightMode("light");
//         }
//     }

//     function onChangeRTLMode() {
//         if (document.body.classList.contains("rtl_mode")) {
//             document.body.classList.remove("rtl_mode");
//         } else {
//             document.body.classList.add("rtl_mode");
//         }
//         setUpdateRtl(!updateRtl);
//     }

//     const { activekey } = props;

//     return (
//         <div
//             id="knowledgeBaseSideMenu"
//             className={`kbase px-4 py-4 py-md-5 me-0 ${
//                 isSidebarMini ? "sidebar-mini" : ""
//             }`}
//         >
//             <div className="d-flex flex-column h-100">
//                 <a href="/" className="mb-0 brand-icon">
//                     <span className="kbase-title">Knowledge Base</span>
//                 </a>
//                 <ul className="kbase-menu flex-grow-1 mt-3">
//                     {menuData.map((item, index) => (
//                         <li
//                             key={`menu-item-${index}`}
//                             className={`kbase-item ${item.routerLink[0] === activekey ? "active" : ""}`}
//                         >
//                             <Link
//                                 to={`${process.env.PUBLIC_URL}/${item.routerLink[0]}`}
//                                 className="kbase-link"
//                                 title={item.breadcrumbMessage} // Add breadcrumb as title
//                             >
//                                 <i className={`icon ${item.iconClass}`}></i>
//                                 <span>{item.name}</span>
//                             </Link>
//                         </li>
//                     ))}
//                 </ul>
//             </div>
//         </div>
//     );
// }

// export default KnowledgeBaseSidebar;