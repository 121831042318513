import React from "react";
import LoginForm from "./LoginForm";
import 'material-icons/iconfont/material-icons.css';
import KnowledgeBaseSidebar from "../../components/common/KnowledgeBaseSidebar";
import './LoginPage.css'

function LoginPage() {
    return (
        <div className="login-page d-flex">
           
            {/* Scrollable Login Form */}
            <div className="form-container">
                <h2>Welcome to HRMS!</h2>
                <div className="login-form-wrapper">
                    <LoginForm />
                </div>
            </div>
             {/* Sidebar */}
             <div className="kbase-container">
                <KnowledgeBaseSidebar />
            </div>
        </div>
    );
}

export default LoginPage;


// import React from "react";
// import LoginForm from "./LoginForm";
// import 'material-icons/iconfont/material-icons.css';
// import KnowledgeBaseSidebar from "../../components/common/KnowledgeBaseSidebar.js";

// function LoginPage() {
//     return (
//         <div className="login-page">
//             <h2>Welcome to HRMS!</h2>
//             <div className="container-xxl">
//                 <div className="row">
//                     {/* Login form should be scrollable */}
//                     <div className="col-md-10 col-lg-9 login-form-container">
//                         <LoginForm />
//                     </div>
//                     {/* Sidebar should remain fixed */}
//                     <div className="col-md-2 col-lg-3 sidebar-container">
//                         <KnowledgeBaseSidebar />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default LoginPage;

// import React from "react";
// import LoginForm from "./LoginForm";
// import 'material-icons/iconfont/material-icons.css';
// import KnowledgeBaseSidebar from "../../components/common/KnowledgeBaseSidebar.js";

// function LoginPage() {
//     return (
//         <div >
//             <h2>Welcome to HRMS!</h2>
//             <div className="container-xxl">
//                 <div className="row">
//                     {/* Dynamically adjust form width based on the state of KnowledgeBasePill */}
//                     <div className="col-md-10 col-lg-9">
//                         <LoginForm />
//                     </div>
//                     <div className="col-md-2 col-lg-3 mt-0">
//                         <KnowledgeBaseSidebar />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default LoginPage;