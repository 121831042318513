import React, { useState } from "react";
import './Leaves.css';
import SearchBar from './SearchBar'; 
import ResultNavigation from './ResultNavigation'; 
import UploadComponent from './UploadComponent';

function Leaves() {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredLeaves, setFilteredLeaves] = useState([]);
    const [showUpload, setShowUpload] = useState(false);

    const leavesSummary = [
        { type: "Type 1", total: 120, claimed: 5, available: 115 }
    ];

    const leavesHistory = [
        { leaveNo: "Leave 1", from: "05-AUG-2024", to: "06-AUG-2024", status: "Approved", type: "Ipseum" }
    ];

    const leavesPerPage = 1; 
    const totalLeaves = filteredLeaves.length > 0 ? filteredLeaves.length : leavesHistory.length;

    const indexOfLastLeave = currentPage * leavesPerPage;
    const indexOfFirstLeave = indexOfLastLeave - leavesPerPage;
    const currentLeaves = (filteredLeaves.length > 0 ? filteredLeaves : leavesHistory).slice(indexOfFirstLeave, indexOfLastLeave);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(totalLeaves / leavesPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const openUploadPopup = () => setShowUpload(true);
    const closeUploadPopup = () => setShowUpload(false); 

    return (
        <div className="leaves-container">
            <div className="right-options d-flex align-items-right">
                <button className="btn btn-primary" onClick={openUploadPopup}>+Leave</button>
            </div>
            <h3>Leaves Available</h3>
            <div className="summary-section">
                <table className="summary-table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Total</th>
                            <th>Claimed</th>
                            <th>Available</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leavesSummary.map((summary, index) => (
                            <tr key={index}>
                                <td><a href="#">{summary.type}</a></td>
                                <td>{summary.total}</td>
                                <td>{summary.claimed}</td>
                                <td>{summary.available}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <h3>Leaves History</h3>
            <div className="history-section">
                <SearchBar 
                    data={leavesHistory} 
                    setFilteredData={setFilteredLeaves} 
                    placeholder="Search..." 
                />

                <table className="history-table">
                    <thead>
                        <tr>
                            <th>Leave No.</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Status</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentLeaves.length > 0 ? (
                            currentLeaves.map((leave, index) => (
                                <tr key={index}>
                                    <td><a href="#">{leave.leaveNo}</a></td>
                                    <td>{leave.from}</td>
                                    <td>{leave.to}</td>
                                    <td><span className={`status-badge ${leave.status.toLowerCase()}`}>{leave.status}</span></td>
                                    <td>{leave.type}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">No records found</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <ResultNavigation 
                    currentPage={currentPage} 
                    totalItems={totalLeaves} 
                    itemsPerPage={leavesPerPage} 
                    onNextPage={handleNextPage} 
                    onPreviousPage={handlePreviousPage} 
                    isNextDisabled={currentPage >= Math.ceil(totalLeaves / leavesPerPage)}
                    isPreviousDisabled={currentPage <= 1}
                />
                <UploadComponent
                isOpen={showUpload}
                onClose={closeUploadPopup}
                fields={[
                    { label: "Type of Leave", name: "leaveType", type: "select", options: [{ value: "Type 1", label: "Type 1" }, { value: "Type 2", label: "Type 2" }] },
                    { label: "From/On (dd-mm-yyyy)", name: "fromDate", type: "date" },
                    { label: "To (dd-mm-yyyy)", name: "toDate", type: "date" },
                    { label: "Comments", name: "comments", type: "text" }
                ]}
            />
            </div>
        </div>
    );
}

export default Leaves;