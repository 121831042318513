import React, { useState } from 'react';

const SSNInput = ({ label, value, onChange, error }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e) => {
    const { value } = e.target;
    const formattedValue = value.replace(/\D/g, '').slice(0, 9); // Only allow digits and limit to 9
    onChange(formattedValue);
  };

  const formatSSN = (ssn) => {
    if (isFocused) {
      // Show unmasked SSN when focused
      return ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
    } else {
      // Show masked SSN when not focused
      return ssn.replace(/(\d{3})(\d{2})(\d{4})/, 'XXX-XX-$3');
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const handlePaste = (e) => {
    e.preventDefault(); // Stops the paste action
    
};

  return (
    <div className={`mb-3 ${error ? 'has-error' : ''}`}> {/* Container for styling spacing and error class */}
      <label className="form-label">{label}</label>
      <input
        type="text"
        onPaste={handlePaste}
        value={formatSSN(value)}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        maxLength="11" // Max length includes hyphens
        className={`form-control ${error ? 'is-invalid' : ''}`} // Bootstrap-like classes for styling
        placeholder={isFocused ? '123-45-6789' : 'XXX-XX-6789'}
      />
      {error && <div className="invalid-feedback">{error}</div>} {/* Error message container */}
    </div>
  );
};

export default SSNInput;
