import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import SSNInput from "./SSNInput";

function ForgotUserID() {
  const [showFirstSubmitButton, setShowFirstSubmitButton] = useState(true);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    ssn: "",
    securityAnswer1: "",
    securityAnswer2: "",
    securityQuestion1: "",
    securityQuestion2: ""
  });
  const [securityQuestions, setSecurityQuestions] = useState([]);
  const [errors, setErrors] = useState({});
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isRecaptchaValid, setIsRecaptchaValid] = useState(true);
  const [responseMessage, setResponseMessage] = useState(null); 
  const [showSecurityQuestions, setShowSecurityQuestions] = useState(false);

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    setSecurityQuestions([
      { id: 1, question: "What was your first pet's name?" },
      { id: 2, question: "What is your mother's maiden name?" },
      { id: 3, question: "What city were you born in?" },
    ]);
  }, []);

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "firstName":
      case "lastName":
        if (!/^[a-zA-Z]{2,30}$/.test(value)) {
          error = "Should be 2-30 characters long and contain only letters";
        }
        break;
      case "dateOfBirth":
        if (!/^\d{4}-\d{2}-\d{2}$/.test(value)) {
          error = "Should be in YYYY-MM-DD format";
        }
        break;
      case "ssn":
        if (!/^\d{9}$/.test(value)) {
          error = "Should be a 9-digit number without dashes";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setIsRecaptchaValid(!!value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage(null);
    
    const ssnError = validateField("ssn", formData.ssn);
    if (ssnError) {
        setErrors({ ...errors, ssn: ssnError });
        return;
    }

    

    //setShowFirstSubmitButton(false);

    // Normalize the SSN format before sending
    const normalizedSSN = formData.ssn.replace(/-/g, "");
    setFormData({ ...formData, ssn: normalizedSSN });

    // Log the form data being sent
    console.log("Data Sent to Backend:", {
        firstName: formData.firstName,
        lastName: formData.lastName,
        dateOfBirth: formData.dateOfBirth,
        ssn: normalizedSSN,
    });

    const formErrors = {}; 

    // Validate all fields before submission
    const answerKeys = ["securityAnswer1", "securityAnswer2"];
answerKeys.forEach((key) => {
    const error = validateField(key, formData[key]);
    if (error) formErrors[key] = error;
});


    if (!recaptchaValue) {
        setIsRecaptchaValid(false);
    }

    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0 && recaptchaValue) {
        try {
            const { firstName, lastName, dateOfBirth } = formData;

            // Call the backend API using fetch
            const response = await fetch(
                `http://localhost:3500/employee/forgot/UID?ssn=${normalizedSSN}&birth_date=${dateOfBirth}&firstName=${firstName}&lastName=${lastName}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            const result = await response.json();

            // Log the backend response
            console.log("Backend Response:", result);

            if (response.ok) {
                setShowSecurityQuestions(true);
                setResponseMessage(result.message);
            } else {
              
                setResponseMessage("Details do not exist. Please reverify your details.");
            }
        } catch (error) {
            setResponseMessage("An error occurred while verifying the details.");
            console.error("Error in API call:", error);
        }
    }
};

  
  return (
    <div className="card mb-3">
      <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
        <h6 className="mb-0 fw-bold">Forgot User ID</h6>
      </div>
      <div className="card-body">
      <form onSubmit={handleSubmit}>
          <div className="row g-3 align-items-center">
            <div className="col-md-6">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                required
              />
              {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
            </div>
           
            <div className="col-md-6">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                required
              />
              {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
            </div>
            <div className="col-md-6">
              <label className="form-label">Date Of Birth</label>
              <input
                type="date"
                className="form-control"
                id="dateOfBirth"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleInputChange}
                max={today}
                required
              />
              {errors.dateOfBirth && <div className="text-danger">{errors.dateOfBirth}</div>}
            </div>

            <div className="col-md-6">
              <SSNInput
                label="SSN"
                value={formData.ssn}
                onChange={(value) => setFormData({ ...formData, ssn: value })}
                error={errors.ssn}
              />
            </div>

            <div className="col-md-12">
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={handleRecaptchaChange}
              />
              {!isRecaptchaValid && (
                <div className="text-danger">Please verify you are not a robot</div>
              )}
            </div>

            <div className="col-md-12">
            {showFirstSubmitButton && (
              <button type="submit-security-questions" className="btn btn-primary mt-3">
                { "Submit"}
              </button>
            )}
            </div>


            {showSecurityQuestions && (
            <div className="row g-3 align-items-center">

                <div className="col-md-6">
                  <label className="form-label">Security Question 1</label>
                  <select
                    className="form-select"
                    id="securityQuestion1"
                    name="securityQuestion1"
                    value={formData.securityQuestion1}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select a question</option>
                    {securityQuestions.map((question) => (
                      <option key={question.id} value={question.id}>
                        {question.question}
                      </option>
                    ))}
                  </select>
                  {errors.securityQuestion1 && (
                    <div className="text-danger">{errors.securityQuestion1}</div>
                  )}
                </div>

                <div className="col-md-6">
                  <label htmlFor="securityAnswer1" className="form-label">Answer 1</label>
                  <input
                    type="text"
                    className="form-control"
                    id="securityAnswer1"
                    name="securityAnswer1"
                    value={formData.securityAnswer1}
                    onChange={handleInputChange}
                    required
                  />
                  {errors.securityAnswer1 && (
                    <div className="text-danger">{errors.securityAnswer1}</div>
                  )}
                </div>

                <div className="col-md-6">
                  <label className="form-label">Security Question 2</label>
                  <select
                    className="form-select"
                    id="securityQuestion2"
                    name="securityQuestion2"
                    value={formData.securityQuestion2}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select a question</option>
                    {securityQuestions.map((question) => (
                      <option key={question.id} value={question.id}>
                        {question.question}
                      </option>
                    ))}
                  </select>
                  {errors.securityQuestion2 && (
                    <div className="text-danger">{errors.securityQuestion2}</div>
                  )}
                </div>

                <div className="col-md-6">
                  <label htmlFor="securityAnswer2" className="form-label">Answer 2</label>
                  <input
                    type="text"
                    className="form-control"
                    id="securityAnswer2"
                    name="securityAnswer2"
                    value={formData.securityAnswer2}
                    onChange={handleInputChange}
                    required
                  />
                  {errors.securityAnswer2 && (
                    <div className="text-danger">{errors.securityAnswer2}</div>
                  )}
                </div>

                <div className="col-md-12">
              <button type="submit-security-questions" className="btn btn-primary mt-3">
                { "Submit"}
              </button>
            </div>
             {/* Display response message */}
             {responseMessage && (
              <div className="col-md-12 mt-3">
                <div className={`alert ${responseMessage.includes("successful") ? "alert-success" : "alert-danger"}`}>
                 {responseMessage}
                </div>
              </div>
                )}
              </div>
              )}
            </div>
      </form>
    </div>
  </div>
);
}



export default ForgotUserID;
