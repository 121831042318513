import 'material-icons/iconfont/material-icons.css';
import React, { useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import "../../assets/scss/bootstrap/kbase.css"


function KnowledgeBasePill() {
    const [isKnowledgeBasePillMini, setIsKnowledgeBasePillMini] = useState(false);

    return (
        <div className="border-top">
            <div className="card mb-3">
                <div className="card-body align-items-center">
                    <h3 id="Pills">Knowledge Base</h3>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="Azure">
                        <Row>
                            {/* Adjust the column width based on the collapse state */}
                            <Col sm={isKnowledgeBasePillMini ? 1 : 3}>
                                <Nav variant="pills" className={`flex-column ${isKnowledgeBasePillMini ? 'text-center' : ''}`}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Azure" className="d-inline-block px-3" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className="material-icons" style={{ marginRight: isKnowledgeBasePillMini ? '0' : '8px', fontSize: '20px' }}>cloud</span>
                                                {!isKnowledgeBasePillMini && <span style={{ lineHeight: '20px' }}>Cloud Technology (Azure)</span>}
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="AWS" className="d-inline-block px-3" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className="material-icons" style={{ marginRight: isKnowledgeBasePillMini ? '0' : '8px', fontSize: '20px' }}>cloud_queue</span>
                                                {!isKnowledgeBasePillMini && <span style={{ lineHeight: '20px' }}>Cloud Technology (AWS)</span>}
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="DataEngineering" className="d-inline-block px-3" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className="material-icons" style={{ marginRight: isKnowledgeBasePillMini ? '0' : '8px', fontSize: '20px' }}>storage</span>
                                                {!isKnowledgeBasePillMini && <span style={{ lineHeight: '20px' }}>Data Engineering</span>}
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="AI" className="d-inline-block px-3" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className="material-icons" style={{ marginRight: isKnowledgeBasePillMini ? '0' : '8px', fontSize: '20px' }}>smart_toy</span>
                                                {!isKnowledgeBasePillMini && <span style={{ lineHeight: '20px' }}>AI Technologies</span>}
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="Robotics" className="d-inline-block px-3" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className="material-icons" style={{ marginRight: isKnowledgeBasePillMini ? '0' : '8px', fontSize: '20px' }}>android</span>
                                                {!isKnowledgeBasePillMini && <span style={{ lineHeight: '20px' }}>Robotics</span>}
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="Selenium" className="d-inline-block px-3" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className="material-icons" style={{ marginRight: isKnowledgeBasePillMini ? '0' : '8px', fontSize: '20px' }}>bug_report</span>
                                                {!isKnowledgeBasePillMini && <span style={{ lineHeight: '20px' }}>Selenium</span>}
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="Java" className="d-inline-block px-3" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className="material-icons" style={{ marginRight: isKnowledgeBasePillMini ? '0' : '8px', fontSize: '20px' }}>code</span>
                                                {!isKnowledgeBasePillMini && <span style={{ lineHeight: '20px' }}>Java</span>}
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="React" className="d-inline-block px-3" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className="material-icons" style={{ marginRight: isKnowledgeBasePillMini ? '0' : '8px', fontSize: '20px' }}>psychology</span>
                                                {!isKnowledgeBasePillMini && <span style={{ lineHeight: '20px' }}>React</span>}
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </div>
    );
}

export default KnowledgeBasePill;
