import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [authState, setAuthState] = useState({
        isLoggedIn: false,
        firstName: "",
    });

    const login = (firstName) => {
        setAuthState({ isLoggedIn: true, firstName });
    };

    const logout = () => {
        setAuthState({ isLoggedIn: false, firstName: "" });
    };

    const updateAuthState = (newState) => {
        setAuthState((prevState) => ({ ...prevState, ...newState }));
    };

    return (
        <AuthContext.Provider value={{ authState, login, logout, updateAuthState }}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);


// import React, { createContext, useContext, useState } from "react";

// const AuthContext = createContext();

// export function AuthProvider({ children }) {
//   const [authState, setAuthState] = useState({
//     isLoggedIn: false,
//     firstName: "",
//   });

//   const login = (firstName) => {
//     console.log("Logging in:", firstName); // Debug log
//     setAuthState({ isLoggedIn: true, firstName });
//   };

//   const logout = () => {
//     console.log("Logging out"); // Debug log
//     setAuthState({ isLoggedIn: false, firstName: "" });
//   };

//   return (
//     <AuthContext.Provider value={{ authState, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// }

// export const useAuth = () => useContext(AuthContext);