import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Dropdown } from "react-bootstrap";
import "./../../../src/assets/scss/main.scss"
import SSNInput from "./SSNInput"; // Import the custom SSNInput component
import APIClient from "./APIClient";

function OnboardingW4() {
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    ssn: '', // SSN is fetched but will be readOnly
    address: '',
    city: '',
    state: '',
    zip: '',
    dropdownOption: '',
    signature: '',
  });

   // Declare useState

  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const jwt_token = Cookies.get("jwt_token");
      // const session_id = Cookies.get("session_id");
  
      if (jwt_token) {
        try {
          const response = await APIClient.get(`/employee/w4-details`, {
            headers: {
              Authorization: `Bearer ${jwt_token}`,
            },
            withCredentials: true, // Include cookies
          });
  
          const data = response.data.data;
          
          // Ensure SSN is a string and then format it
          const formattedSSN = formatSSN(data.ssn.toString());
          
          setFormData((prev) => ({
            ...prev,
            firstName: data.firstName,
            lastName: data.lastName,
            ssn: formattedSSN, // Set formatted SSN
            address: data.address,
            dropdownOption: data.w4TaxStatus,
          }));
        } catch (error) {
          console.error("Error during onboarding:", error);
  
          if (error.response && error.response.status === 403) {
            alert("Your session has expired. Please log in again.");
          } else {
            alert("An error occurred during onboarding. Please login again.");
          }
        }
      }
    };
  
    fetchData();
  }, []);
  
  

  const formatSSN = (value) => {
    // Remove any non-numeric characters except for the dash
    const cleanedValue = value.replace(/\D/g, '');

    // Format the cleaned value to NNN-NN-NNNN
    const formattedSSN = cleanedValue
      .slice(0, 9) // Ensure max length is 9 digits
      .replace(/(\d{3})(\d{2})(\d{0,4})/, '$1-$2-$3');

    return formattedSSN;
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the field is 'ssn' and apply formatting
    if (name === "ssn") {
      const formattedSSN = formatSSN(value);
      setFormData({
        ...formData,
        [name]: formattedSSN,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setErrors({
      ...errors,
      [name]: '',
    });
  };
    
  const validate = () => {
    let formErrors = {};
    if (!formData.firstName) formErrors.firstName = "First name is required";
    if (!formData.lastName) formErrors.lastName = "Last name is required";
    if (!formData.ssn) {
      formErrors.ssn = "SSN is required";
    } else if (!/^\d{3}-\d{2}-\d{4}$/.test(formData.ssn)) {
      formErrors.ssn = "SSN should be in the format NNN-NN-NNNN";
    }
    if (!formData.address) formErrors.address = "Address is required";
    if (!formData.selectOption || formData.selectOption === 'Select an Option') {
      formErrors.selectOption = "Please select an option";
    }
    if (!formData.signature) formErrors.signature = "Signature is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  
  const handleSave = () => {
    
      console.log("form saved:", formData);
      alert("Form saved successfully.");
   
    
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const payload = {
          // session_id: Cookies.get("session_id"),
          first_name: formData.firstName,
          last_name: formData.lastName,
          address: formData.address,
          w4_tax_status: formData.dropdownOption,
          signature: formData.signature,
        };

        const jwt_token = Cookies.get("jwt_token");
        const response = await APIClient.post("/employee/w4/onboard", payload, {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        });
        console.log("Form submitted successfully:", response.data);
        alert("Form submitted successfully");
      } catch (error) {
        console.error("Error submitting W-4 form:", error);
        alert("Error submitting W-4 form. Please login again.");
      }
    }
  };
  const handleClear = () => {
    setFormData({
      ...formData,
      selectOption: "",
      signature:"", 
      
  });
}
const handleSelect = (displayValue, eventKey) => {
  setFormData((prevData) => ({
    ...prevData,
    selectOption: displayValue, // Set display label as selected option
  }));
  setErrors((prevErrors) => ({
    ...prevErrors,
    selectOption: null, // Clear error on selection
  }));
};


  return (
    <form onSubmit={handleSubmit}>
      <div className="card mb-3">
        <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
          <h3 className="mb-0 fw-bold">Form W-4</h3>
        </div>
        <div className="card-body">
          <div className="alert alert-info">
            <p>
              I am aware that federal law provides for imprisonment and/or fines
              for false statements or use of false documents in connection with
              the completion of this form.
            </p>
            <p>
              I attest, under penalty of perjury, that I am (check one of the
              following boxes):
            </p>
          </div>
          {/* <div className="row g-3 align-items-center"> */}
            <div className="col-md-12">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                placeholder="First Name"
                onChange={handleInputChange}
              />
              {errors.firstName && (
                <span style={{ color: "red" }}>{errors.firstName}</span>
              )}
            </div>
            <div className="col-md-12">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="form-control"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
              />
              {errors.lastName && (
                <span style={{ color: "red" }}>{errors.lastName}</span>
              )}
            </div>
            <div className="col-md-12">
              <label htmlFor="ssn" className="form-label">
                SSN
              </label>
              <input
                type="text"
                name="ssn"
                id="ssn"
                placeholder="SSN"
                className="form-control"
                value={formData.ssn}
                onChange={handleInputChange}
                readOnly
              />
              {errors.ssn && (
                <span style={{ color: "red" }}>{errors.ssn}</span>
              )}
            </div>
            <div className="col-md-12">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <input
                type="text"
                name="address"
                id="address"
                className="form-control"
                placeholder="Address"
                value={formData.address}
                onChange={handleInputChange}
              />
              {errors.address && (
                <span style={{ color: "red" }}>{errors.address}</span>
              )}
            </div>
            
              <div className="col-md-12">
                <label htmlFor="city" className="form-label">
                  City or Town
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  name="city"
                  value={formData.city}
                  placeholder="City"
                  onChange={handleInputChange}
                />
                {errors.city && <span className="text-danger">{errors.city}</span>}
              </div>
              <div className="col-md-12">
                <label htmlFor="state" className="form-label">
                  State
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="state"
                  name="state"
                  value={formData.state}
                  placeholder="State"
                  onChange={handleInputChange}
                />
                {errors.state && (
                  <span className="text-danger">{errors.state}</span>
                )}
              </div>
              <div className="col-md-12">
                <label htmlFor="zipcode" className="form-label">
                  Zip Code
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="zipcode"
                  name="zipcode"
                  value={formData.zipcode}
                  placeholder="Zip Code"
                  onChange={handleInputChange}
                />
                {errors.zipcode && (
                  <span className="text-danger">{errors.zipcode}</span>
                )}
                </div>
            <div className="col-md-12">
            <label htmlFor="selectOption" className="form-label">
                Select an Option
              </label>
              {/* <select
                name="selectOption"
                className="form-control"
                id="selectOption"
                value={formData.selectOption}
                onChange={handleInputChange}
              >  */}
                
                {/* <option value="">Select an option</option>
                <option value="single">
                  Single or Married filing separately
                </option>
                <option value="jointly">
                  Married filing jointly or Qualifying surviving spouse
                </option>
                <option value="head">Head of household</option> */}
              {/* </select> */}
              
              <Dropdown style={{ width: '100%' }}>
          <Dropdown.Toggle
            variant=""
            id="selectOption"
            className="form-control custom-dropdown-toggle"
          >
            {formData.selectOption || 'Select an Option'} {/* Display selected option or placeholder */}
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ width: '100%' }}>
            <Dropdown.Item onClick={() => handleSelect("Single or Married filing separately", "single")}>
              Single or Married filing separately
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSelect("Married filing jointly or Qualifying surviving spouse", "jointly")}>
              Married filing jointly or Qualifying surviving spouse
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSelect("Head of household", "head")}>
              Head of household
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

              {errors.selectOption && (
                <span style={{ color: "red" }}>{errors.selectOption}</span>
              )}
            </div>
            

            <div className="col-md-12">
              <label htmlFor="signature" className="form-label">
                Signature
              </label>
              <input
                type="text"
                name="signature"
                id="signature"
                className="form-control"
                placeholder="Signature"
                value={formData.signature}
                onChange={handleInputChange}
              />
              {errors.signature && (
                <span style={{ color: "red" }}>{errors.signature}</span>
              )}
            </div>
          </div>
        {/* </div> */}
      </div>
      <div>
        <button type="button" className="btn btn-secondary mt-4" onClick={handleSave}>
          Save
        </button>
        <button type="button" className="btn btn-secondary mt-4" onClick={handleClear}>
                        Clear
                    </button>
        <button type="submit" className="btn btn-success mt-4">Submit</button>
      </div>
    </form>
  );
}

export default OnboardingW4;



