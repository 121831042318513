import React, { useState } from 'react';
import { Tab, Nav, Row, Col } from 'react-bootstrap';
import SearchBar from './SearchBar';
import ResultNavigation from './ResultNavigation'; // Import the ResultNavigation component
import './messages.css';

const messages = [
    
        { id: "message1", sender: "Google+", subject: "You were tagged in 3 photos", snippet: "Google+ You were tagged in three photos...", uploaded_at: "2024-10-20" },
        { id: "message2", sender: "YouTube", subject: "LauraBlack just uploaded a video.", snippet: "Jess, have you seen the video LauraBlack...", uploaded_at: "2024-10-21" },
        { id: "message3", sender: "Google+", subject: "You were tagged in 3 photos", snippet: "Google+ You were tagged in three photos...", uploaded_at: "2024-10-20" },
        { id: "message4", sender: "YouTube", subject: "LauraBlack just uploaded a video.", snippet: "Jess, have you seen the video LauraBlack...", uploaded_at: "2024-10-21" },
        { id: "message5", sender: "Google+", subject: "You were tagged in 3 photos", snippet: "Google+ You were tagged in three photos...", uploaded_at: "2024-10-20" },
        { id: "message6", sender: "YouTube", subject: "LauraBlack just uploaded a video.", snippet: "Jess, have you seen the video LauraBlack...", uploaded_at: "2024-10-21" },
        { id: "message7", sender: "Google+", subject: "You were tagged in 3 photos", snippet: "Google+ You were tagged in three photos...", uploaded_at: "2024-10-20" },
        { id: "message8", sender: "YouTube", subject: "LauraBlack just uploaded a video.", snippet: "Jess, have you seen the video LauraBlack...", uploaded_at: "2024-10-21" },
        { id: "message9", sender: "Google+", subject: "You were tagged in 3 photos", snippet: "Google+ You were tagged in three photos...", uploaded_at: "2024-10-20" },
        { id: "message10", sender: "YouTube", subject: "LauraBlack just uploaded a video.", snippet: "Jess, have you seen the video LauraBlack...", uploaded_at: "2024-10-21" },
        { id: "message11", sender: "Google+", subject: "You were tagged in 3 photos", snippet: "Google+ You were tagged in three photos...", uploaded_at: "2024-10-20" },
        { id: "message12", sender: "YouTube", subject: "LauraBlack just uploaded a video.", snippet: "Jess, have you seen the video LauraBlack...", uploaded_at: "2024-10-21" },
        { id: "message13", sender: "Google+", subject: "You were tagged in 3 photos", snippet: "Google+ You were tagged in three photos...", uploaded_at: "2024-10-20" },
        { id: "message14", sender: "YouTube", subject: "LauraBlack just uploaded a video.", snippet: "Jess, have you seen the video LauraBlack...", uploaded_at: "2024-10-21" },
        { id: "message15", sender: "Google+", subject: "You were tagged in 3 photos", snippet: "Google+ You were tagged in three photos...", uploaded_at: "2024-10-20" },
        { id: "message16", sender: "YouTube", subject: "LauraBlack just uploaded a video.", snippet: "Jess, have you seen the video LauraBlack...", uploaded_at: "2024-10-21" },
        { id: "message17", sender: "Google+", subject: "You were tagged in 3 photos", snippet: "Google+ You were tagged in three photos...", uploaded_at: "2024-10-20" },
        { id: "message18", sender: "YouTube", subject: "LauraBlack just uploaded a video.", snippet: "Jess, have you seen the video LauraBlack...", uploaded_at: "2024-10-21" },
      
        // Add additional messages as needed...
      
  // Add additional messages as needed...
];

const ITEMS_PER_PAGE = 5; // Define items per page for pagination

function Messages() {
  const [filteredMessages, setFilteredMessages] = useState(messages);
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total number of pages
  const totalPages = Math.ceil(filteredMessages.length / ITEMS_PER_PAGE);

  // Calculate the messages for the current page
  const paginatedMessages = filteredMessages.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // Functions to handle page navigation
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  // Mark a message as read when clicked
  const handleSelectMessage = (messageId) => {
    setFilteredMessages(prevMessages =>
      prevMessages.map(message =>
        message.id === messageId ? { ...message, read: true } : message
      )
    );
  };

  return (
    <div className="messages-container">
      <h2>Messages</h2>
      
      <Tab.Container id="left-tabs-example" defaultActiveKey="null">
        <Row className="h-100">
          <Col sm={4} className="message-list d-flex flex-column p-0">
            <SearchBar documents={messages} setFilteredDocuments={setFilteredMessages} />
            
            {/* Message list or "No messages available" text */}
            <Nav variant="pills" className="flex-column">
              {paginatedMessages.length > 0 ? (
                paginatedMessages.map((message) => (
                  <Nav.Item key={message.id}>
                    <Nav.Link 
                      eventKey={message.id} 
                      className="message-item"
                      onClick={() => handleSelectMessage(message.id)} // Set message as read
                    >
                      <strong style={{ fontWeight: message.read ? "normal" : "bold" }}>
                        {message.sender}
                      </strong>
                      <br />
                      <span style={{ fontWeight: message.read ? "normal" : "bold" }}>
                        {message.subject}
                      </span>
                      <br />
                      <small className="text-muted">{message.snippet}</small>
                    </Nav.Link>
                  </Nav.Item>
                ))
              ) : (
                <div className="text-center p-3">
                  <small className="text-muted">No messages available</small>
                </div>
              )}
            </Nav>

            {/* ResultNavigation component positioned at the bottom */}
            <div className="mt-auto">
              <ResultNavigation
                currentPage={currentPage}
                totalItems={filteredMessages.length}
                itemsPerPage={ITEMS_PER_PAGE}
                onNextPage={handleNextPage}
                onPreviousPage={handlePreviousPage}
              />
            </div>
          </Col>

          <Col sm={8} className="message-content">
            <Tab.Content>
              {paginatedMessages.map((message) => (
                <Tab.Pane eventKey={message.id} key={message.id}>
                  <h2>{message.subject}</h2>
                  <p><strong>From:</strong> {message.sender}</p>
                  <p>{message.snippet}</p>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}

export default Messages;
