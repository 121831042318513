import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import './Leaves.css';
import SearchBar from './SearchBar';
import ResultNavigation from './ResultNavigation';
import UploadComponent from './UploadComponent';
import StatusHistoryComponent from "./StatusHistoryComponent";
import EditDocumentComponent from './EditDocumentComponent';
import APIClient from "./APIClient";


function Leaves() {
    // const session_id = Cookies.get("session_id");
        const jwt_token = Cookies.get("jwt_token");
    const [summaryData, setSummaryData] = useState([]);
    const [leavesHistory, setLeavesHistory] = useState([]);
    const [filteredLeaves, setFilteredLeaves] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const leavesPerPage = 5;
    const [showUpload, setShowUpload] = useState(false);
    const [showStatusHistory, setShowStatusHistory] = useState(false);
    const [selectedLeave, setSelectedLeave] = useState(null);
    const [leaveTypes, setLeaveTypes] = useState([]);
    const [showEdit, setShowEdit] = useState(false); // State for EditDocumentComponent
    const [editData, setEditData] = useState(null); 

    const fetchSummaryData = async () => {
        // const session_id = Cookies.get("session_id");
        const jwt_token = Cookies.get("jwt_token");

        if (jwt_token) {
            try {
                const response = await APIClient.get(`/employee/leaves/summary`, {
                    headers: {
                        Authorization: `Bearer ${jwt_token}`,
                    },
                    withCredentials: true,
                });

                if (response.data.success) {
                    setSummaryData(response.data.data);
                } else {
                    console.error("Error fetching summary data:", response.data.message);
                }
            } catch (error) {
                console.error("Error fetching leaves summary:", error);
            }
        }
    };

    const fetchLeavesHistory = async () => {
        // const session_id = Cookies.get("session_id");
        const jwt_token = Cookies.get("jwt_token");

        if (jwt_token) {
            try {
                const response = await APIClient.get(`/employee/leaves`, {
                    headers: {
                        Authorization: `Bearer ${jwt_token}`,
                    },
                    withCredentials: true,
                });

                if (response.data.success) {
                    setLeavesHistory(response.data.data || []);
                    setFilteredLeaves(response.data.data || []); 
                } else {
                    console.error("Unexpected response:", response);
                }
            } catch (error) {
                console.error("Error fetching leaves history:", error);
            }
        }
    };

    const fetchLeaveTypes = async () => {
        const jwt_token = Cookies.get("jwt_token");

        try {
            const response = await APIClient.get('/employee/leaves/type/description/types', {
                headers: {
                    Authorization: `Bearer ${jwt_token}`,
                },
                withCredentials: true,
            });

            if (response.data.success) {
                setLeaveTypes(response.data.data);
            } else {
                console.error("Error fetching leave types:", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching leave types:", error);
        }
    };

    const handleSubmit = async (data) => {
        // const session_id = Cookies.get("session_id");
        const jwt_token = Cookies.get("jwt_token");
    
        if (jwt_token) {
            try {
                console.log("Submitting leave data:", data); // Debug log to confirm data
                const response = await APIClient.post(
                    `/employee/leaves/upload`,
                    data,
                    {
                        headers: {
                            Authorization: `Bearer ${jwt_token}`,
                        },
                        withCredentials: true,
                    }
                );
    
                if (response.data.success) {
                    console.log("Leave submitted successfully");
                    fetchLeavesHistory(); // Refresh leave history after submission
                    setShowUpload(false); // Close the upload modal
                } else {
                    console.error("Error submitting leave:", response.data.message);
                }
            } catch (error) {
                console.error("Error submitting leave:", error);
            }
        } else {
            console.error("Session ID or JWT token missing");
        }
    };
    
    
    const openEditPopup = (leave) => {
        setEditData({
            ...leave,
            From: formatDate(leave.From),
            To: formatDate(leave.To),
        }); // Format dates for the edit form
        setShowEdit(true);
    };

    const closeEditPopup = () => setShowEdit(false);

    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    useEffect(() => {
        fetchSummaryData();
        fetchLeavesHistory();
        fetchLeaveTypes(); // Fetch leave types on component mount
    }, []);

    const indexOfLastLeave = currentPage * leavesPerPage;
    const indexOfFirstLeave = indexOfLastLeave - leavesPerPage;
    const currentLeaves = filteredLeaves.slice(indexOfFirstLeave, indexOfLastLeave);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredLeaves.length / leavesPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const openStatusHistory = (leave) => {
        setSelectedLeave(leave);
        setShowStatusHistory(true);
    };

    const closeStatusHistory = () => setShowStatusHistory(false);

    const openUploadPopup = () => setShowUpload(true);
    const closeUploadPopup = () => setShowUpload(false);

    const handleSearchQueryChange = (query) => {
        const filtered = leavesHistory.filter((leave) =>
            leave.type?.toLowerCase().includes(query.toLowerCase()) ||
            leave.status?.toLowerCase().includes(query.toLowerCase()) ||
            leave.Comments?.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredLeaves(filtered);
        setCurrentPage(1); 
    };

    return (
        <div className="leaves-container">
            <div className="right-options d-flex align-items-right">
                <button className="btn btn-primary" onClick={openUploadPopup}>+Leave</button>
            </div>
            <h3>Leaves Summary</h3>
            <div className="summary-section">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Total</th>
                            <th>Claimed</th>
                            <th>Available</th>
                        </tr>
                    </thead>
                    <tbody>
                        {summaryData.length > 0 ? (
                            summaryData.map((summary, index) => (
                                <tr key={index}>
                                    <td>{summary.type}</td>
                                    <td>{summary.Total}</td>
                                    <td>{summary.Claimed}</td>
                                    <td>{summary.Available}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="text-center">No summary data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <h3>Leaves History</h3>
            <SearchBar data={leavesHistory} setFilteredData={setFilteredLeaves} onSearch={handleSearchQueryChange} placeholder="Search leaves..." />

            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Leave Type</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Status</th>
                        <th>Comments</th>
                        <th style={{ width: '100px', textAlign: 'center' }}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentLeaves.length > 0 ? (
                        currentLeaves.map((leave, index) => (
                            <tr key={index}>
                                <td>{leave.type}</td>
                                <td>{leave.From ? leave.From.split('T')[0] : 'N/A'}</td>
                                <td>{leave.To ? leave.To.split('T')[0] : 'N/A'}</td>
                                <td>
                                    <span className={`badge ${leave.status === 'Approved' ? 'bg-success' : 'bg-warning'}`}>
                                        {leave.status}
                                    </span>
                                </td>
                                <td>{leave.Comments || 'N/A'}</td>
                                <td style={{ textAlign: 'center' }}>
                        <button className="btn btn-secondary btn-sm" onClick={() => openEditPopup(leave)}>
                            Edit
                        </button>
                    </td>
                                
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" className="text-center">No leaves found</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <EditDocumentComponent
                isOpen={showEdit}
                onClose={closeEditPopup}
                fields={[
                    { label: 'Type of Leave', name: 'type', type: 'select', options: leaveTypes },
                    { label: 'From', name: 'From', type: 'date' },
                    { label: 'To', name: 'To', type: 'date' },
                    { label: 'Comments', name: 'Comments', type: 'text' }
                ]}
                initialData={editData}
                url={`/employee/leaves/edit`}
                jwt_token={Cookies.get("jwt_token")}
            />
            

        

            <StatusHistoryComponent
                isOpen={showStatusHistory}
                onClose={closeStatusHistory}
                details={selectedLeave}
            />

            <ResultNavigation
                currentPage={currentPage}
                totalItems={filteredLeaves.length}
                itemsPerPage={leavesPerPage}
                onNextPage={handleNextPage}
                onPreviousPage={handlePreviousPage}
                isNextDisabled={currentPage >= Math.ceil(filteredLeaves.length / leavesPerPage)}
                isPreviousDisabled={currentPage <= 1}
            />

            <UploadComponent
                isOpen={showUpload}
                onClose={closeUploadPopup}
                onSubmit={(data) => handleSubmit(data)} 
                jwt_token={jwt_token} 
                // session_id={session_id} 
                url={`/employee/leaves/upload`} // Pass the dynamic URL here
                fields={[
                    { label: "Type of Leave", name: "type", type: "select", options: leaveTypes },
                    { label: "From/On (mm-dd-yyyy)", name: "from", type: "date" },
                    { label: "To (mm-dd-yyyy)", name: "to", type: "date" },
                    { label: "Comments", name: "comments", type: "text" }
                ]}
            />
        </div>
    );
}

export default Leaves;