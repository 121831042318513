import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './TimesheetsPage.css';
import SearchBar from './SearchBar';
import ResultNavigation from './ResultNavigation';
import UploadComponent from './UploadComponent';
import StatusHistoryComponent from './StatusHistoryComponent';
import EditDocumentComponent from './EditDocumentComponent';
import APIClient from './APIClient';

function TimesheetsPage() {
    const [timesheets, setTimesheets] = useState([]);
    const [showStatusHistory, setShowStatusHistory] = useState(false);
    const [selectedTimesheet, setSelectedTimesheet] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredTimesheets, setFilteredTimesheets] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [isUploadOpen, setIsUploadOpen] = useState(false);
    const [formData, setFormData] = useState({ date: '', client: '', hours: '', document: '' });
    const [error, setError] = useState('');
    const [isEditOpen, setIsEditOpen] = useState(false); // State for Edit Component
    const [editData, setEditData] = useState(null); // Selected data for editing


    const timesheetsPerPage = 3;

    useEffect(() => {
        const fetchTimesheets = async () => {
            const jwt_token = Cookies.get('jwt_token');

            if (jwt_token) {
                try {
                    const response = await APIClient.get('/employee/timesheets', {
                        headers: {
                            Authorization: `Bearer ${jwt_token}`,
                        },
                    });

                    if (response.data.success) {
                        setTimesheets(response.data.timesheets || []);
                    } else {
                        console.error('Unexpected response:', response);
                    }
                } catch (error) {
                    console.error('Error fetching timesheets:', error);
                }
            }
        };

        fetchTimesheets();
    }, []);

    const fetchStatusHistory = async (timesheetId) => {
        const jwt_token = Cookies.get('jwt_token');
        try {
            const response = await APIClient.get('/employee/timesheet/status/history', {
                headers: {
                    Authorization: `Bearer ${jwt_token}`,
                },
                params: { employee_timesheets_id: timesheetId },
            });

            if (response.data.success) {
                return response.data.data.statusHistory || [];
            } else {
                console.error('No status history found:', response.data.message);
                return [];
            }
        } catch (error) {
            console.error('Error fetching status history:', error);
            throw error;
        }
    };

    const openStatusHistory = (timesheet) => {
        const details = {
            id: timesheet.id, // Use the unique ID for the timesheet
            pay_period: timesheet.pay_period,
            client: timesheet.client,
            status: timesheet.status,
            uploadedAt: timesheet.uploadedAt,
            comments: timesheet.comments,
            hours: timesheet.hours,
        };
        setSelectedTimesheet(details);
        setShowStatusHistory(true);
    };

    

    const indexOfLastTimesheet = currentPage * timesheetsPerPage;
    const indexOfFirstTimesheet = indexOfLastTimesheet - timesheetsPerPage;
    const currentTimesheets = filteredTimesheets.slice(indexOfFirstTimesheet, indexOfLastTimesheet);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredTimesheets.length / timesheetsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };
    const openUploadPopup = () => {
        setIsUploadOpen(true);
    };

    const closeUploadPopup = () => {
        setIsUploadOpen(false);
    };
    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    
    const openEditPopup = (timesheet) => {
        const formattedData = {
            ...timesheet,
            date: formatDate(timesheet.uploadedAt),
        };
        setEditData(formattedData);
        setIsEditOpen(true);
    };
    

    const closeEditPopup = () => setIsEditOpen(false);
    const fetchTimesheetStatusHistory = (id) => [
        { status: 'Requested', updatedAt: '06-Aug-2024 09:00 AM PT', comments: 'No Wrap Comments' },
        { status: 'Approved', updatedAt: '06-Aug-2024 03:00 PM PT', comments: 'No Wrap Comments' }
    ];

    // Calculate total hours from filtered timesheets
    const totalHours = filteredTimesheets.reduce((total, sheet) => total + (parseFloat(sheet.hours) || 0), 0);
    const closeStatusHistory = () => setShowStatusHistory(false);

    

    return (
        <div className="timesheets-container">
            {/* Header section */}
            <div className="header-bar d-flex justify-content-between align-items-center mb-4">
                <div className="right-options d-flex align-items-center">
                    <div className="download-section me-3">
                        <label> Approved Timesheets</label>
                        <select className="form-select me-2">
                            <option value="">Select Pay Period</option>
                            {/* Populate options dynamically */}
                        </select>
                        <button className="btn btn-primary">Download</button>
                    </div>
                </div>
                <div className="left-options d-flex align-items-center">
                    <button className="btn btn-primary" onClick={openUploadPopup}>+ Timesheet</button>
                </div>
            </div>

            {/* Search and Filters */}
            <div className="search-and-filters d-flex align-items-center mb-7">
                <SearchBar 
                    documents={timesheets} 
                    setFilteredDocuments={setFilteredTimesheets} 
                />
                <button className="btn btn-primary ms-2">Download</button>
                <span className="total-hours ms-3">Total Hours: {filteredTimesheets.reduce((total, sheet) => total + sheet.hours, 0)}</span>
            </div>

            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Pay Period</th>
                        <th scope="col">Hours</th>
                        <th scope="col">Uploaded At</th>
                        <th scope="col">Current Status</th>
                        <th scope="col">Client</th>
                        <th scope="col">Comments</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentTimesheets.length > 0 ? (
                        currentTimesheets.map((sheet, index) => (
                            <tr key={index}>
                                <td><a href="#" onClick={() => openStatusHistory(timesheets)}>{timesheets.timesheetNo}</a></td>
                                <td>{sheet.hours}</td>
                                <td>{new Date(sheet.uploadedAt).toLocaleDateString()}</td>
                                <td><span className={`badge ${sheet.status === 'Approved' ? 'bg-success' : 'bg-warning'}`}>{sheet.status}</span></td>
                                <td>{sheet.client}</td>
                                <td>{sheet.comments}</td>
                                <td>
                                    <button className="btn btn-secondary btn-sm" onClick={() => openEditPopup(sheet)}>
                                        Edit
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className="text-center">No timesheets found</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <StatusHistoryComponent
                    isOpen={showStatusHistory}
                    onClose={closeStatusHistory}
                    details={{
                        id: 'Timesheet1',
                        Name: 'Timesheet 1',
                        'Current Status': 'Approved',
                        'Uploaded On': '06-Aug-2024 09:00 AM PT'
                    }}
                    fetchStatusHistory={fetchTimesheetStatusHistory}
                />

            {/* Pagination */}
            <ResultNavigation
                currentPage={currentPage}
                totalItems={filteredTimesheets.length}
                itemsPerPage={timesheetsPerPage}
                onNextPage={handleNextPage}
                onPreviousPage={handlePreviousPage}
                isNextDisabled={currentPage >= Math.ceil(filteredTimesheets.length / timesheetsPerPage)}
                isPreviousDisabled={currentPage <= 1}
            />
            <EditDocumentComponent
                isOpen={isEditOpen}
                onClose={closeEditPopup}
                fields={[
                    { label: 'Client', name: 'client', type: 'text' },
                    { label: 'Date', name: 'date', type: 'date' },
                    { label: 'Hours', name: 'hours', type: 'text' },
                    { label: 'Comments', name: 'comments', type: 'text' }
                ]}
                initialData={editData}
                url={`/employee/timesheets/edit`}
                jwt_token={Cookies.get("jwt_token")}
            />

            {/* UploadComponent for +Timesheet */}
            <UploadComponent 
                isOpen={isUploadOpen} 
                onClose={closeUploadPopup}
                fields={[
                    {
                        label: 'Client',
                        name: 'client',
                        type: 'select',
                        options: [
                            { value: 'Client 1', label: 'Client 1' },
                            { value: 'Client 2', label: 'Client 2' }
                        ],
                        required: true,
                        onChange: handleChange
                    },
                    {
                        label: 'Date (mm-dd-yyyy)',
                        name: 'date',
                        type: 'text',
                        value: formData.date,
                        placeholder: 'mm-dd-yyyy',
                        onChange: handleChange,
                        required: true
                    },
                    {
                        label: 'Hours Worked',
                        name: 'hours',
                        type: 'text',
                        required: true,
                        onChange: handleChange
                    },
                    {
                        label: 'Upload Document',
                        name: 'document',
                        type: 'file',
                        onChange: handleChange
                    }
                ]}
            />

            {error && <div className="alert alert-danger mt-3">{error}</div>}
        </div>
    );
}

export default TimesheetsPage;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Cookies from 'js-cookie';
// import './TimesheetsPage.css';
// import StatusHistoryComponent from './StatusHistoryComponent';

// function TimesheetsPage() {
//     const [timesheets, setTimesheets] = useState([]);
//     const [showStatusHistory, setShowStatusHistory] = useState(false);
//     const [selectedTimesheet, setSelectedTimesheet] = useState(null);

//     useEffect(() => {
//         const fetchTimesheets = async () => {
//             const jwt_token = Cookies.get('jwt_token');

//             if (jwt_token) {
//                 try {
//                     const response = await axios.get('http://0.0.0.0:3500/employee/timesheets', {
//                         headers: {
//                             Authorization: `Bearer ${jwt_token}`,
//                         },
//                     });

//                     if (response.data.success) {
//                         setTimesheets(response.data.timesheets || []);
//                     } else {
//                         console.error('Unexpected response:', response);
//                     }
//                 } catch (error) {
//                     console.error('Error fetching timesheets:', error);
//                 }
//             }
//         };

//         fetchTimesheets();
//     }, []);

//     const fetchStatusHistory = async (timesheetId) => {
//         console.log('Fetching status history', timesheetId);
//         const jwt_token = Cookies.get('jwt_token');
//         try {
//             console.log('Fetching status hihvhvcy', timesheetId);

//             const response = await axios.get(`http://0.0.0.0:3500/employee/timesheet/status/history`, {
//                 headers: {
//                     Authorization: `Bearer ${jwt_token}`,
//                 },
//                 params: { employee_timesheets_id: timesheetId }, // Pass the ID correctly
//             });
    
//             if (response.data.success) {
//                 return response.data.statusHistory || [];
//             } else {
//                 console.error('No status history found:', response.data.message);
//                 return [];
//             }
//         } catch (error) {
//             console.error('Error fetching status history:', error);
//             throw error;
//         }
//     };
    

//     const openStatusHistory = (timesheet) => {
//         const details = {
//             id: timesheet.id, // Ensure this matches the ID field from backend
//             pay_period: timesheet.pay_period,
//             client: timesheet.client,
//             status: timesheet.status,
//             uploadedAt: timesheet.uploadedAt,
//             comments: timesheet.comments,
//             hours: timesheet.hours,
//         };
//         setSelectedTimesheet(details);
//         setShowStatusHistory(true);
//     };
    
//     const closeStatusHistory = () => {
//         setShowStatusHistory(false);
//         setSelectedTimesheet(null);
//     };

//     return (
//         <div className="timesheets-container">
//             <table className="table table-striped">
//                 <thead>
//                     <tr>
//                         <th>Pay Period</th>
//                         <th>Hours</th>
//                         <th>Uploaded At</th>
//                         <th>Status</th>
//                         <th>Client</th>
//                         <th>Comments</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {timesheets.map((sheet, index) => (
//                         <tr key={index}>
//                             <td>
//                                 <a href="#" onClick={() => openStatusHistory(sheet)}>
//                                     {sheet.pay_period}
//                                 </a>
//                             </td>
//                             <td>{sheet.hours}</td>
//                             <td>{new Date(sheet.uploadedAt).toLocaleDateString()}</td>
//                             <td>{sheet.status}</td>
//                             <td>{sheet.client}</td>
//                             <td>{sheet.comments}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>

//             <StatusHistoryComponent
//                 isOpen={showStatusHistory}
//                 onClose={closeStatusHistory}
//                 details={selectedTimesheet}
//                 fetchStatusHistory={fetchStatusHistory}
//             />
//         </div>
//     );
// }

// export default TimesheetsPage;