// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
// import './DocumentsPage.css';
// import SearchBar from './SearchBar';
// import ResultNavigation from './ResultNavigation';
// import UploadComponent from './UploadComponent';
// import StatusHistoryComponent from "./StatusHistoryComponent";


// function DocumentsPage() {
//     const [isPreFilled, setIsPreFilled] = useState(true);
//     const [searchQuery, setSearchQuery] = useState(''); 
//     const [documents, setDocuments] = useState([]); 
//     const [currentPage, setCurrentPage] = useState(1); 
//     const documentsPerPage = 3; 
//     const [isUploadOpen, setIsUploadOpen] = useState(false); 
//     const [documentTypes, setDocumentTypes] = useState([]); 
//     const [filteredDocuments, setFilteredDocuments] = useState([]); 
//     const [showStatusHistory, setShowStatusHistory] = useState(false);
//     const [selectedDocument, setSelectedDocument] = useState(null);
//     const [uploadStatus, setUploadStatus] = useState(""); // State for upload status message

//     const handleFormTypeChange = (event) => {
//         setIsPreFilled(event.target.value === 'pre-filled');
//     };

//     const handleUploadClick = () => {
//         setIsUploadOpen(true);
//     };

//     const closeUploadPopup = () => {
//         setIsUploadOpen(false);
//         setUploadStatus(""); // Reset status on popup close
//     };

//     // Define fetchDocuments function first
//     const fetchDocuments = async () => {
//         const session_id = Cookies.get("session_id");
//         const jwt_token = Cookies.get("jwt_token");

//         if (session_id && jwt_token) {
//             try {
//                 const response = await axios.get(`http://0.0.0.0:3500/employee/documents/${session_id}`, {
//                     headers: {
//                         Authorization: `Bearer ${jwt_token}`,
//                     },
//                     withCredentials: true,
//                 });

//                 if (response.status === 200) {
//                     const documents = response.data.documents.map(doc => ({
//                         ...doc,
//                         uploaded_at: new Date(doc.uploaded_at)
//                     }));
//                     setDocuments(documents);
//                     setFilteredDocuments(documents);
//                 } else {
//                     console.error("Unexpected response:", response);
//                 }
//             } catch (error) {
//                 console.error("Error fetching documents:", error);
//                 if (error.response && error.response.status === 404) {
//                     alert("Documents not found.");
//                 } else if (error.response && error.response.status === 401) {
//                     alert("Session expired. Please log in again.");
//                     window.location.href = "/login";
//                 } else {
//                     alert("An error occurred. Please try again.");
//                 }
//             }
//         }
//     };

//     // Upload document function
//     const handleUploadDocument = async (formData) => {
//         const jwt_token = Cookies.get("jwt_token");

//         try {
//             const response = await axios.post(
//                 'http://0.0.0.0:3500/employee/document/upload', // Upload endpoint
//                 formData,
//                 {
//                     headers: {
//                         Authorization: `Bearer ${jwt_token}`,
//                         'Content-Type': 'multipart/form-data',
//                     },
//                     withCredentials: true,
//                 }
//             );

//             if (response.data.success) {
//                 setUploadStatus("Document uploaded successfully");
//                 fetchDocuments(); // Refresh documents list
//             } else {
//                 setUploadStatus("Error uploading document: " + response.data.message);
//             }
//         } catch (error) {
//             console.error("Upload error:", error);
//             setUploadStatus(error.response?.data?.message || "An error occurred during upload");
//         }
//     };

//     const uploadFields = [
//         { name: 'doc_name', label: 'Document Name', type: 'text', required: true },
//         {
//             name: 'doc_type',
//             label: 'Document Type',
//             type: 'select',
//             options: documentTypes.map(type => ({ value: type.forms_master_id, label: type.form_type })),
//             required: true 
//         },
//         { name: 'comments', label: 'Comments', type: 'textarea', required: false },
//         { name: 'file', label: 'Upload File', type: 'file', required: true },
//     ];

//     const fetchDocumentStatusHistory = (id) => [
//         { status: 'Requested', updatedAt: '06-Aug-2024 09:00 AM PT', comments: 'No Wrap Comments' },
//         { status: 'Approved', updatedAt: '06-Aug-2024 03:00 PM PT', comments: 'No Wrap Comments' }
//     ];

//     useEffect(() => {
//         const fetchDocumentTypes = async () => {
//             try {
//                 const response = await axios.get('http://0.0.0.0:3500/employee/document-types');
//                 if (response.data.success) {
//                     setDocumentTypes(response.data.data);
//                 } else {
//                     console.error("Failed to fetch document types");
//                 }
//             } catch (error) {
//                 console.error("Error fetching document types:", error);
//             }
//         };

//         fetchDocumentTypes();
//         fetchDocuments(); // Ensure this function is defined before use
//     }, []);

//     const indexOfLastDocument = currentPage * documentsPerPage;
//     const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
//     const currentDocuments = filteredDocuments.slice(indexOfFirstDocument, indexOfLastDocument);
//     const closeStatusHistory = () => setShowStatusHistory(false);

//     const handleNextPage = () => {
//         if (currentPage < Math.ceil(filteredDocuments.length / documentsPerPage)) {
//             setCurrentPage(currentPage + 1);
//         }
//     };

//     const handlePreviousPage = () => {
//         if (currentPage > 1) {
//             setCurrentPage(currentPage - 1);
//         }
//     };

//     const handleSearchQueryChange = (query) => {
//         setSearchQuery(query);
//         setCurrentPage(1); 
//     };

//     const clearSearch = () => {
//         setSearchQuery('');
//         setCurrentPage(1);
//     };

//     const openStatusHistory = (document) => {
//         setSelectedDocument(document);
//         setShowStatusHistory(true);
//     };

//     return (
//         <div className={`document-status-container ${isUploadOpen ? 'blur' : ''}`}>
//             <div className="top-bar d-flex justify-content-between align-items-center mb-4">
//                 <div className="left-options d-flex align-items-center">
//                     <select 
//                         className="form-select me-3" 
//                         onChange={handleFormTypeChange} 
//                         value={isPreFilled ? 'pre-filled' : 'unfilled' }
//                             style={{ minWidth: "150px", whiteSpace: "nowrap" }
//                         }
//                     >
//                         <option value="unfilled">Unfilled Form</option>
//                         <option value="pre-filled">Pre-filled Form</option>
//                     </select>

//                     <select
//                         className="form-select me-3" style={{ minWidth: "12rem", whiteSpace: "nowrap" }}
//                         >
//                         <option value="">
//                             {isPreFilled ? "Select Pre-filled Form" : "Select Unfilled Form"}
//                         </option>
//                         {isPreFilled ? (
//                             <>
//                             <option value="pre1">Pre-filled Form 1</option>
//                             <option value="pre2">Pre-filled Form 2</option>
//                             </>
//                         ) : (
//                             <>
//                             <option value="form1">Unfilled Form 1</option>
//                             <option value="form2">Unfilled Form 2</option>
//                             </>
//                         )}
//                     </select>


//                     <button className="btn btn-primary me-2" style={{ marginBottom:'0.7rem' }}>Download</button>
//                 </div>
//                 <div className="right-options">
//                     <button className="btn btn-success" onClick={handleUploadClick}>Upload Document</button>
//                 </div>
//             </div>

//             <UploadComponent 
//                 isOpen={isUploadOpen} 
//                 onClose={closeUploadPopup} 
//                 fields={uploadFields} 
//                 onUpload={handleUploadDocument} // Pass upload handler
//             />

//             {uploadStatus && <p>{uploadStatus}</p>} {/* Show upload status */}
            
//             <SearchBar documents={documents} setFilteredDocuments={setFilteredDocuments} />

//             <table className="table table-striped">
//                 <thead>
//                     <tr>
//                         <th scope="col">Name</th>
//                         <th scope="col">Type</th>
//                         <th scope="col">Uploaded On</th>
//                         <th scope="col">Current Status</th>
//                         <th scope="col">Comments</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {currentDocuments.length > 0 ? (
//                         currentDocuments.map((doc, index) => (
//                             <tr key={index}>
//                                 <td>{doc.name}</td>
//                                 <td>{doc.type}</td>
//                                 <td>
//                                     {doc.uploaded_at instanceof Date && !isNaN(doc.uploaded_at)
//                                         ? doc.uploaded_at.toLocaleDateString()
//                                         : 'N/A'}
//                                 </td>
//                                 <td>
//                                     <span className={`badge ${doc.status === 'Approved' ? 'bg-success' : 'bg-warning'}`}>
//                                         {doc.status}
//                                     </span>
//                                 </td>
//                                 <td>{doc.comments}</td>
//                             </tr>
//                         ))
//                     ) : (
//                         <tr>
//                             <td colSpan="5" className="text-center">No documents found</td>
//                         </tr>
//                     )}
//                 </tbody>
//             </table>
//             <StatusHistoryComponent
//                     isOpen={showStatusHistory}
//                     onClose={closeStatusHistory}
//                     details={{
//                         id: 'Document1',
//                         Name: 'Document 1',
//                         Type: "H1",
//                         'Current Status': 'Approved',
//                         'Uploaded On': '06-Aug-2024 09:00 AM PT'
//                     }}
//                     fetchStatusHistory={fetchDocumentStatusHistory}
//                 />

//             <ResultNavigation
//                 currentPage={currentPage}
//                 totalItems={filteredDocuments.length}
//                 itemsPerPage={documentsPerPage}
//                 onNextPage={handleNextPage}
//                 onPreviousPage={handlePreviousPage}
//                 isNextDisabled={currentPage >= Math.ceil(filteredDocuments.length / documentsPerPage)}
//                 isPreviousDisabled={currentPage <= 1}
//             />
//         </div>
//     );
// }

// export default DocumentsPage;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import './DocumentsPage.css';
import SearchBar from './SearchBar';
import ResultNavigation from './ResultNavigation';
import UploadComponent from './UploadComponent';
import EditDocumentComponent from './EditDocumentComponent';
import StatusHistoryComponent from "./StatusHistoryComponent";
import APIClient from "./APIClient";

function DocumentsPage() {
    const [isPreFilled, setIsPreFilled] = useState(true);
    const [searchQuery, setSearchQuery] = useState(''); 
    const [documents, setDocuments] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1); 
    const documentsPerPage = 3; 
    const [isUploadOpen, setIsUploadOpen] = useState(false); 
    const [isEditOpen, setIsEditOpen] = useState(false); // State to track edit modal
    const [showStatusHistory, setShowStatusHistory] = useState(false);
    const [documentTypes, setDocumentTypes] = useState([]); 
    const [filteredDocuments, setFilteredDocuments] = useState([]); 
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [uploadStatus, setUploadStatus] = useState(""); // State for upload status message
    const [editDocumentData, setEditDocumentData] = useState(null); // State for document data to edit

    const handleFormTypeChange = (event) => {
        setIsPreFilled(event.target.value === 'pre-filled');
    };

    const handleUploadClick = () => {
        setIsUploadOpen(true);
    };

    const closeUploadPopup = () => {
        setIsUploadOpen(false);
        setUploadStatus(""); // Reset status on popup close
    };

    const closeEditPopup = () => {
        setIsEditOpen(false);
    };

    // Fetch documents from server
    const fetchDocuments = async () => {
        // const session_id = Cookies.get("session_id");
        const jwt_token = Cookies.get("jwt_token");

        if (jwt_token) {
            try {
                const response = await APIClient.get(`/employee/documents`, {
                    headers: {
                        Authorization: `Bearer ${jwt_token}`,
                    },
                    withCredentials: true,
                });

                if (response.status === 200) {
                    const documents = response.data.documents.map(doc => ({
                        ...doc,
                        uploaded_at: new Date(doc.uploaded_at)
                    }));
                    setDocuments(documents);
                    setFilteredDocuments(documents);
                } else {
                    console.error("Unexpected response:", response);
                }
            } catch (error) {
                console.error("Error fetching documents:", error);
                if (error.response && error.response.status === 404) {
                    alert("Documents not found.");
                } else if (error.response && error.response.status === 401) {
                    alert("Session expired. Please log in again.");
                    window.location.href = "/login";
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
    };

    // Upload document function
    const handleUploadDocument = async (formData) => {
        const jwt_token = Cookies.get("jwt_token");

        try {
            const response = await APIClient.post(
                '/employee/document/upload', // Upload endpoint
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${jwt_token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    withCredentials: true,
                }
            );

            if (response.data.success) {
                setUploadStatus("Document uploaded successfully");
                fetchDocuments(); // Refresh documents list
            } else {
                setUploadStatus("Error uploading document: " + response.data.message);
            }
        } catch (error) {
            console.error("Upload error:", error);
            setUploadStatus(error.response?.data?.message || "An error occurred during upload");
        }
    };

    const handleEditDocument = (document) => {
        setIsEditOpen(true);
        setEditDocumentData({
            doc_name: document.name,
            doc_type: document.type,
            comments: document.comments,
        });
        setSelectedDocument(document);
    };

    const handleSaveEdit = async (updatedData) => {
        const jwt_token = Cookies.get("jwt_token");
        try {
            const response = await APIClient.put(
                `/employee/document/${selectedDocument.id}/edit`,
                updatedData,
                {
                    headers: { Authorization: `Bearer ${jwt_token}` },
                    withCredentials: true,
                }
            );

            if (response.data.success) {
                setDocuments((prevDocuments) =>
                    prevDocuments.map((doc) =>
                        doc.id === selectedDocument.id ? { ...doc, ...updatedData } : doc
                    )
                );
                setIsEditOpen(false); 
            } else {
                console.error("Error updating document:", response.data.message);
            }
        } catch (error) {
            console.error("Update error:", error);
        }
    };

    const uploadFields = [
        { name: 'doc_name', label: 'Document Name', type: 'text', required: true },
        {
            name: 'doc_type',
            label: 'Document Type',
            type: 'select',
            options: documentTypes.map(type => ({ value: type.forms_master_id, label: type.form_type })),
            required: true 
        },
        { name: 'comments', label: 'Comments', type: 'textarea', required: false },
        { name: 'file', label: 'Upload File', type: 'file', required: true },
    ];

    useEffect(() => {
        const fetchDocumentTypes = async () => {
            try {
                const response = await APIClient.get('/employee/document-types');
                if (response.data.success) {
                    setDocumentTypes(response.data.data);
                } else {
                    console.error("Failed to fetch document types");
                }
            } catch (error) {
                console.error("Error fetching document types:", error);
            }
        };

        fetchDocumentTypes();
        fetchDocuments();
    }, []);

    const indexOfLastDocument = currentPage * documentsPerPage;
    const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
    const currentDocuments = filteredDocuments.slice(indexOfFirstDocument, indexOfLastDocument);
    const closeStatusHistory = () => setShowStatusHistory(false);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredDocuments.length / documentsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const openStatusHistory = (document) => {
        setSelectedDocument(document);
        setShowStatusHistory(true);
    };

    return (
        <div className={`document-status-container ${isUploadOpen ? 'blur' : ''}`}>
            <div className="top-bar d-flex justify-content-between align-items-center mb-4">
                <div className="left-options d-flex align-items-center">
                    <select 
                        className="form-select me-3" 
                        onChange={handleFormTypeChange} 
                        value={isPreFilled ? 'pre-filled' : 'unfilled' }
                            style={{ minWidth: "150px", whiteSpace: "nowrap" }
                        }
                    >
                        <option value="unfilled">Unfilled Form</option>
                        <option value="pre-filled">Pre-filled Form</option>
                    </select>
                
                <select
                        className="form-select me-3" style={{ minWidth: "12rem", whiteSpace: "nowrap" }}
                        >
                        <option value="">
                            {isPreFilled ? "Select Pre-filled Form" : "Select Unfilled Form"}
                        </option>
                        {isPreFilled ? (
                            <>
                            <option value="pre1">Pre-filled Form 1</option>
                            <option value="pre2">Pre-filled Form 2</option>
                            </>
                        ) : (
                            <>
                            <option value="form1">Unfilled Form 1</option>
                            <option value="form2">Unfilled Form 2</option>
                            </>
                        )}
                    </select>


                    <button className="btn btn-primary me-2" style={{ marginBottom:'0.7rem' }}>Download</button>
                </div>
                
            
                <div className="right-options">
                    <button className="btn btn-success" onClick={handleUploadClick}>Upload Document</button>
                </div>
            </div>

            <UploadComponent 
                isOpen={isUploadOpen} 
                onClose={closeUploadPopup} 
                fields={uploadFields} 
                onUpload={handleUploadDocument} // Pass upload handler
            />

            {uploadStatus && <p>{uploadStatus}</p>} {/* Show upload status */}

            <SearchBar documents={documents} setFilteredDocuments={setFilteredDocuments} />

            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Type</th>
                        <th scope="col">Uploaded On</th>
                        <th scope="col">Current Status</th>
                        <th scope="col">Comments</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentDocuments.length > 0 ? (
                        currentDocuments.map((doc, index) => (
                            <tr key={index}>
                                <td>{doc.name}</td>
                                <td>{doc.type}</td>
                                <td>{doc.uploaded_at.toLocaleDateString()}</td>
                                <td><span className={`badge ${doc.status === 'Approved' ? 'bg-success' : 'bg-warning'}`}>{doc.status}</span></td>
                                <td>{doc.comments}</td>
                                <td>
                                    <button className="btn btn-secondary" onClick={() => handleEditDocument(doc)}>Edit</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className="text-center">No documents found</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <EditDocumentComponent
                isOpen={isEditOpen}
                onClose={closeEditPopup}
                fields={uploadFields} // Reuse upload fields for editing
                initialData={editDocumentData}
                onSave={handleSaveEdit}
            />

            <ResultNavigation
                currentPage={currentPage}
                totalItems={filteredDocuments.length}
                itemsPerPage={documentsPerPage}
                onNextPage={handleNextPage}
                onPreviousPage={handlePreviousPage}
                isNextDisabled={currentPage >= Math.ceil(filteredDocuments.length / documentsPerPage)}
                isPreviousDisabled={currentPage <= 1}
            />
        </div>
    );
}

export default DocumentsPage;









