// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document-status-container {
    margin-top: 50px; 
    padding: 20px;
}

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left-options {
    display: flex;
    align-items: center;
}

.left-options select {
    margin-left: 10px;
}

.table {
    margin-top: 20px; /* Space between top bar and table */
}

/* Dashboard CSS */

.dashboard-container {
    padding: 20px;
}

.onboarding-details {
    margin-bottom: 20px;
}

.status-sections {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.status-box {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    width: 30%;
    text-align: center;
}

.status-box ul {
    list-style-type: none;
    padding: 0;
}

.status-box ul li {
    margin: 10px 0;
    font-size: 16px;
}

.application-btn-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.application-btn-container button {
    margin-bottom: 5px;
}

`, "",{"version":3,"sources":["webpack://./src/screens/OtherPages/DocumentsPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB,EAAE,oCAAoC;AAC1D;;AAEA,kBAAkB;;AAElB;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".document-status-container {\n    margin-top: 50px; \n    padding: 20px;\n}\n\n.top-bar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.left-options {\n    display: flex;\n    align-items: center;\n}\n\n.left-options select {\n    margin-left: 10px;\n}\n\n.table {\n    margin-top: 20px; /* Space between top bar and table */\n}\n\n/* Dashboard CSS */\n\n.dashboard-container {\n    padding: 20px;\n}\n\n.onboarding-details {\n    margin-bottom: 20px;\n}\n\n.status-sections {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 20px;\n}\n\n.status-box {\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    padding: 10px;\n    width: 30%;\n    text-align: center;\n}\n\n.status-box ul {\n    list-style-type: none;\n    padding: 0;\n}\n\n.status-box ul li {\n    margin: 10px 0;\n    font-size: 16px;\n}\n\n.application-btn-container {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n}\n\n.application-btn-container button {\n    margin-bottom: 5px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
