
import React, { useState, useEffect } from 'react';
import './UploadComponent.css'; 
import axios from "axios";

const EditDocumentComponent = ({ isOpen, onClose, fields, initialData, url, jwt_token, session_id }) => {
    const [formData, setFormData] = useState(initialData || {}); 
    const [selectedFile, setSelectedFile] = useState(null); 
    const [error, setError] = useState(''); 

    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB

    useEffect(() => {
        setFormData(initialData || {});
    }, [initialData]);

    const handleChange = (event) => {
        const { name, value, type, files } = event.target;
        if (type === 'file') {
            const file = files[0];
            if (file && file.size > MAX_FILE_SIZE) {
                setError(`File size should not exceed ${MAX_FILE_SIZE / (1024 * 1024)} MB`);
                setSelectedFile(null); 
            } else {
                setError(''); 
                setSelectedFile(file); 
            }
        } else if (type === 'date') {
            const formattedDate = formatDate(value);
            setFormData((prevData) => ({ ...prevData, [name]: formattedDate }));
        } else {
            setFormData((prevData) => ({ ...prevData, [name]: value }));
        }
    };

    const formatDate = (value) => {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const handleSave = async () => {
        if (error) {
            alert(error);
            return;
        }

        const uploadData = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
            uploadData.append(key, value);
        });
        if (selectedFile) {
            uploadData.append("file", selectedFile);
        }

        try {
            const response = await axios.put(
                url,
                uploadData,
                {
                    headers: {
                        Authorization: `Bearer ${jwt_token}`,
                        'Content-Type': 'multipart/form-data', 
                    },
                    withCredentials: true,
                }
            );

            if (response.data.success) {
                console.log("Document updated successfully");
                onClose();
            } else {
                console.error("Update failed:", response.data.message);
            }
        } catch (error) {
            console.error("Error during update:", error);
        }
    };

    return (
        isOpen && (
            <div className="edit-popup">
                <div className="edit-popup-content">
                    <span className="close" onClick={onClose}>&times;</span>
                    <h2>Edit Document</h2>
                    {error && <div className="error-message">{error}</div>} 
                    <form>
                        {fields.map((field, index) => (
                            <div key={index} className="mb-3">
                                <label>{field.label}</label>
                                {field.type === 'text' && (
                                    <input
                                        type="text"
                                        className="form-control"
                                        name={field.name}
                                        value={formData[field.name] || ''}
                                        onChange={handleChange}
                                        required={field.required}
                                    />
                                )}
                                {field.type === 'textarea' && (
                                    <textarea
                                        className="form-control"
                                        name={field.name}
                                        value={formData[field.name] || ''}
                                        onChange={handleChange}
                                    ></textarea>
                                )}
                                {field.type === 'select' && (
                                    <select
                                        className="form-select"
                                        name={field.name}
                                        onChange={handleChange}
                                        value={formData[field.name] || ''} 
                                        required={field.required}
                                    >
                                        <option value="">Select {field.label}</option>
                                        {field.options.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                )}
                                {field.type === 'file' && (
                                    <>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="fileInput" className="custom-file-label">
                                            {selectedFile ? selectedFile.name : "No file chosen"}
                                        </label>
                                    </>
                                )}
                                {field.type === 'date' && (
                                    <input
                                        type="date"
                                        className="form-control"
                                        name={field.name}
                                        onChange={handleChange}
                                        value={formData[field.name] || ''} 
                                        required={field.required}
                                    />
                                )}
                            </div>
                        ))}
                        <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button> 
                    </form>
                </div>
            </div>
        )
    );
};

export default EditDocumentComponent;











