// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messages-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.message-list {
  background-color: #f8f9fa; /* Optional: background color */
  height: 100%;
  overflow-y: auto;
  margin-top: 20px;
}

.message-item {
  padding: 15px;
  border-bottom: 1px solid #8a8888;
  cursor: pointer;
}

.message-item:hover {
  background-color: #e9ecef;
}

.message-content {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}
.search-and-list {
    display: flex;
    flex-direction: column;
  }

.list-group-flush, .list-group-flush li {
  list-style: none; /* Removes bullet points */
  padding-left: 0;
}
.messages-heading {
    margin-bottom: 20px; /* Adjust this value as needed */

  }
  
  
`, "",{"version":3,"sources":["webpack://./src/screens/OtherPages/messages.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,yBAAyB,EAAE,+BAA+B;EAC1D,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB;AAClB;AACA;IACI,aAAa;IACb,sBAAsB;EACxB;;AAEF;EACE,gBAAgB,EAAE,0BAA0B;EAC5C,eAAe;AACjB;AACA;IACI,mBAAmB,EAAE,gCAAgC;;EAEvD","sourcesContent":[".messages-container {\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  padding: 20px;\n}\n\n.message-list {\n  background-color: #f8f9fa; /* Optional: background color */\n  height: 100%;\n  overflow-y: auto;\n  margin-top: 20px;\n}\n\n.message-item {\n  padding: 15px;\n  border-bottom: 1px solid #8a8888;\n  cursor: pointer;\n}\n\n.message-item:hover {\n  background-color: #e9ecef;\n}\n\n.message-content {\n  padding: 20px;\n  height: 100%;\n  overflow-y: auto;\n}\n.search-and-list {\n    display: flex;\n    flex-direction: column;\n  }\n\n.list-group-flush, .list-group-flush li {\n  list-style: none; /* Removes bullet points */\n  padding-left: 0;\n}\n.messages-heading {\n    margin-bottom: 20px; /* Adjust this value as needed */\n\n  }\n  \n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
