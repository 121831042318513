// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp-input {
    width: 35px; /* Adjust the width as needed */
    height: 60px; /* Adjust the height to make it square */
    text-align: center; /* Center the text */
}

/* This is for OTP */`, "",{"version":3,"sources":["webpack://./src/screens/OtherPages/Other.css"],"names":[],"mappings":"AAAA;IACI,WAAW,EAAE,+BAA+B;IAC5C,YAAY,EAAE,wCAAwC;IACtD,kBAAkB,EAAE,oBAAoB;AAC5C;;AAEA,oBAAoB","sourcesContent":[".otp-input {\n    width: 35px; /* Adjust the width as needed */\n    height: 60px; /* Adjust the height to make it square */\n    text-align: center; /* Center the text */\n}\n\n/* This is for OTP */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
