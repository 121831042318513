import React, { useState } from "react";
import axios from "axios";
import SSNInput from "./SSNInput"; // Import the custom SSNInput component

// Country and extension data
const countryList = [
    { name: "United States", code: "USA", extension: "+1" },
    { name: "Canada", code: "Canada", extension: "+1" },
    { name: "United Kingdom", code: "UK", extension: "+44" },
    { name: "Australia", code: "Australia", extension: "+61" },
    { name: "India", code: "India", extension: "+91" },
    { name: "Germany", code: "Germany", extension: "+49" },
    { name: "France", code: "France", extension: "+33" },
    { name: "China", code: "China", extension: "+86" },
    { name: "Japan", code: "Japan", extension: "+81" },
    { name: "Brazil", code: "Brazil", extension: "+55" },
    { name: "Mexico", code: "Mexico", extension: "+52" },
    { name: "South Africa", code: "South Africa", extension: "+27" },
    { name: "Russia", code: "Russia", extension: "+7" },
    { name: "Italy", code: "Italy", extension: "+39" },
    { name: "South Korea", code: "South Korea", extension: "+82" },
    { name: "Spain", code: "Spain", extension: "+34" },
    { name: "Sweden", code: "Sweden", extension: "+46" },
    { name: "Netherlands", code: "Netherlands", extension: "+31" },
];

function EmployeeRegistrationForm() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        ssn: "",
        confirmSsn: "",
        email: "",
        confirmEmail:"",
        address: "",
        city: "", 
        state: "", 
        zip: "", 
        country: "",
        phoneNumber: "",
        extension: "+1", 
    });

    const [errors, setErrors] = useState({});

    const validateField = (name, value) => {
        let error = "";
        switch (name) {
            case "firstName":
            case "lastName":
                if (!/^[a-zA-Z]{2,30}$/.test(value)) {
                    error = "Should be 2-30 characters long and contain only letters";
                }
                break;
            case "dateOfBirth":
                if (!/^\d{4}-\d{2}-\d{2}$/.test(value)) {
                    error = "Should be in YYYY-MM-DD format";
                }
                break;
            case "ssn":
            case "confirmSsn":
                if (!/^\d{9}$/.test(value)) {
                    error = "Should be a 9-digit number without dashes";
                }
                break;
            case "email":
            case "confirmEmail":
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    error = "Should be a valid email address";
                }
                break;
            case "address":
                if (value.length < 5) {
                    error = "Should be at least 5 characters long";
                }
                break;
            case "city":
                if (!/^[a-zA-Z ]{2,50}$/.test(value)) {
                    error = "Should be 2-50 characters long and contain only letters";
                }
                break;
            case "state":
                if (!/^[a-zA-Z ]{2,50}$/.test(value)) {
                    error = "Should be 2-50 characters long and contain only letters";
                }
                break;
            case "zip":
                if (!/^\d{5}(-\d{4})?$/.test(value)) {
                    error = "Should be a 5-digit number or ZIP+4 format";
                }
                break;
            case "phoneNumber":
                if (!/^\d{10}$/.test(value)) {
                    error = "Should be a 10-digit phone number";
                }
                break;
            default:
                break;
        }
        return error;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        const error = validateField(name, value);
        setErrors({ ...errors, [name]: error });
    };

    const handleCountryChange = (e) => {
        const selectedCountry = e.target.value;
        const selectedExtension = countryList.find((country) => country.code === selectedCountry)?.extension || "+1";
        setFormData({ ...formData, country: selectedCountry, extension: selectedExtension });
    };

    const handleExtensionChange = (e) => {
        setFormData({ ...formData, extension: e.target.value });
    };

    const handleSSNChange = (value) => {
        setFormData({ ...formData, ssn: value });
        const error = validateField("ssn", value);
        setErrors({ ...errors, ssn: error });
    };

    const handleConfirmSSNChange = (value) => {
        setFormData({ ...formData, confirmSsn: value });
        const error = validateField("confirmSsn", value);
        setErrors({ ...errors, confirmSsn: error });
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload
    
        console.log("Form Data Before Submission: ", formData); // Log form data
    
        const formErrors = {};
        Object.keys(formData).forEach((key) => {
            const error = validateField(key, formData[key]);
            if (error) formErrors[key] = error;
        });
    
        if (formData.ssn !== formData.confirmSsn) {
            formErrors.confirmSsn = "SSN fields must match";
        }
        if (formData.email !== formData.confirmEmail) {
            formErrors.confirmEmail = "Email fields must match";
        }
    
        setErrors(formErrors);
    
        if (Object.keys(formErrors).length === 0) {
            try {
                const dataToSend = {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    dob: formData.dateOfBirth,
                    ssn: formData.ssn,
                    email: formData.email,
                    address: formData.address,
                    city: formData.city,
                    state: formData.state,
                    zip: formData.zip,
                    country: formData.country,
                    phone: formData.phoneNumber,
                    extension: formData.extension,
                };
    
                console.log("Sending Data: ", dataToSend); // Log data sent to backend
    
                // Send the request using axios
                const response = await axios.post(
                    "http://0.0.0.0:3500/employee/register", 
                    dataToSend
                );
    
                console.log("API Response: ", response.data); // Log API response
    
                if (response.data.success) {
                    alert("Employee registered successfully!"); // Success alert
                } else {
                    alert(`Error: ${response.data.message}`); // Backend error message
                }
            } catch (error) {
                console.error("Error during registration: ", error); // Log detailed error
    
                // Check if the error response contains a message
                if (error.response && error.response.data && error.response.data.message) {
                    alert(`Error: ${error.response.data.message}`); // Display backend error message
                } else {
                    alert("An unexpected error occurred. Please try again."); // Generic error
                }
            }
        }
    };
    
    
    

    return (
        <div className="card mb-3">
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold">Employee Registration Form</h6>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="row g-3 align-items-center">
                        <div className="col-md-6">
                            <label htmlFor="firstName" className="form-label">First Name</label>
                            <input type="text" className="form-control" id="firstName" name="firstName" value={formData.firstName} onChange={handleInputChange} required />
                            {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="lastName" className="form-label">Last Name</label>
                            <input type="text" className="form-control" id="lastName" name="lastName" value={formData.lastName} onChange={handleInputChange} required />
                            {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                        </div>
                        <div className="col-md-6">
                            <label className="form-label">Date Of Birth</label>
                            <input type="date" className="form-control" id="dateOfBirth" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleInputChange} required />
                            {errors.dateOfBirth && <div className="text-danger">{errors.dateOfBirth}</div>}
                        </div>
                        
                        <div className="col-md-6">
                            <label className="form-label">Phone Number</label>
                            <div className="input-group">
                                <span className="input-group-text">{formData.extension}</span>
                                <input type="text" className="form-control" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} required />
                            </div>
                            {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
                        </div>

                        <div className="col-md-6">
                            <SSNInput label="SSN" value={formData.ssn} onChange={handleSSNChange} error={errors.ssn} />
                        </div>
                        <div className="col-md-6">
                            <SSNInput label="Confirm SSN" value={formData.confirmSsn} onChange={handleConfirmSSNChange} error={errors.confirmSsn} />
                        </div>

                        <div className="col-md-6">
                            <label className="form-label">Email</label>
                            <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleInputChange} required />
                            {errors.email && <div className="text-danger">{errors.email}</div>}
                        </div>

                        <div className="col-md-6">
                            <label className="form-label">Confirm Email</label>
                            <input type="email" className="form-control" id="confirmEmail" name="confirmEmail" value={formData.confirmEmail} onChange={handleInputChange} required />
                            {errors.confirmEmail && <div className="text-danger">{errors.confirmEmail}</div>}
                        </div>

                        <div className="col-md-6">
                            <label className="form-label">Address</label>
                            <input type="text" className="form-control" id="address" name="address" value={formData.address} onChange={handleInputChange} required />
                            {errors.address && <div className="text-danger">{errors.address}</div>}
                        </div>

                        <div className="col-md-6">
                            <label className="form-label">City</label>
                            <input type="text" className="form-control" id="city" name="city" value={formData.city} onChange={handleInputChange} required />
                            {errors.city && <div className="text-danger">{errors.city}</div>}
                        </div>

                        <div className="col-md-6">
                            <label className="form-label">State</label>
                            <input type="text" className="form-control" id="state" name="state" value={formData.state} onChange={handleInputChange} required />
                            {errors.state && <div className="text-danger">{errors.state}</div>}
                        </div>

                        <div className="col-md-6">
                            <label className="form-label">ZIP</label>
                            <input type="text" className="form-control" id="zip" name="zip" value={formData.zip} onChange={handleInputChange} required />
                            {errors.zip && <div className="text-danger">{errors.zip}</div>}
                        </div>

                        <div className="col-md-6">
                            <label className="form-label">Country</label>
                            <select className="form-select" id="country" name="country" value={formData.country} onChange={handleCountryChange} required>
                                <option value="">Select Country</option>
                                {countryList.map((country) => (
                                    <option key={country.code} value={country.code}>
                                        {country.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                      
                    </div>

                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>
        </div>
    );
}

export default EmployeeRegistrationForm;
