import React, { useState, useEffect } from 'react';
import './StatusHistoryComponent.css';

const StatusHistoryComponent = ({ isOpen, onClose, details, fetchStatusHistory }) => {
    const [statusHistory, setStatusHistory] = useState([]);
    const [loading, setLoading] = useState(false); // To handle loading state
    const [error, setError] = useState(null); // To handle any errors

    useEffect(() => {
        const loadStatusHistory = async () => {
            if (isOpen && details && fetchStatusHistory) {
                setLoading(true);
                setError(null); // Reset error state
                try {
                    const history = await fetchStatusHistory(details.id); // Use `details.id` as timesheet ID
                    setStatusHistory(history || []); // Default to an empty array if no data
                } catch (err) {
                    console.error('Error fetching status history:', err);
                    setError('Failed to load status history.');
                } finally {
                    setLoading(false); // Always stop loading
                }
            }
        };
    
        loadStatusHistory();
    }, [isOpen, details, fetchStatusHistory]);
    
    

    if (!isOpen) return null;

    return (
        <div className="status-history-popup">
            <div className="status-history-content">
                <button className="close" onClick={onClose}>
                    &times;
                </button>
                <h2>Details</h2>
                <div className="item-details">
                    {details &&
                        Object.keys(details).map((key) => (
                            <p key={key}>
                                <strong>{key.replace(/([A-Z])/g, ' $1')}:</strong> {details[key]}
                            </p>
                        ))}
                </div>
                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p className="error">{error}</p>
                ) : (
                    <table className="status-history-table">
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Updated At</th>
                                <th>Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {statusHistory.length > 0 ? (
                                statusHistory.map((record, index) => (
                                    <tr key={index}>
                                        <td>{record.status}</td>
                                        <td>{new Date(record.updated_at).toLocaleString()}</td>
                                        <td>{record.comments || 'No comments'}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" className="text-center">
                                        No status history available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default StatusHistoryComponent;

// import React, { useState, useEffect } from 'react';
// import './StatusHistoryComponent.css';

// const StatusHistoryComponent = ({ isOpen, onClose, details, fetchStatusHistory }) => {
//     const [statusHistory, setStatusHistory] = useState([]);

//     useEffect(() => {
//         if (isOpen && details) {
//             // Fetch status history based on provided `fetchStatusHistory` function
//             const history = fetchStatusHistory(details.id);
//             setStatusHistory(history);
//         }
//     }, [isOpen, details, fetchStatusHistory]);

//     return (
//         isOpen && (
//             <div className="status-history-popup">
//                 <div className="status-history-content">
//                     <span className="close" onClick={onClose}>&times;</span>
//                     <h2>Details</h2>
//                     <div className="item-details">
//                         {Object.keys(details).map((key) => (
//                             <p key={key}><strong>{key.replace(/([A-Z])/g, ' $1')}:</strong> {details[key]}</p>
//                         ))}
//                     </div>
//                     <table className="status-history-table">
//                         <thead>
//                             <tr>
//                                 <th>Status</th>
//                                 <th>Updated At</th>
//                                 <th>Comments</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {statusHistory.map((record, index) => (
//                                 <tr key={index}>
//                                     <td>{record.status}</td>
//                                     <td>{record.updatedAt}</td>
//                                     <td>{record.comments}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         )
//     );
// };

// export default StatusHistoryComponent;